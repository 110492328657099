import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
  Zoom
} from '@mui/material';
import { UserApi } from 'common/api';
import { NotificationSettings } from 'common/types';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

function NotificationsTab() {
  const { enqueueSnackbar } = useSnackbar();
  const { t }: { t: any } = useTranslation();
  const { user, refreshUser: fetchUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettings>({
      salesEmail: user?.notificationSettings.salesEmail ?? true,
      newsletterEmail: user?.notificationSettings.newsletterEmail ?? true
    });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUnsavedChanges(true);
    setNotificationSettings({
      ...notificationSettings,
      [event.target.name]: event.target.checked
    });
  };

  const handleSave = async () => {
    if (!user) return;
    setIsLoading(true);
    const response = await UserApi.updateUser(user.userId, {
      notificationSettings
    });

    if (response.success) {
      fetchUser();
      setUnsavedChanges(false);
      handleSuccessPopup();
    } else {
      handleErrorPopup();
    }
    setIsLoading(false);
  };

  const handleSuccessPopup = () => {
    enqueueSnackbar(t('The user account was updated successfully'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const handleErrorPopup = () => {
    enqueueSnackbar(t('Error saving settings'), {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box pb={2}>
          <Typography variant="h3">Account</Typography>
          <Typography variant="subtitle2">
            Choose what notifications you want to receive
          </Typography>
        </Box>
        <Card>
          <List>
            <ListItem sx={{ p: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1
                }}
                primary="Newsletter"
                secondary="Receive emails about our latest announcements and news"
              />
              <Switch
                color="primary"
                checked={notificationSettings.newsletterEmail}
                onChange={handleChange}
                name="newsletterEmail"
              />
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1
                }}
                primary="Latest Sales"
                secondary="Receive emails about latest sales and deals."
              />
              <Switch
                color="primary"
                checked={notificationSettings.salesEmail}
                onChange={handleChange}
                name="salesEmail"
              />
            </ListItem>
          </List>
        </Card>
        <Box pt={2} display="flex" justifyContent="right">
          <Box pr={2} pt={1}>
            {isLoading ? <CircularProgress size="1.5rem" /> : null}
          </Box>

          <Button
            variant="contained"
            disabled={!unsavedChanges || isLoading}
            onClick={() => handleSave()}
          >
            Save Changes
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default NotificationsTab;
