import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Zoom
} from '@mui/material';
import { styled } from '@mui/system';
import MultiSelectUserGroups from 'app/components/MultiSelectUserGroups';
import { IntegrationApi } from 'common/api';
import {
  IntegrationConnectorProperties,
  IntegrationScope,
  IntegrationSettings,
  JobType,
  PermissionGroup
} from 'common/types';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { IntegrationSetupCard } from '../IntegrationCards';
import { OAuthButton } from '../OAuth/OAuthButton';
import { IntegrationOption } from '../lib';
import { getFieldComponent } from '../lib/helpers';
import { AvailableIntegrations } from '../lib/integrations';

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  minWidth: '600px',
  minHeight: '500px',
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    minHeight: 'auto'
  }
}));

export interface IntegrationWizardFormData {
  selectedIntegration?: IntegrationOption;
  connection?: IntegrationConnectorProperties;
  scope?: IntegrationScope;
  settings?: IntegrationSettings;
}

const defaultSettings: IntegrationSettings = {
  syncType: JobType.ON_DEMAND,
  defaultGroupIds: [],
  syncFrequencyInHours: 48,
  startDateTime: new Date().toISOString()
};

interface IntegrationWizardProps {
  activeIntegrations: IntegrationOption[];
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const IntegrationWizard = (props: IntegrationWizardProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState<IntegrationWizardFormData>({
    selectedIntegration: undefined,
    connection: {},
    scope: {},
    settings: defaultSettings
  });
  const [error, setError] = useState<string>();
  const { organization } = useAuth();
  const maxSteps = 3;

  const handleClose = (event?, reason?) => {
    if (reason && reason === 'backdropClick') return;
    props.onClose();
    resetForm();
  };

  const resetForm = () => {
    setIsLoading(false);
    setError(undefined);
    setFormData({
      selectedIntegration: undefined,
      connection: {},
      scope: {},
      settings: defaultSettings
    });
    setActiveStep(0);
  };

  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  const validateStep = async (step: number) => {
    if (!formData.selectedIntegration) {
      setError('Select an Integration');
      return false;
    }
    switch (step) {
      case 0:
        return true;

      case 1:
        if (!formData.scope) {
          setError('Please enter scope details');
          return false;
        }

        const scopeFields =
          formData.selectedIntegration.wizardDetails.scopeStep.fields;
        let atleastOneFieldSelected = false;
        for (const field of scopeFields) {
          if (
            !formData.scope[formData.selectedIntegration.id] ||
            !formData.scope[formData.selectedIntegration.id][field.key]
          ) {
            const newFormData = { ...formData };
            if (!newFormData.scope) {
              newFormData.scope = {};
            }
            if (!newFormData.scope[formData.selectedIntegration.id]) {
              newFormData.scope[formData.selectedIntegration.id] = {};
            }
            newFormData.scope[formData.selectedIntegration.id][field.key] =
              false;
            setFormData(newFormData);
          } else if (
            formData.scope[formData.selectedIntegration.id][field.key] === true
          ) {
            atleastOneFieldSelected = true;
          }
        }

        if (!atleastOneFieldSelected && scopeFields.length > 0) {
          setError('Please select atleast one scope');
          return false;
        }

        return true;

      case 2:
        if (!formData.settings) {
          setError('Please enter settings');
          return false;
        }

        if (
          formData.settings.syncType === JobType.REOCCURING &&
          !formData.settings.startDateTime
        ) {
          setError('Please enter a start date and time');
          return false;
        }

        return true;

      case 3:
        if (!formData.connection) {
          setError('Please enter connection details');
          return false;
        }

        const fields =
          formData.selectedIntegration.wizardDetails.connectionStep.fields;
        for (const field of fields) {
          if (
            !formData.connection ||
            !formData.selectedIntegration?.id ||
            !formData.connection[formData.selectedIntegration.id]
          ) {
            setError(`Please enter a value for ${field.label}`);
            return false;
          }
        }

        const verified = await IntegrationApi.verifyCredentials(
          formData.selectedIntegration.id,
          formData.connection
        );

        if (!verified.success || !verified.data) {
          setError('Invalid Credentials');
          return false;
        }

        return true;

      default:
        return true;
    }
  };

  const handleNext = async () => {
    setIsLoading(true);
    const isValid = await validateStep(activeStep);
    if (isValid) {
      setActiveStep(prevStep => prevStep + 1);
      setError(undefined);
    }

    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (!organization) {
      setError('Unexpected Error');
      setIsLoading(false);
      return;
    }

    if (
      !formData.selectedIntegration ||
      !formData.connection ||
      !formData.settings ||
      !formData.scope
    ) {
      setError('Unexpected Error');
      setIsLoading(false);
      return;
    }

    const permissionGroups: PermissionGroup[] =
      formData.settings.defaultGroupIds.map(groupId => {
        return { key: groupId, effect: 'allow' };
      });

    const oneTimeSync = formData.settings.syncType === JobType.ON_DEMAND;
    const response = await IntegrationApi.createIntegration({
      orgId: organization.orgId,
      type: formData.selectedIntegration.id,
      startDateTime: !oneTimeSync ? formData.settings.startDateTime : undefined,
      scope: formData.scope,
      connector: formData.connection,
      syncFrequency: !oneTimeSync ? formData.settings.syncFrequencyInHours : 0,
      syncType: formData.settings.syncType,
      defaultPermissions: permissionGroups
    });
    if (!response.success || !response.data) {
      setError('Unexpected Error');
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    enqueueSnackbar('Integration has been added', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
    props.onSubmit();
  };

  const renderSteps = (step: number) => {
    switch (step) {
      case 0:
        return (
          <IntegrationSelectionStep
            activeIntegrations={props.activeIntegrations}
            selectedIntegration={formData.selectedIntegration}
            onSelect={async (selectedIntegration: IntegrationOption) => {
              setFormData(prevState => ({
                ...prevState,
                selectedIntegration: selectedIntegration
              }));
            }}
            error={error}
            setError={setError}
          />
        );

      case 1:
        return formData.selectedIntegration ? (
          <IntegrationScopeStep
            selectedIntegration={formData.selectedIntegration}
            scope={formData.scope ?? {}}
            onChange={(scope: IntegrationScope) => {
              setFormData(prevState => ({
                ...prevState,
                scope: scope
              }));
            }}
            error={error}
            setError={setError}
          />
        ) : null;

      case 2:
        return formData.selectedIntegration ? (
          <IntegrationSettingsStep
            selectedIntegration={formData.selectedIntegration}
            settings={formData.settings ?? defaultSettings}
            onChange={(settings: IntegrationSettings) => {
              setFormData(prevState => ({
                ...prevState,
                settings: settings
              }));
            }}
            error={error}
            setError={setError}
          />
        ) : null;

      case 3:
        return formData.selectedIntegration ? (
          <IntegrationConnectionStep
            selectedIntegration={formData.selectedIntegration}
            connection={formData.connection ?? {}}
            onChange={(connector: IntegrationConnectorProperties) => {
              setFormData(prevState => ({
                ...prevState,
                connection: connector
              }));
            }}
            error={error}
            setError={setError}
          />
        ) : null;
    }
  };

  useEffect(() => {
    if (formData.selectedIntegration && activeStep === 0) {
      handleNext();
    }
  }, [formData.selectedIntegration]);

  return (
    <Dialog
      maxWidth={'lg'}
      fullWidth={true}
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          p: 1,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        }}
      >
        <Typography variant="h3" textAlign="center" gutterBottom>
          {'Integration Wizard'}
        </Typography>
      </DialogTitle>
      <StyledDialogContent>
        <Stepper
          activeStep={activeStep}
          sx={{
            pr: '20px',
            pl: '20px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
          }}
        >
          <Step>
            <StepLabel>Start</StepLabel>
          </Step>
          <Step>
            <StepLabel>Scope</StepLabel>
          </Step>
          <Step>
            <StepLabel>Settings</StepLabel>
          </Step>
          <Step>
            <StepLabel>Connect</StepLabel>
          </Step>
        </Stepper>
        {renderSteps(activeStep)}
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleBack} disabled={isLoading || activeStep === 0}>
          Back
        </Button>
        {formData.selectedIntegration && activeStep === maxSteps ? (
          <OAuthButton
            type={formData.selectedIntegration?.id}
            formData={formData}
            onSuccess={() => {
              enqueueSnackbar('Integration has been added', {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right'
                },
                TransitionComponent: Zoom
              });
              props.onSubmit();
              resetForm();
            }}
            onFailure={error => {
              setError(error);
            }}
          />
        ) : (
          <Button
            variant="contained"
            color="primary"
            startIcon={
              isLoading ? (
                <CircularProgress size="1rem" sx={{ color: 'white' }} />
              ) : null
            }
            onClick={() => handleNext()}
          >
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

interface IntegrationSelectionStepProps {
  activeIntegrations: IntegrationOption[];
  selectedIntegration?: IntegrationOption;
  onSelect: (selectedIntegration: IntegrationOption) => void;
  error?: string;
  setError: (error: string) => void;
}

const IntegrationSelectionStep = (props: IntegrationSelectionStepProps) => {
  const availableIntegrations = AvailableIntegrations.filter(i => {
    return !props.activeIntegrations.find(ai => ai.id === i.id);
  });
  return (
    <>
      {Boolean(props.error && props.error !== '') && (
        <Grid item xs={8}>
          <Alert severity="error">{props.error}</Alert>
        </Grid>
      )}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ p: 2, pt: 4 }}
      >
        {availableIntegrations.length === 0 && (
          <Grid item xs={12}>
            <Typography variant="body1" fontSize="1rem" gutterBottom>
              There are no integrations available to add.
            </Typography>
          </Grid>
        )}
        {availableIntegrations.map(integration => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={integration.id}>
            <IntegrationSetupCard
              integration={integration}
              selected={props.selectedIntegration?.id === integration.id}
              onSelect={() => props.onSelect(integration)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

interface IntegrationConnectionStepProps {
  selectedIntegration: IntegrationOption;
  connection: IntegrationConnectorProperties;
  onChange: (connector: IntegrationConnectorProperties) => void;
  error?: string;
  setError: (error?: string) => void;
}

const IntegrationConnectionStep = (props: IntegrationConnectionStepProps) => {
  return (
    <>
      {Boolean(props.error && props.error !== '') && (
        <Grid item xs={8}>
          <Alert severity="error">{props.error}</Alert>
        </Grid>
      )}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ p: 2, pt: 4 }}
      >
        {props.selectedIntegration?.wizardDetails.connectionStep
          .description && (
          <Grid item xs={8}>
            {props.selectedIntegration.wizardDetails.connectionStep.description}
          </Grid>
        )}
        {props.selectedIntegration?.wizardDetails.connectionStep.fields.map(
          field => (
            <Grid item xs={8} key={field.key}>
              {getFieldComponent(
                field,
                props.selectedIntegration.id,
                props.connection,
                (connection: IntegrationConnectorProperties) => {
                  props.setError();
                  props.onChange(connection);
                }
              )}
            </Grid>
          )
        )}
      </Grid>
    </>
  );
};

interface IntegrationScopeStepProps {
  selectedIntegration: IntegrationOption;
  scope: IntegrationScope;
  onChange: (scope: IntegrationScope) => void;
  error?: string;
  setError: (error?: string) => void;
}

const IntegrationScopeStep = (props: IntegrationScopeStepProps) => {
  return (
    <>
      {Boolean(props.error && props.error !== '') && (
        <Grid item xs={8}>
          <Alert severity="error">{props.error}</Alert>
        </Grid>
      )}
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignContent="center"
        spacing={2}
        sx={{ p: 2, pt: 4 }}
      >
        {props.selectedIntegration?.wizardDetails.scopeStep.description && (
          <Grid item xs={8}>
            {props.selectedIntegration.wizardDetails.scopeStep.description}
          </Grid>
        )}
        <Grid item xs={8}>
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignContent="center"
            spacing={2}
          >
            {props.selectedIntegration?.wizardDetails.scopeStep.fields.map(
              field => (
                <Grid item md={6} key={field.key}>
                  {getFieldComponent(
                    field,
                    props.selectedIntegration.id,
                    props.scope,
                    (scope: IntegrationScope) => {
                      props.setError();
                      props.onChange(scope);
                    }
                  )}
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

interface IntegrationSettingsStepProps {
  selectedIntegration: IntegrationOption;
  settings: IntegrationSettings;
  onChange: (settings: IntegrationSettings) => void;
  error?: string;
  setError: (error?: string) => void;
}
const IntegrationSettingsStep = (props: IntegrationSettingsStepProps) => {
  const { organization } = useAuth();

  console.log('props.settings.defaultGroupIds', props.settings.defaultGroupIds);
  const selectedGroups = (organization?.permissionGroups ?? [])
    .filter(g => props.settings.defaultGroupIds.includes(g.groupId))
    .map(g => {
      return { groupId: g.groupId, name: g.name };
    });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ p: 2, pt: 4, pb: 4 }}
    >
      {Boolean(props.error && props.error !== '') && (
        <Grid item xs={8}>
          <Alert severity="error">{props.error}</Alert>
        </Grid>
      )}
      <Grid item xs={8}>
        <Typography variant="body1" fontSize="1rem" gutterBottom>
          Select the settings for the integration. These settings can be changed
          later.
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <InputLabel>Syncronization Type</InputLabel>
        <Select
          fullWidth
          value={props.settings.syncType}
          onChange={event => {
            props.onChange({
              ...props.settings,
              syncType: event.target.value as JobType
            });
          }}
        >
          <MenuItem value={JobType.ON_DEMAND}>
            <ListItemText primary={'On Demand'} />
          </MenuItem>
          <MenuItem value={JobType.REOCCURING}>
            <ListItemText primary={'Scheduled'} />
          </MenuItem>
        </Select>
      </Grid>
      {props.settings.syncType === JobType.REOCCURING && (
        <>
          <Grid item xs={8}>
            <InputLabel>Interval</InputLabel>
            <Select
              fullWidth
              value={props.settings.syncFrequencyInHours}
              onChange={event => {
                props.onChange({
                  ...props.settings,
                  syncFrequencyInHours: event.target.value as number
                });
              }}
            >
              <MenuItem value={12}>
                <ListItemText primary="12 Hours" />
              </MenuItem>
              <MenuItem value={24}>
                <ListItemText primary="24 Hours" />
              </MenuItem>
              <MenuItem value={48}>
                <ListItemText primary="48 Hours" />
              </MenuItem>
              <MenuItem value={168}>
                <ListItemText primary="1 Week" />
              </MenuItem>
              <MenuItem value={730}>
                <ListItemText primary="1 Month" />
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={8}>
            <InputLabel>
              What date and time the syncronization should start?
            </InputLabel>
            <TextField
              type="datetime-local"
              value={
                props.settings.startDateTime
                  ? props.settings.startDateTime
                  : new Date().toISOString()
              }
              onChange={event => {
                props.onChange({
                  ...props.settings,
                  startDateTime: event.target.value
                });
              }}
            />
          </Grid>
        </>
      )}

      <Grid item xs={8}>
        <InputLabel>Assigned Permission Groups</InputLabel>
        <MultiSelectUserGroups
          label=""
          value={selectedGroups}
          onChange={event => {
            console.log(event.target);
            props.onChange({
              ...props.settings,
              defaultGroupIds: event.target.value.map(g => g.groupId)
            });
          }}
        />
      </Grid>
    </Grid>
  );
};
