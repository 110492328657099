import { Chat } from '@mui/icons-material';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  Zoom,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import Scrollbar from 'app/components/Scrollbar';
import { ConversationApi } from 'common/api';
import {
  Attachment,
  Conversation,
  ConversationStatus,
  MessageRole
} from 'common/types';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useState } from 'react';
import { ArchiveConversationDialog } from '../Dialogs/ArchiveConversation';
import { DeleteConversationDialog } from '../Dialogs/DeleteConversation';
import EditConversationDialog from '../Dialogs/EditConversation';
import { RestoreConversationDialog } from '../Dialogs/RestoreConversation';

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTabs-indicator {
            min-height: 4px;
            height: 4px;
            box-shadow: none;
            border: 0;
        }

        .MuiTab-root {
            &.MuiButtonBase-root {
                padding: 0;
                margin-right: ${theme.spacing(3)};
                font-size: ${theme.typography.pxToRem(16)};
                color: ${theme.colors.alpha.trueWhite[50]};

                .MuiTouchRipple-root {
                    display: none;
                }
            }

            &.Mui-selected:hover,
            &.Mui-selected {
                color: ${theme.colors.alpha.trueWhite[100]};
            }
        }
  `
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
          background-color: ${theme.colors.alpha.trueWhite[10]};
          color: ${theme.colors.alpha.trueWhite[50]};
          width: ${theme.spacing(8)};
          height: ${theme.spacing(8)};
          margin-left: auto;
          margin-right: auto;
    `
);

interface ConversationTabsProps {
  conversations: Conversation[];
  isLoading: boolean;
  selectedConversation?: Conversation;
  setSelectedConversation: (conversation?: Conversation) => void;
  setConversations: (conversations: Conversation[]) => void;
  onConversationClick: (conversation: Conversation) => void;
}

export const ConversationTabs = (props: ConversationTabsProps) => {
  const [currentTab, setCurrentTab] = useState<string>('all');
  const [menuConversation, setMenuConversation] = useState<Conversation>();
  const [activeConversations, setActiveConversations] = useState<
    Conversation[]
  >([]);
  const [archivedConversations, setArchivedConversations] = useState<
    Conversation[]
  >([]);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState<boolean>(false);
  const [openRestoreDialog, setOpenRestoreDialog] = useState<boolean>(false);
  const [openEditConversationDialog, setOpenEditConversationDialog] =
    useState<boolean>(false);

  useEffect(() => {
    setActiveConversations(
      props.conversations.filter(
        c =>
          c.status !== ConversationStatus.ARCHIVED &&
          c.conversationId !== undefined
      )
    );
    setArchivedConversations(
      props.conversations.filter(c => c.status === ConversationStatus.ARCHIVED)
    );

    const _attachments: Attachment[] = [];
    props.conversations.forEach(c => {
      c.messages.forEach(m => {
        if (m.attachments) {
          _attachments.push(...m.attachments);
        }
      });
    });
    setAttachments(_attachments);
  }, [props.conversations]);

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const handleConversationClick = (conversation: Conversation) => {
    console.log('Conversation clicked');
    props.onConversationClick(conversation);
  };

  const tabs = [
    { value: 'all', label: 'Active' },
    { value: 'archived', label: 'Archived' },
    { value: 'reports', label: 'Reports' }
  ];

  return (
    <Box
      sx={{
        height: '67.4%'
      }}
    >
      <TabsContainerWrapper ml={2}>
        <Tabs
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map(tab => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </TabsContainerWrapper>
      <Scrollbar>
        <Box mt={2}>
          {currentTab === 'all' && (
            <Box>
              {props.isLoading && <LoadingDisplay />}
              {!props.isLoading && activeConversations.length === 0 && (
                <EmptyDisplay />
              )}
              <List disablePadding component="div">
                {activeConversations.map(c => (
                  <ConversationItem
                    key={c.conversationId}
                    conversation={c}
                    isSelected={
                      props.selectedConversation?.conversationId ===
                      c.conversationId
                    }
                    onClick={() => handleConversationClick(c)}
                    onArchive={() => {
                      setMenuConversation(c);
                      setOpenArchiveDialog(true);
                    }}
                    onUnarchive={() => {
                      setMenuConversation(c);
                      setOpenRestoreDialog(true);
                    }}
                    onDelete={() => {
                      setMenuConversation(c);
                      setOpenDeleteDialog(true);
                    }}
                    onRename={() => {
                      setMenuConversation(c);
                      setOpenEditConversationDialog(true);
                    }}
                  />
                ))}
              </List>
            </Box>
          )}
          {currentTab === 'archived' && (
            <Box>
              {props.isLoading && <LoadingDisplay />}
              {!props.isLoading && archivedConversations.length === 0 && (
                <EmptyDisplay />
              )}
              <List disablePadding component="div">
                {archivedConversations.map(c => (
                  <ConversationItem
                    key={c.conversationId}
                    conversation={c}
                    isSelected={
                      props.selectedConversation?.conversationId ===
                      c.conversationId
                    }
                    onClick={() => handleConversationClick(c)}
                    onArchive={() => {
                      setMenuConversation(c);
                      setOpenArchiveDialog(true);
                    }}
                    onUnarchive={() => {
                      setMenuConversation(c);
                      setOpenRestoreDialog(true);
                    }}
                    onDelete={() => {
                      setMenuConversation(c);
                      setOpenDeleteDialog(true);
                    }}
                    onRename={() => {
                      setMenuConversation(c);
                      setOpenEditConversationDialog(true);
                    }}
                  />
                ))}
              </List>
            </Box>
          )}
          {currentTab === 'reports' && (
            <Box>
              {props.isLoading && <LoadingDisplay />}
              {!props.isLoading && attachments.length === 0 && <EmptyDisplay />}
              <List disablePadding component="div">
                {attachments.map(a => (
                  <AttachmentItem
                    key={a.attachmentId}
                    attachment={a}
                    onClick={() => {
                      const conversation = props.conversations.find(
                        c => c.conversationId === a.conversationId
                      );
                      if (conversation) {
                        handleConversationClick(conversation);
                      }
                    }}
                  />
                ))}
              </List>
            </Box>
          )}
        </Box>
      </Scrollbar>
      {menuConversation && (
        <>
          <DeleteConversationDialog
            conversation={menuConversation}
            open={openDeleteDialog}
            onClose={() => {
              setOpenDeleteDialog(false);
            }}
            onSuccess={async () => {
              const updatedConversations = props.conversations.filter(
                c => c.conversationId !== menuConversation.conversationId
              );
              props.setConversations(updatedConversations);
              if (
                props.selectedConversation?.conversationId ===
                menuConversation.conversationId
              ) {
                props.setSelectedConversation(undefined);
              }
              setOpenDeleteDialog(false);
              setMenuConversation(undefined);
            }}
          />
          <ArchiveConversationDialog
            conversation={menuConversation}
            open={openArchiveDialog}
            onClose={() => {
              setOpenArchiveDialog(false);
            }}
            onSuccess={async () => {
              const updatedConversations = props.conversations.map(c => {
                if (c.conversationId === menuConversation.conversationId) {
                  c.status = ConversationStatus.ARCHIVED;
                }
                return c;
              });
              props.setConversations(updatedConversations);
              setOpenArchiveDialog(false);
              setMenuConversation(undefined);
            }}
          />
          <RestoreConversationDialog
            conversation={menuConversation}
            open={openRestoreDialog}
            onClose={() => {
              setOpenRestoreDialog(false);
            }}
            onSuccess={async () => {
              const updatedConversations = props.conversations.map(c => {
                if (c.conversationId === menuConversation.conversationId) {
                  c.status = ConversationStatus.IDLE;
                }
                return c;
              });
              props.setConversations(updatedConversations);
              setOpenRestoreDialog(false);
              setMenuConversation(undefined);
            }}
          />
          <EditConversationDialog
            conversation={menuConversation}
            open={openEditConversationDialog}
            onClose={() => {
              setOpenEditConversationDialog(false);
            }}
            onSuccess={async updatedItem => {
              const updatedConversations = props.conversations.map(c => {
                if (c.conversationId === menuConversation.conversationId) {
                  c.name = updatedItem.name;
                }
                return c;
              });
              props.setConversations(updatedConversations);
              setOpenEditConversationDialog(false);
              setMenuConversation(undefined);
            }}
          />
        </>
      )}
    </Box>
  );
};

interface ConversationItemProps {
  conversation: Conversation;
  isSelected: boolean;
  onClick: () => void;
  onArchive: () => void;
  onUnarchive: () => void;
  onDelete: () => void;
  onRename: () => void;
}

const ConversationItem = (props: ConversationItemProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const messages = props.conversation.messages.filter(
    m => m.role === MessageRole.ASSISTANT || m.role === MessageRole.USER
  );
  const lastMessage = messages[messages.length - 1];

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      display="flex"
      sx={{
        ...(props.isSelected
          ? { background: alpha(theme.colors.alpha.trueWhite[100], 0.08) }
          : {}),
        '&:hover': {
          background: alpha(theme.colors.alpha.trueWhite[100], 0.15)
        }
      }}
    >
      <ListItemButton
        sx={{
          py: 1,
          px: 2,
          width: '100%',
          '&:hover': {
            background: 'transparent'
          }
        }}
        key={props.conversation.conversationId}
        onClick={() => props.onClick()}
      >
        <ListItemAvatar sx={{ mr: -2 }}>
          <Chat />
        </ListItemAvatar>
        <ListItemText
          sx={{
            mr: 1
          }}
          primaryTypographyProps={{
            color: theme.colors.alpha.trueWhite[70],
            variant: 'h5',
            noWrap: true
          }}
          secondaryTypographyProps={{
            color: theme.colors.alpha.trueWhite[50],
            noWrap: true
          }}
          primary={props.conversation.name ?? 'New Conversation'}
          secondary={
            props.conversation.messages &&
            props.conversation.messages.length > 1
              ? `${lastMessage.content.substring(0, 25)}...`
              : undefined
          }
        />
      </ListItemButton>
      <IconButton
        onClick={handleClick}
        sx={{
          color: theme.colors.alpha.trueWhite[70],
          '&:hover': {
            color: theme.colors.alpha.trueWhite[100],
            background: alpha(theme.colors.alpha.trueWhite[100], 0.06)
          }
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem onClick={props.onRename}>Rename</MenuItem>
        <MenuItem
          onClick={() => {
            if (props.conversation.status === ConversationStatus.ARCHIVED) {
              props.onUnarchive();
            } else {
              props.onArchive();
            }
          }}
        >
          {props.conversation.status === ConversationStatus.ARCHIVED
            ? 'Unarchive'
            : 'Archive'}
        </MenuItem>
        <MenuItem onClick={props.onDelete}>Delete</MenuItem>
      </Menu>
    </Box>
  );
};

interface AttachmentItemProps {
  attachment: Attachment;
  onClick: () => void;
}

const AttachmentItem = (props: AttachmentItemProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [attachmentsMap, setAttachmentsMap] = useState(
    new Map<string, { url: string; expiresOn: string }>()
  );
  const { enqueueSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = async () => {
    if (attachmentsMap.has(props.attachment.attachmentId)) {
      const downloadUrl = attachmentsMap.get(
        props.attachment.attachmentId
      )?.url;
      const expiresOn = attachmentsMap.get(
        props.attachment.attachmentId
      )?.expiresOn;

      if (downloadUrl && expiresOn && new Date(expiresOn) > new Date()) {
        await downloadFile(downloadUrl, props.attachment);
        handleClose();
        return;
      }
    }
    const s3DataResponse = await ConversationApi.getAttachmentDownloadUrl(
      props.attachment.conversationId,
      props.attachment.attachmentId
    );

    if (s3DataResponse.success && s3DataResponse.data) {
      setAttachmentsMap(
        attachmentsMap.set(props.attachment.attachmentId, {
          url: s3DataResponse.data.url,
          expiresOn: s3DataResponse.data.expiresOn
        })
      );
      await downloadFile(s3DataResponse.data.url, props.attachment);
      handleClose();
      return;
    } else {
      enqueueSnackbar('Unexpected Error', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    }
  };

  const downloadFile = async (url: string, attachment: Attachment) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = urlBlob;
      link.download = attachment.fileName; // replace with your file's name

      // This is necessary as link.click() does not work on the latest Firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );

      // Remove the link from the DOM
      setTimeout(() => {
        window.URL.revokeObjectURL(urlBlob);
        link.remove();
      }, 100);
    } catch (err) {
      console.error('Error downloading file:', err);
    }
  };

  const getFileIcon = type => {
    switch (type) {
      case 'jpeg':
      case 'png':
        return <ImageIcon fontSize="small" />;
      case 'pdf':
        return <PictureAsPdfIcon fontSize="small" />;
      case 'doc':
      case 'docx':
        return <DescriptionIcon fontSize="small" />;
      default:
        return <DescriptionIcon fontSize="small" />;
    }
  };

  return (
    <Box
      display="flex"
      sx={{
        '&:hover': {
          background: alpha(theme.colors.alpha.trueWhite[100], 0.15)
        },
        '&:active': {
          background: alpha(theme.colors.alpha.trueWhite[100], 0.12)
        }
      }}
    >
      <ListItemButton
        sx={{
          py: 1,
          px: 2,
          width: '100%',
          '&:hover': {
            background: 'transparent'
          }
        }}
        key={props.attachment.attachmentId}
        onClick={() => props.onClick()}
      >
        <ListItemAvatar sx={{ mr: -2 }}>
          {getFileIcon(props.attachment.fileType)}
        </ListItemAvatar>
        <ListItemText
          sx={{
            mr: 1
          }}
          primaryTypographyProps={{
            color: theme.colors.alpha.trueWhite[70],
            variant: 'h5',
            noWrap: true
          }}
          secondaryTypographyProps={{
            color: theme.colors.alpha.trueWhite[50],
            noWrap: true
          }}
          primary={props.attachment.fileName}
          secondary={new Date(props.attachment.createdOn).toLocaleString()}
        />
      </ListItemButton>
      <IconButton
        onClick={handleClick}
        sx={{
          color: theme.colors.alpha.trueWhite[70],
          '&:hover': {
            color: theme.colors.alpha.trueWhite[100],
            background: alpha(theme.colors.alpha.trueWhite[100], 0.06)
          }
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem onClick={handleDownload}>Download</MenuItem>
      </Menu>
    </Box>
  );
};

interface EmptyDisplayProps {
  text?: string;
}

const EmptyDisplay = (props: EmptyDisplayProps) => (
  <Box py={3}>
    <AvatarSuccess>
      <CheckTwoToneIcon />
    </AvatarSuccess>
    <Typography
      sx={{
        mt: 2,
        textAlign: 'center',
        color: theme => theme.colors.alpha.trueWhite[50]
      }}
      variant="subtitle2"
    >
      {props.text ?? 'There are no items to show'}
    </Typography>
  </Box>
);

const LoadingDisplay = () => (
  <Box display="flex" justifyContent="center" py={3}>
    <CircularProgress
      size={48}
      sx={{
        color: theme => theme.colors.alpha.trueWhite[50]
      }}
    />
  </Box>
);
