import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Zoom
} from '@mui/material';
import { UserApi } from 'common/api';
import { FullName } from 'common/types';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface PersonalDetailsDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const PersonalDetailsDialog = (props: PersonalDetailsDialogProps) => {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user, refreshUser } = useAuth();

  const handleUpdateSuccess = () => {
    enqueueSnackbar(t('The user account was updated successfully'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });

    props.setOpen(false);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={handleClose}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t('Update Personal Details')}
        </Typography>
        <Typography variant="subtitle2">
          {t('Fill in the fields below to update your personal details')}
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          email: user?.email ?? '',
          firstName: user?.name.firstName ?? '',
          lastName: user?.name.lastName ?? '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .max(32, t('Must be less than 32 characters'))
            .required(t('The first name field is required')),
          lastName: Yup.string()
            .max(32, t('Must be less than 32 characters'))
            .required(t('The last name field is required')),
          email: Yup.string()
            .email(t('The email provided should be a valid email address'))
            .max(64, t('Must be less than 64 characters'))
            .required(t('The email field is required'))
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            if (!user) return;
            setSubmitting(true);
            const name: FullName = {
              fullName: `${_values.firstName} ${_values.lastName}`,
              firstName: _values.firstName,
              lastName: _values.lastName
            };

            if (user.email !== _values.email) {
              const response = await UserApi.doesEmailExist(_values.email);
              if (response.success && response.data === true) {
                setStatus({ success: false });
                setErrors({ submit: 'Email already exists' });
                setSubmitting(false);
                return;
              }
            }

            const response = await UserApi.updateUser(user.userId, {
              email: _values.email,
              name: name
            });
            if (response.success && response.data) {
              await refreshUser();
              setStatus({ success: true });
              setSubmitting(false);
              handleUpdateSuccess();
              resetForm();
            } else {
              setStatus({ success: false });
              setErrors({ submit: response.errorMessage });
              setSubmitting(false);
            }
          } catch (err: any) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} lg={7}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        label={t('First name')}
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.lastName && errors.lastName)}
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        label={t('Last name')}
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label={t('Email address')}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                p: 3
              }}
            >
              <Button color="secondary" onClick={handleClose}>
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={Boolean(errors.submit) || isSubmitting}
                variant="contained"
              >
                {t('Update')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default PersonalDetailsDialog;
