import CloseIcon from '@mui/icons-material/Close';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  IconButton,
  Slide,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Zoom
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Label } from 'app/components/Label';
import { OrganizationApi } from 'common/api/organization-service-api';
import {
  Organization,
  OrgInvite,
  OrgInviteStatus,
  OrgRole,
  Undefinable
} from 'common/types';
import { format } from 'date-fns';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import {
  ChangeEvent,
  FC,
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const getUserRoleLabel = (userRole: OrgRole): JSX.Element => {
  const map = {
    owner: {
      text: 'Owner',
      color: 'success'
    },
    admin: {
      text: 'Administrator',
      color: 'error'
    },
    user: {
      text: 'User',
      color: 'info'
    }
  };

  const { text, color }: any = map[userRole];

  return <Label color={color}>{text}</Label>;
};

const getInviteStatusLabel = (status: OrgInviteStatus): JSX.Element => {
  const map = {
    accepted: {
      text: 'Accepted',
      color: 'success'
    },
    pending: {
      text: 'Pending',
      color: 'error'
    }
  };

  const { text, color }: any = map[status];

  return <Label color={color}>{text}</Label>;
};

const applyPagination = (
  invites: OrgInvite[],
  page: number,
  limit: number
): OrgInvite[] => {
  return invites.slice(page * limit, page * limit + limit);
};

interface InvitesTableProps {
  invites: OrgInvite[];
  organization: Organization | undefined;
  onSelectChange: (selectedInvites: OrgInvite[]) => void;
}

export const InvitesTable: FC<InvitesTableProps> = ({
  invites,
  organization,
  onSelectChange
}) => {
  const [selectedInvites, setSelectedInvites] = useState<OrgInvite[]>([]);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { refreshOrganization: fetchOrganization } = useAuth();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const paginatedInvites = applyPagination(invites, page, limit);
  const selectedSomeInvites =
    selectedInvites.length > 0 && selectedInvites.length < invites.length;
  const selectedAllInvites = selectedInvites.length === invites.length;

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [interactedInvite, setInterectedInvite] =
    useState<Undefinable<OrgInvite>>(undefined);

  const handleSelectAllInvites = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedInvites(event.target.checked ? invites : []);
  };

  useEffect(() => {
    onSelectChange(selectedInvites);
  }, [selectedInvites]);

  const handleSelectOneInvite = (
    _event: ChangeEvent<HTMLInputElement>,
    invite: OrgInvite
  ): void => {
    if (!selectedInvites.find(u => u.inviteId === invite.inviteId)) {
      setSelectedInvites(prevSelected => [...prevSelected, invite]);
    } else {
      setSelectedInvites(prevSelected =>
        prevSelected.filter(
          prevInvite => prevInvite.inviteId !== invite.inviteId
        )
      );
    }
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleConfirmDelete = (invite: OrgInvite) => {
    setInterectedInvite(invite);
    setOpenConfirmDelete(true);
  };

  const handleResendEmail = async (invite: OrgInvite) => {
    if (!organization?.orgId) return;
    setIsLoading(true);
    const response = await OrganizationApi.resendInvite(
      organization.orgId,
      invite.inviteId
    );
    if (response.success) {
      //TODO disable button for x seconds
      enqueueSnackbar(t('The invite email has been resent'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
      resetFormState();
    } else {
      console.error(response.errorMessage);
    }
  };

  const resetFormState = () => {
    setIsLoading(false);
    setInterectedInvite(undefined);
    setOpenConfirmDelete(false);
    setOpenEditUser(false);
  };

  const handleDeleteCompleted = async () => {
    if (!interactedInvite || !organization?.orgId) return;
    setIsLoading(true);
    const response = await OrganizationApi.deleteInvite(
      organization.orgId,
      interactedInvite.inviteId
    );
    if (response.success) {
      await fetchOrganization();
      enqueueSnackbar(t('The invite has been revoked'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
      resetFormState();
    } else {
      console.error(response.errorMessage);
    }
  };

  return (
    <>
      {paginatedInvites.length === 0 ? (
        <>
          <Typography
            sx={{
              py: 10
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {t('No pending invites found')}
          </Typography>
        </>
      ) : (
        <>
          <TableContainer
            sx={{
              minHeight: `350px`,
              maxHeight: `350px`,
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAllInvites}
                      indeterminate={selectedSomeInvites}
                      onChange={handleSelectAllInvites}
                    />
                  </TableCell>
                  <TableCell>{t('Email')}</TableCell>
                  <TableCell>{t('Role')}</TableCell>
                  <TableCell>{t('Status')}</TableCell>
                  <TableCell>{t('Created On')}</TableCell>
                  <TableCell align="center">{t('Actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedInvites.map(tableInvite => {
                  const isInviteSelected = selectedInvites.find(
                    u => u.inviteId === tableInvite.inviteId
                  )
                    ? true
                    : false;
                  return (
                    <TableRow
                      hover
                      key={tableInvite.inviteId}
                      selected={isInviteSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isInviteSelected}
                          onChange={event =>
                            handleSelectOneInvite(event, tableInvite)
                          }
                          value={isInviteSelected}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>{tableInvite.email}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {getUserRoleLabel(tableInvite.role)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {getInviteStatusLabel(tableInvite.status)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {format(
                          new Date(tableInvite.createdOn),
                          'dd MMMM yyyy h:mm a'
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap>
                          <Tooltip title={t('Resend Email')} arrow>
                            <IconButton
                              onClick={async () => {
                                await handleResendEmail(tableInvite);
                              }}
                              color="primary"
                              disabled={
                                tableInvite.status !==
                                  OrgInviteStatus.PENDING || isLoading
                              }
                            >
                              <ForwardToInboxIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('Delete')} arrow>
                            <span>
                              <IconButton
                                onClick={() => {
                                  handleConfirmDelete(tableInvite);
                                }}
                                color="primary"
                                disabled={
                                  tableInvite.status !== OrgInviteStatus.PENDING
                                }
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            p={'0px 20px'}
            sx={{ borderTop: '1px solid', borderColor: '#afafaf' }}
          >
            <TablePagination
              component="div"
              count={invites.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[10, 25, 50]}
            />
          </Box>
        </>
      )}
      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={resetFormState}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 6
            }}
            variant="h3"
          >
            {t('Are you sure you want to revoke this invite?')}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={resetFormState}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
              startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
              disabled={isLoading}
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};
