import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  styled
} from '@mui/material';
import { IntegrationApi } from 'common/api';
import { Integration, JobType } from 'common/types';
import { format } from 'date-fns';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntegrationOption } from '../lib';
import { syncStatusChips } from '../lib/helpers';
import { IntegrationSettingsTab } from './tabs/IntegrationSettings';

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    minHeight: 'auto'
  }
}));

enum IntegrationOverviewTab {
  Overview = 0,
  Settings = 1
}

interface IntegrationOverviewProps {
  selectedIntegration: IntegrationOption;
  onClose: () => void;
  onSyncRequest: () => void;
  onDelete: (Integration: Integration) => void;
}
export const IntegrationOverview = (props: IntegrationOverviewProps) => {
  const { t }: { t: any } = useTranslation();
  const [currentTab, setCurrentTab] = useState<IntegrationOverviewTab>(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSync = async () => {
    const lastSyncTime = props.selectedIntegration.details?.lastRunTime;
    /*
    if (lastSyncTime) {
      const diff = new Date().getTime() - new Date(lastSyncTime).getTime();
      if (diff < 60 * 60 * 1000) {
        enqueueSnackbar(t('You can only sync once per hour'), {
          variant: 'error'
        });
        return;
      }
    }*/

    if (!props.selectedIntegration.details) {
      enqueueSnackbar(t('Unexpected Error'), {
        variant: 'error'
      });
      return;
    }

    setIsLoading(true);
    try {
      await IntegrationApi.syncIntegration(
        props.selectedIntegration.details.integrationId
      );
      props.onSyncRequest();
      enqueueSnackbar(t('Sync request sent'), {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(t('Sync request failed'), {
        variant: 'error'
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <StyledDialogContent>
        <DialogTitle
          sx={{
            p: 2
          }}
        >
          <Typography variant="h4" gutterBottom>
            {`${props.selectedIntegration.name} Integration`}
          </Typography>
        </DialogTitle>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Tabs
            value={currentTab}
            onChange={(event, newValue) => setCurrentTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Overview" value={IntegrationOverviewTab.Overview} />
            <Tab label="Settings" value={IntegrationOverviewTab.Settings} />
          </Tabs>
        </Box>
        <Divider />
        <Box
          sx={{
            p: 2
          }}
        >
          {currentTab === IntegrationOverviewTab.Overview && (
            <OverviewTab integrationOption={props.selectedIntegration} />
          )}
          {currentTab === IntegrationOverviewTab.Settings && (
            <IntegrationSettingsTab integration={props.selectedIntegration} />
          )}
        </Box>
      </StyledDialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setDeleteDialogOpen(true)}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          onClick={handleSync}
          startIcon={
            isLoading ? (
              <CircularProgress size="1rem" sx={{ color: 'white' }} />
            ) : null
          }
        >
          Sync
        </Button>
      </DialogActions>
      <DeleteDialog
        selectedIntegration={props.selectedIntegration}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onSuccessfulDelete={async () => {
          await props.onDelete(
            props.selectedIntegration.details as Integration
          );
          setDeleteDialogOpen(false);
        }}
      />
    </>
  );
};

interface OverviewTabProps {
  integrationOption: IntegrationOption;
}
const OverviewTab = (props: OverviewTabProps) => {
  const integationDetails = props.integrationOption.details as Integration;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Stack spacing={2}>
          <Typography variant="h6" align="center">
            Integration Details
          </Typography>
          <Typography>
            <strong>Last Sync:</strong>{' '}
            {!integationDetails.lastRunTime
              ? 'N/A'
              : format(
                  new Date(integationDetails.lastRunTime),
                  'dd MMM yyyy h:mm a'
                )}
          </Typography>
          <Typography>
            <strong>Next Sync:</strong>{' '}
            {!integationDetails.nextRunTime ||
            integationDetails.settings.syncType === JobType.ON_DEMAND
              ? 'Not scheduled'
              : format(
                  new Date(integationDetails.nextRunTime),
                  'dd MMM yyyy h:mm a'
                )}
          </Typography>
          <Typography>
            <strong>Total Documents Synced:</strong> 0
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography variant="h6" align="center">
            Sync History
          </Typography>
          {integationDetails.history.length > 0 ? (
            <TableContainer component={Paper} sx={{ pt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Runtime</TableCell>
                    <TableCell>Documents Updated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {integationDetails.history.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell>{syncStatusChips[row.status]}</TableCell>
                      <TableCell>
                        {format(new Date(row.timestamp), 'dd MMM yyyy h:mm a')}
                      </TableCell>
                      <TableCell>{row.updates}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography sx={{ pt: 2 }} align="center">
              No history available.
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

interface DeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccessfulDelete: () => void;
  selectedIntegration: IntegrationOption;
}

const DeleteDialog = (props: DeleteDialogProps) => {
  const { open, onClose, selectedIntegration, onSuccessfulDelete } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { organization, refreshOrganization: fetchOrganization } = useAuth();

  const handleDelete = async () => {
    if (!selectedIntegration.details?.integrationId) {
      enqueueSnackbar('Unexpected Error', { variant: 'error' });
      return;
    }
    setIsLoading(true);
    const response = await IntegrationApi.deleteIntegration(
      selectedIntegration.details.integrationId
    );
    if (response.success) {
      await fetchOrganization();
      enqueueSnackbar('Successfully deleted integration.', {
        variant: 'success'
      });
      onSuccessfulDelete();
    } else {
      enqueueSnackbar('Unexpected Error', { variant: 'error' });
    }
    setIsLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Integration</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the{' '}
          <strong>{selectedIntegration.name}</strong> integration?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleDelete()}
          startIcon={
            isLoading ? (
              <CircularProgress size="1rem" sx={{ color: 'white' }} />
            ) : null
          }
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
