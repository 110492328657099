import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Zoom
} from '@mui/material';
import { OrganizationApi } from 'common/api/organization-service-api';
import { MapToOptions, ProfanityFilterMap } from 'common/helpers';
import { ProfanityFilterLevel } from 'common/types';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface SettingsDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ModerationDialog = (props: SettingsDialogProps) => {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { organization, refreshOrganization: fetchOrganization } = useAuth();

  const profanityFilterOptions = MapToOptions(ProfanityFilterMap);

  const handleUpdateSuccess = () => {
    enqueueSnackbar(t('Organiztation settings were updated successfully'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });

    props.setOpen(false);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={handleClose}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t('Update Settings')}
        </Typography>
        <Typography variant="subtitle2">
          {t('Fill in the fields below to update your settings')}
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          profanityFilterLevel:
            organization?.settings.profanityFilterLevel ??
            ProfanityFilterLevel.MEDIUM,
          submit: null
        }}
        validationSchema={Yup.object().shape({
          profanityFilterLevel: Yup.string().required(
            t('The profanity filter field is required')
          )
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          if (!organization || !organization.orgId) {
            setStatus({ success: false });
            setErrors({ submit: 'Unexpected Error' });
            setSubmitting(false);
            return;
          }
          try {
            setSubmitting(true);
            const response = await OrganizationApi.updateOrganization(
              organization.orgId,
              {
                orgSettings: {
                  ...organization.settings,
                  profanityFilterLevel: _values.profanityFilterLevel
                }
              }
            );
            if (response.success && response.data) {
              await fetchOrganization();
              setStatus({ success: true });
              setSubmitting(false);
              handleUpdateSuccess();
              resetForm();
            } else {
              setStatus({ success: false });
              setErrors({ submit: response.errorMessage });
              setSubmitting(false);
            }
          } catch (err: any) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} lg={7}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <InputLabel>Profanity Filter Level</InputLabel>
                      <Select
                        error={Boolean(
                          touched.profanityFilterLevel &&
                            errors.profanityFilterLevel
                        )}
                        fullWidth
                        value={values.profanityFilterLevel ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="profanityFilterLevel"
                        variant="outlined"
                      >
                        {profanityFilterOptions.map(options => (
                          <MenuItem key={options.value} value={options.value}>
                            {options.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                p: 3
              }}
            >
              <Button color="secondary" onClick={handleClose}>
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={Boolean(errors.submit) || isSubmitting}
                variant="contained"
              >
                {t('Update')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ModerationDialog;
