import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Zoom
} from '@mui/material';
import { ConversationApi } from 'common/api';
import { Attachment } from 'common/types';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

interface AttachmentChipsProps {
  conversationId: string;
  attachments: Attachment[];
}

export const AttachmentChips = (props: AttachmentChipsProps) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [attachmentsMap, setAttachmentsMap] = useState(
    new Map<string, { url: string; expiresOn: string }>()
  );
  const maxChips = 3;

  const truncate = str => {
    const maxLength = 20;
    return str.length > maxLength
      ? str.substring(0, maxLength - 3) + '...'
      : str;
  };

  const handleDownload = async attachment => {
    if (attachmentsMap.has(attachment.attachmentId)) {
      const downloadUrl = attachmentsMap.get(attachment.attachmentId)?.url;
      const expiresOn = attachmentsMap.get(attachment.attachmentId)?.expiresOn;

      if (downloadUrl && expiresOn && new Date(expiresOn) > new Date()) {
        await downloadFile(downloadUrl, attachment);
        return;
      }
    }
    const s3DataResponse = await ConversationApi.getAttachmentDownloadUrl(
      props.conversationId,
      attachment.attachmentId
    );

    if (s3DataResponse.success && s3DataResponse.data) {
      setAttachmentsMap(
        attachmentsMap.set(attachment.attachmentId, {
          url: s3DataResponse.data.url,
          expiresOn: s3DataResponse.data.expiresOn
        })
      );
      await downloadFile(s3DataResponse.data.url, attachment);
      return;
    } else {
      enqueueSnackbar('Unexpected Error', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    }
  };

  const getFileIcon = type => {
    switch (type) {
      case 'jpeg':
      case 'png':
        return <ImageIcon fontSize="small" />;
      case 'pdf':
        return <PictureAsPdfIcon fontSize="small" />;
      case 'doc':
      case 'docx':
        return <DescriptionIcon fontSize="small" />;
      default:
        return <DescriptionIcon fontSize="small" />;
    }
  };

  const downloadFile = async (url: string, attachment: Attachment) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = urlBlob;
      link.download = attachment.fileName; // replace with your file's name

      // This is necessary as link.click() does not work on the latest Firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );

      // Remove the link from the DOM
      setTimeout(() => {
        window.URL.revokeObjectURL(urlBlob);
        link.remove();
      }, 100);
    } catch (err) {
      console.error('Error downloading file:', err);
    }
  };

  const chips = props.attachments
    .slice(0, maxChips)
    .map((attachment, index) => (
      <li key={index}>
        <Chip
          sx={{ margin: 0.5 }}
          icon={getFileIcon(attachment.fileType)}
          label={truncate(attachment.name)}
          clickable
          title={attachment.fileName}
          onClick={() => handleDownload(attachment)}
        />
      </li>
    ));

  if (props.attachments.length > maxChips) {
    chips.push(
      <li key={props.attachments.length}>
        <Chip
          sx={{ margin: 0.5 }}
          label={`+${props.attachments.length - maxChips}`}
          clickable
          onClick={() => setOpen(true)}
        />
      </li>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: 0.5,
        margin: 0
      }}
    >
      {chips}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Additional Attachments</DialogTitle>
        <DialogContent>
          {props.attachments.slice(maxChips).map((attachment, index) => (
            <Chip
              key={index}
              sx={{ margin: 0.5 }}
              icon={getFileIcon(attachment.fileType)}
              label={truncate(attachment.name)}
              clickable
              title={attachment.fileName}
              onClick={() => handleDownload(attachment)}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
