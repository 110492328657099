import { Box, styled, useTheme } from '@mui/material';
import { ConversationApi, WEBSOCKET_URL } from 'common/api';
import {
  Conversation,
  WebsocketEventPayload,
  WebsocketEventTypes
} from 'common/types';
import firebase from 'firebase/compat/app';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket.js';
import { ChatBar } from './ChatBar';
import { ChatContent } from './ChatContent';
import { ConversationsDrawer } from './ConversationsDrawer';
import { ToolDrawer } from './ToolDrawer';
import TopBar from './TopBar';

const ChatWindow = styled(Box)(
  () => `
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
`
);

export const ChatContainer = () => {
  const theme = useTheme();
  const isMobile = window.innerWidth < theme.breakpoints.values.md;
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [openConversationDrawer, setOpenConversationDrawer] = useState<boolean>(
    !isMobile
  );
  const [openToolDrawer, setOpenToolDrawer] = useState<boolean>(false);

  const [selectedConversation, setSelectedConversation] =
    useState<Conversation>();
  const [token, setToken] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [connected, setConnected] = useState<boolean>(false);

  useWebSocket(WEBSOCKET_URL, {
    shouldReconnect: closeEvent => {
      console.log(closeEvent);
      return true;
    },
    onOpen: () => {
      console.log('Connected');
      setConnected(true);
    },
    onClose: () => {
      console.log('Disconnected');
      getToken();
    },
    share: true,
    onMessage: event => {
      const newMessage = JSON.parse(event.data) as WebsocketEventPayload<any>;
      if (newMessage.type === WebsocketEventTypes.CONVO_UPDATE) {
        updateConversions(newMessage.payload);
      }
    },
    queryParams: {
      token: token ?? ''
    }
  });

  useEffect(() => {
    getToken();
    const init = async () => {
      setIsLoading(true);
      await getConversationsRequest();
      setIsLoading(false);
    };
    init();
  }, []);

  const getToken = async () => {
    const user = firebase.auth().currentUser;
    const token = user
      ? await user.getIdToken(/* forceRefresh */ true)
      : undefined;
    setToken(token);
  };

  const updateConversions = (conversation: Conversation) => {
    let conversationsCopy = [...conversations];
    const indexOfConversation = conversationsCopy.findIndex(
      c => c.conversationId === conversation.conversationId
    );

    if (indexOfConversation !== -1) {
      conversationsCopy[indexOfConversation] = conversation;
    } else {
      conversationsCopy.unshift(conversation);
      setSelectedConversation(conversation);
    }

    if (selectedConversation?.conversationId === conversation.conversationId) {
      setSelectedConversation(conversation);
    }

    setConversations(conversationsCopy);
  };

  const getConversationsRequest = useCallback(async () => {
    try {
      const response = await ConversationApi.getUserConversations();
      if (response.success && response.data) {
        let conversations = response.data.items;
        console.log('Conversations: ', conversations);

        conversations.sort((a, b) => {
          return (
            new Date(b.lastUpdated).getTime() -
            new Date(a.lastUpdated).getTime()
          );
        });

        console.log('Sorted Conversations: ', conversations);
        setConversations(conversations);
        if (
          !selectedConversation ||
          !conversations.find(
            c => c.conversationId === selectedConversation.conversationId
          )
        ) {
          setSelectedConversation(undefined);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Ava - Chat</title>
      </Helmet>
      <Box
        sx={{
          height: 'calc(100vh)',
          display: 'flex'
        }}
      >
        <ConversationsDrawer
          open={openConversationDrawer}
          isLoading={isLoading}
          onClose={() => {
            setOpenConversationDrawer(false);
          }}
          conversations={conversations}
          setConversations={setConversations}
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
        />
        <ChatWindow>
          <TopBar
            conversation={selectedConversation}
            onMenuOpen={() => setOpenConversationDrawer(true)}
            onToolsOpen={() => setOpenToolDrawer(true)}
          />
          <ChatContent
            isLoading={isLoading || !connected}
            selectedConversation={selectedConversation}
            setSelectedConversation={setSelectedConversation}
            conversations={conversations}
            setConversations={setConversations}
          />
          <ChatBar
            isLoading={isLoading || !connected}
            selectedConversation={selectedConversation}
            setSelectedConversation={setSelectedConversation}
            conversations={conversations}
            setConversations={setConversations}
          />
        </ChatWindow>
        <ToolDrawer
          open={openToolDrawer}
          isLoading={isLoading || !connected}
          setOpen={setOpenToolDrawer}
          conversations={conversations}
          setConversations={setConversations}
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
        />
      </Box>
    </>
  );
};
