import { AccountStatus, OrgRole, UserRole } from 'common/types';
import { orgRoles, statuses, userRoles } from './contants';

export const getFormattedRoleLabel = (role: UserRole) => {
  const roleLabel = userRoles.find(r => r.value === role)?.label;

  return roleLabel ?? 'No Role Assigned';
};

export const getFormattedOrgRoleLabel = (role: OrgRole) => {
  const roleLabel = orgRoles.find(r => r.value === role)?.label;

  return roleLabel ?? 'No Role Assigned';
};

export const getFormattedStatusLabel = (status: AccountStatus) => {
  const statusLabel = statuses.find(r => r.value === status)?.label;

  return statusLabel ?? 'No Status Assigned';
};
