import { forwardRef, ReactElement, Ref, useState } from 'react';

import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  styled,
  Typography,
  Zoom
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TransitionProps } from '@mui/material/transitions';
import MultiSelectUserGroups, {
  UserGroup
} from 'app/components/MultiSelectUserGroups';
import { DocumentApi } from 'common/api';
import { Document } from 'common/types';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface BulkActionsProps {
  selectedDocuments: Document[];
  updateDocuments: (
    type: 'update' | 'delete' | 'create',
    items: Document[]
  ) => void;
}

function BulkActions(props: BulkActionsProps) {
  const { t }: { t: any } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ message?: string }>({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openEditGroups, setOpenEditGroups] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const handleDeleteFiles = async () => {
    try {
      setIsLoading(true);
      if (!user?.orgId) {
        setErrors({ message: 'Unexpected Error' });
        setIsLoading(false);
        return;
      }

      const response = await DocumentApi.deleteFiles(
        props.selectedDocuments.map(file => file.documentId)
      );
      if (response) {
        props.updateDocuments('delete', props.selectedDocuments);
        resetFormState();
        enqueueSnackbar(t('The files have been deleted'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      }
    } catch (err: any) {
      console.error(err);
      setErrors({ message: err.message });
    }
    setIsLoading(false);
  };

  const resetFormState = () => {
    setOpenDelete(false);
    setOpenEditGroups(false);
  };

  const handleUpdateCompleted = async () => {
    resetFormState();
    enqueueSnackbar(t('The files have been updated'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" color="text.secondary">
            {t('Bulk actions')}:
          </Typography>
          <Button
            sx={{
              ml: 1
            }}
            size="small"
            startIcon={<EditIcon />}
            variant="contained"
            onClick={() => setOpenEditGroups(true)}
          >
            {t('Set Groups')}
          </Button>
          <ButtonError
            sx={{
              ml: 1
            }}
            size="small"
            startIcon={<DeleteIcon />}
            variant="contained"
            onClick={() => setOpenDelete(true)}
          >
            {t('Delete')}
          </ButtonError>
        </Box>
      </Box>
      <DialogWrapper
        open={openDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={resetFormState}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 6
            }}
            variant="h3"
          >
            {t(
              'This cannot be undone. Are you sure you want to remove these files'
            )}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={resetFormState}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteFiles}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
              startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
              disabled={isLoading}
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openEditGroups}
        onClose={resetFormState}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('Update Groups')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Fill in the fields below to update the groups for the selected users.'
            )}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={{
            permissionGroups: [] as UserGroup[],
            submit: null
          }}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              setSubmitting(true);
              if (!user?.orgId) return;

              const response = await DocumentApi.updateDocuments(
                props.selectedDocuments.map(f => f.documentId),
                {
                  setGroupIds: _values.permissionGroups.map(g => g.groupId)
                }
              );

              if (response.success && response.data) {
                const updatedDocuments: Document[] =
                  props.selectedDocuments.map(f => {
                    return {
                      ...f,
                      permissionGroups: _values.permissionGroups.map(g => {
                        return {
                          key: g.groupId,
                          effect: 'allow'
                        };
                      })
                    };
                  });
                props.updateDocuments('update', updatedDocuments);
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
                handleUpdateCompleted();
              } else {
                setStatus({ success: false });
                setErrors({ submit: response.errorMessage });
                setSubmitting(false);
              }
            } catch (err: any) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              {Boolean(touched.submit && errors.submit) && (
                <Box textAlign="center">
                  <Alert severity="error">{errors.submit}</Alert>
                </Box>
              )}
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} lg={7}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MultiSelectUserGroups
                          value={values.permissionGroups ?? []}
                          onChange={handleChange}
                          name="permissionGroups"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={resetFormState}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {t('Update documents')}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default BulkActions;
