import { useState } from 'react';

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
  Zoom
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import EditIcon from '@mui/icons-material/Edit';
import MultiSelectUserGroups from 'app/components/MultiSelectUserGroups';
import {
  OrganizationApi,
  OrganizationMember
} from 'common/api/organization-service-api';
import { Organization, OrgPermissionGroup, OrgRole } from 'common/types';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { orgRoles, UpdateType } from './lib';

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

interface BulkActionsProps {
  updateUsers: (type: UpdateType, items: OrganizationMember[]) => void;
  selectedUsers: OrganizationMember[];
  organization: Organization | undefined;
}

function BulkActions(props: BulkActionsProps) {
  const { t }: { t: any } = useTranslation();
  const [openEditGroups, setOpenEditGroups] = useState(false);
  const [openEditRole, setOpenEditRole] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user, refreshOrganization: fetchOrganization } = useAuth();

  const handleUpdateCompleted = async () => {
    resetFormState();
    enqueueSnackbar(t('The user account has been updated'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const resetFormState = () => {
    setOpenEditGroups(false);
    setOpenEditRole(false);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" color="text.secondary">
            {t('Bulk actions')}:
          </Typography>
          <Button
            sx={{
              ml: 1
            }}
            size="small"
            startIcon={<EditIcon />}
            variant="contained"
            onClick={() => setOpenEditRole(true)}
          >
            {t('Set Role')}
          </Button>
          <Button
            sx={{
              ml: 1
            }}
            size="small"
            startIcon={<EditIcon />}
            variant="contained"
            onClick={() => setOpenEditGroups(true)}
          >
            {t('Set Groups')}
          </Button>
        </Box>
      </Box>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openEditRole}
        onClose={resetFormState}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('Update Role')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Fill in the fields below to update the role for the selected users.'
            )}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={{
            selectedUsers: props.selectedUsers,
            role: OrgRole.USER,
            submit: null
          }}
          validationSchema={Yup.object().shape({
            role: Yup.string().required(t('The role field is required'))
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              setSubmitting(true);
              if (!user?.orgId) return;

              const response = await OrganizationApi.updateMembers(
                user.orgId,
                _values.selectedUsers.map(u => u.userId),
                {
                  role: _values.role
                }
              );
              if (response.success && response.data) {
                const updatedUsers = _values.selectedUsers.map(u => ({
                  ...u,
                  role: _values.role
                }));
                props.updateUsers('update', updatedUsers);
                await fetchOrganization();
                setStatus({ success: true });
                setSubmitting(false);
                handleUpdateCompleted();
                resetForm();
              } else {
                setStatus({ success: false });
                setErrors({ submit: response.errorMessage });
                setSubmitting(false);
              }
            } catch (err: any) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              {Boolean(touched.submit && errors.submit) && (
                <Box textAlign="center">
                  <Alert severity="error">{errors.submit}</Alert>
                </Box>
              )}
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} lg={7}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <InputLabel variant="standard">Role</InputLabel>
                        <Select
                          error={Boolean(touched.role && errors.role)}
                          fullWidth
                          name="role"
                          label={t('Role')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          value={values.role}
                        >
                          {orgRoles.map(r => (
                            <MenuItem key={r.value} value={r.value}>
                              {r.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={resetFormState}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {t('Update user')}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openEditGroups}
        onClose={resetFormState}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('Update Groups')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Fill in the fields below to update the groups for the selected users.'
            )}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={{
            selectedUsers: props.selectedUsers,
            permissionGroups: [] as OrgPermissionGroup[],
            submit: null
          }}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              setSubmitting(true);
              if (!user?.orgId) return;

              const response = await OrganizationApi.updateMembers(
                user.orgId,
                _values.selectedUsers.map(u => u.userId),
                {
                  groupIds: _values.permissionGroups.map(g => g.groupId)
                }
              );
              if (response.success && response.data) {
                const updatedUsers = _values.selectedUsers.map(u => ({
                  ...u,
                  groups: _values.permissionGroups
                }));
                props.updateUsers('update', updatedUsers);
                await fetchOrganization();
                setStatus({ success: true });
                setSubmitting(false);
                handleUpdateCompleted();
                resetForm();
              } else {
                setStatus({ success: false });
                setErrors({ submit: response.errorMessage });
                setSubmitting(false);
              }
            } catch (err: any) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              {Boolean(touched.submit && errors.submit) && (
                <Box textAlign="center">
                  <Alert severity="error">{errors.submit}</Alert>
                </Box>
              )}
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} lg={7}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MultiSelectUserGroups
                          value={values.permissionGroups ?? []}
                          onChange={handleChange}
                          name="permissionGroups"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={resetFormState}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {t('Update users')}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default BulkActions;
