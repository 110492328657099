import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Typography,
  styled
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  FrontendRoutes,
  FullName,
  OrgInvite,
  OrgInviteStatus
} from 'common/types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import Logo from 'app/components/Logo';
import { OrganizationApi } from 'common/api';
import {
  GoogleAuthProvider,
  getAdditionalUserInfo,
  getAuth,
  signInWithPopup
} from 'firebase/auth';
import useAuth from 'hooks/useAuth';
import { EmailRegistration } from './EmailRegistration';
import './index.css';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    overflow: auto;
    flex: 1;
`
);
function RegisterWizard() {
  const { t }: { t: any } = useTranslation();
  const { register, refreshData } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailRegistration, setEmailRegistration] = useState<boolean>(false);
  const [inviteInfo, setInviteInfo] = useState<OrgInvite | undefined>(
    undefined
  );
  const [errorDisplay, setErrorDisplay] = useState<ReactNode | undefined>(
    undefined
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const orgId = searchParams.get('orgId') ?? undefined;
  const inviteId = searchParams.get('inviteId') ?? undefined;

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      if (orgId && inviteId) {
        const response = await OrganizationApi.getInvite(orgId, inviteId);
        if (response.success && response.data) {
          setInviteInfo(response.data);
          setEmailRegistration(false);
          if (response.data.status === OrgInviteStatus.ACCEPTED) {
            setErrorDisplay(
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  p: 4
                }}
              >
                <Typography variant="h4" color="text.secondary">
                  {t('Invite already accepted')}
                </Typography>
              </Box>
            );
          }
        } else {
          setErrorDisplay(
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                p: 4
              }}
            >
              <Typography variant="h4" color="text.secondary">
                {t('Invalid invite link')}
              </Typography>
            </Box>
          );
        }
      }
      setIsLoading(false);
    };
    init();
  }, []);

  return (
    <>
      <Helmet>
        <title>Register</title>
      </Helmet>
      <MainContent>
        <Container
          sx={{
            my: 4
          }}
          maxWidth={emailRegistration ? 'md' : 'sm'}
        >
          <Logo size="l" />
          <Card
            sx={{
              mt: 4,
              pt: 5
            }}
          >
            <Box px={4}>
              <Typography
                variant="h2"
                sx={{
                  mb: 1
                }}
              >
                {t('Create account')}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 1
                }}
              >
                {t('Fill in the fields below to sign up for an account.')}
              </Typography>
            </Box>

            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  p: 4
                }}
              >
                <CircularProgress />
              </Box>
            ) : errorDisplay ? (
              errorDisplay
            ) : emailRegistration ? (
              <EmailRegistration orgId={orgId} inviteInfo={inviteInfo} />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 4
                }}
              >
                <Button
                  color="primary"
                  startIcon={
                    <img
                      width={24}
                      height={24}
                      src="/images/logo/google.svg"
                      alt="Google Logo"
                    />
                  }
                  fullWidth
                  size="large"
                  variant="outlined"
                  onClick={() => {
                    const provider = new GoogleAuthProvider();
                    const auth = getAuth();

                    signInWithPopup(auth, provider)
                      .then(async result => {
                        // This gives you a Google Access Token. You can use it to access the Google API.
                        const credential =
                          GoogleAuthProvider.credentialFromResult(result);

                        if (credential === null) {
                          throw new Error('No credential');
                        }
                        const user = result.user;
                        const additionalUserInfo =
                          getAdditionalUserInfo(result);

                        if (
                          user.email &&
                          additionalUserInfo &&
                          additionalUserInfo.isNewUser
                        ) {
                          const firstName = user.displayName?.split(' ')[0];
                          const lastName = user.displayName?.split(' ')[1];
                          const fullName: FullName = {
                            firstName: firstName ?? '',
                            lastName: lastName ?? '',
                            fullName: user.displayName ?? ''
                          };
                          await register(
                            user.email,
                            fullName,
                            undefined,
                            undefined,
                            undefined,
                            user.uid
                          );

                          navigate(FrontendRoutes.SETUP);
                        } else {
                          navigate(FrontendRoutes.CHAT);
                        }
                      })
                      .catch(error => {
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // The email of the user's account used.
                        const email = error.customData.email;
                        // The AuthCredential type that was used.
                        const credential =
                          GoogleAuthProvider.credentialFromError(error);
                        // ...
                      });
                  }}
                >
                  {t('Sign up with Google')}
                </Button>
                <Box
                  sx={{
                    mt: 3,
                    mb: 1
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        mr: 2
                      }}
                    >
                      <hr />
                    </Box>
                    <span>{t('OR')}</span>
                    <Box
                      sx={{
                        flexGrow: 1,
                        ml: 2
                      }}
                    >
                      <hr />
                    </Box>
                  </Box>
                </Box>
                <Button
                  sx={{
                    mt: 3
                  }}
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={() => {
                    setEmailRegistration(true);
                  }}
                >
                  {t('Sign up with email')}
                </Button>
              </Box>
            )}
          </Card>
        </Container>
      </MainContent>
    </>
  );
}

export default RegisterWizard;
