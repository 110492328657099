import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';

import { Box, Dialog, Grid, useMediaQuery, useTheme } from '@mui/material';
import LogoLoading from 'app/components/LogoLoading';
import PageTitleWrapper from 'app/components/PageTitleWrapper';
import { IntegrationApi } from 'common/api';
import { Integration } from 'common/types';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import IntegrationDirectory from './IntegrationDirectory';
import { IntegrationOverview } from './IntegrationOverview';
import { IntegrationOption, IntegrationStatus } from './lib';
import { AvailableIntegrations } from './lib/integrations';

function IntegrationDashboard() {
  const [openOverviewDialog, setOpenOverviewDialog] = useState<boolean>(false);
  const [selectedIntegration, setSelectedIntegration] =
    useState<IntegrationOption>();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [integrations, setIntegrations] = useState<IntegrationOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { organization } = useAuth();

  useEffect(() => {
    fetchIntegrations();
  }, [organization?.integrationIds]);

  const fetchIntegrations = async () => {
    if (!organization?.orgId) return;
    setIsLoading(true);

    const response = await IntegrationApi.getIntegrations(organization?.orgId);
    if (response.success && response.data) {
      const activeIntegrations = response.data;
      const integrationOptions = activeIntegrations
        .map((integration: Integration) => {
          const integrationOption = AvailableIntegrations.find(
            availableIntegration => {
              return availableIntegration.id === integration.type;
            }
          );
          if (integrationOption) {
            return {
              ...integrationOption,
              status: IntegrationStatus.ACTIVE,
              details: integration
            };
          }
          return undefined;
        })
        .filter(i => i !== undefined) as IntegrationOption[];
      setIntegrations(integrationOptions);
      console.log('integrationOptions', integrationOptions);
    }
    setIsLoading(false);
  };

  const handleCloseDialog = () => {
    setOpenOverviewDialog(false);
    setSelectedIntegration(undefined);
  };

  return (
    <>
      <Helmet>
        <title>Integrations</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader
          activeIntegrations={integrations}
          onIntegrationAdded={async () => {
            await fetchIntegrations();
          }}
        />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 2
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          {isLoading ? (
            <Box display="flex" justifyContent="center">
              <LogoLoading size="l" />
            </Box>
          ) : (
            <IntegrationDirectory
              availableIntegrations={integrations}
              onSettingsClick={integration => {
                setSelectedIntegration(integration);
                setOpenOverviewDialog(true);
              }}
              onSetupClick={integration => {
                setSelectedIntegration(integration);
              }}
            />
          )}
        </Grid>
      </Grid>
      {selectedIntegration && (
        <Dialog
          maxWidth={'md'}
          fullWidth={true}
          open={openOverviewDialog}
          onClose={handleCloseDialog}
          fullScreen={fullScreen}
        >
          <IntegrationOverview
            selectedIntegration={selectedIntegration}
            onClose={handleCloseDialog}
            onDelete={async (integration: Integration) => {
              const updatedIntegrations = integrations.filter(
                i => i.details?.integrationId !== integration.integrationId
              );
              setIntegrations(updatedIntegrations);
              handleCloseDialog();
            }}
            onSyncRequest={() => {
              const updatedIntegration = selectedIntegration;
              if (updatedIntegration.details) {
                updatedIntegration.details.lastRunTime =
                  new Date().toISOString();
                setSelectedIntegration(updatedIntegration);
              }
            }}
          />
        </Dialog>
      )}
    </>
  );
}

export default IntegrationDashboard;
