import BuildIcon from '@mui/icons-material/Build';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography
} from '@mui/material';
import { IntegrationOption } from './lib';

export interface IntegrationCardProps {
  integration: IntegrationOption;
  selected?: boolean;
  onSelect: () => void;
}

export const IntegrationSetupCard = (props: IntegrationCardProps) => {
  const shadow = props.selected
    ? { boxShadow: 'inset 0 0px 20px rgba(0, 0, 0, 0.10)' }
    : { boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)' };
  return (
    <Card sx={shadow}>
      <CardHeader
        title={
          <Box display="flex" justifyContent="center">
            <Typography variant="h4">{props.integration.name}</Typography>
          </Box>
        }
      />
      <CardContent
        sx={{
          display: 'flex',
          pt: '0px',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '15px'
        }}
      >
        {props.integration.logo}
        <Button
          variant="contained"
          startIcon={<BuildIcon fontSize="small" />}
          disabled={props.selected}
          onClick={() => props.onSelect()}
        >
          {props.selected ? 'Selected' : 'Select'}
        </Button>
      </CardContent>
    </Card>
  );
};
