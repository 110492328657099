import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'sanitize.css/sanitize.css';
import reportWebVitals from 'reportWebVitals';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { HashRouter } from 'react-router-dom';
import ScrollTop from './hooks/useScrollTop';

import App from './App';
import { SidebarProvider } from './providers/SidebarContext';
import { AuthProvider } from 'providers/AuthProvider';
import { CookiesProvider } from 'react-cookie';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HelmetProvider>
    <SidebarProvider>
      <CookiesProvider>
        <HashRouter>
          <ScrollTop />
          <AuthProvider>
            <App />
          </AuthProvider>
        </HashRouter>
      </CookiesProvider>
    </SidebarProvider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
