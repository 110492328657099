import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Zoom
} from '@mui/material';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import firebase from '../../../../../utils/firebase';

interface ChangePasswordDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ChangePasswordDialog = (props: ChangePasswordDialogProps) => {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user, refreshUser: fetchUser } = useAuth();

  const handleUpdateSuccess = () => {
    enqueueSnackbar(t('Your password was updated successfully'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });

    props.setOpen(false);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={handleClose}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t('Change Password')}
        </Typography>
        <Typography variant="subtitle2">
          {t('Fill in the fields below to update your password')}
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          newPasswordConfirm: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string()
            .min(8, t('Must be at least 8 characters'))
            .max(64, t('Must be less than 64 characters'))
            .required(t('The password field is required')),
          newPassword: Yup.string()
            .notOneOf(
              [Yup.ref('currentPassword')],
              'New password must be different from current password'
            )
            .min(8, t('Must be at least 8 characters'))
            .max(64, t('Must be less than 64 characters'))
            .required(t('The password field is required')),
          newPasswordConfirm: Yup.string().oneOf(
            [Yup.ref('newPassword'), null],
            'Passwords must match'
          )
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            setSubmitting(true);
            const credential = firebase.auth.EmailAuthProvider.credential(
              user?.email ?? '',
              _values.currentPassword
            );
            const userCredential = await firebase
              .auth()
              .currentUser?.reauthenticateWithCredential(credential);

            if (!userCredential || !userCredential.user) {
              throw new Error('Invalid email or password');
            }

            await userCredential.user.updatePassword(_values.newPassword);
            setStatus({ success: true });
            setSubmitting(false);
            handleUpdateSuccess();
            resetForm();
          } catch (err: any) {
            console.error(err);
            if (err.code === 'auth/wrong-password') {
              setStatus({ success: false });
              setErrors({ submit: 'Invalid Password' });
              setSubmitting(false);
            } else {
              setStatus({ success: false });
              setErrors({ submit: 'Error updating password' });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            {Boolean(touched.submit && errors.submit) && (
              <Box textAlign="center">
                <Alert severity="error">{errors.submit}</Alert>
              </Box>
            )}
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} lg={7}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.currentPassword && errors.currentPassword
                        )}
                        fullWidth
                        helperText={
                          touched.currentPassword && errors.currentPassword
                        }
                        label={t('Current Password')}
                        name="currentPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.currentPassword}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.newPassword && errors.newPassword
                        )}
                        fullWidth
                        helperText={touched.newPassword && errors.newPassword}
                        label={t('New Password')}
                        name="newPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.newPassword}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.newPasswordConfirm &&
                            errors.newPasswordConfirm
                        )}
                        fullWidth
                        helperText={
                          touched.newPasswordConfirm &&
                          errors.newPasswordConfirm
                        }
                        label={t('Confirm New Password')}
                        name="newPasswordConfirm"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.newPasswordConfirm}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                p: 3
              }}
            >
              <Button color="secondary" onClick={handleClose}>
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={Boolean(errors.submit) || isSubmitting}
                variant="contained"
              >
                {t('Change Password')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ChangePasswordDialog;
