import {
  BasicResponse,
  Document,
  HttpMethods,
  PermissionGroup
} from 'common/types';
import { BulkResponse, FetchProps, FetchUrl } from './api-client';

export class DocumentApi {
  public static async getByOrg(
    orgId: string,
    amount?: number,
    startCursor?: string
  ): Promise<BasicResponse<BulkResponse<Document>>> {
    const params = new URLSearchParams({
      orgId: orgId
    });
    if (amount) {
      params.append('amount', amount.toString());
    }
    if (startCursor) {
      params.append('startCursor', startCursor);
    }
    const request: FetchProps = {
      apiEndpoint: `/api/documents`,
      method: HttpMethods.GET,
      parameters: params
    };
    const response = await FetchUrl<BulkResponse<Document>>(request);
    return response;
  }

  public static async getByDocumentId(
    documentId: string
  ): Promise<BasicResponse<Document>> {
    const request: FetchProps = {
      apiEndpoint: `/api/documents/${documentId}`,
      method: HttpMethods.GET
    };
    const response = await FetchUrl<Document>(request);
    return response;
  }

  public static async updateDocument(
    documentId: string,
    updates: {
      setPermissionGroups?: PermissionGroup[];
      addPermissionGroups?: PermissionGroup[];
      removePermissionGroupIds?: string[];
    }
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/documents/${documentId}`,
      method: HttpMethods.PUT,
      body: updates
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async updateDocuments(
    documentIds: string[],
    updates: {
      setGroupIds: string[];
      addGroupIds?: string[];
      removeGroupIds?: string[];
    }
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/documents`,
      method: HttpMethods.PUT,
      body: {
        documentIds,
        setGroupIds: updates.setGroupIds,
        addGroupIds: updates.addGroupIds,
        removeGroupIds: updates.removeGroupIds
      }
    };

    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async deleteFile(
    documentId: string
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/documents/${documentId}`,
      method: HttpMethods.DELETE
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async deleteFiles(
    documentIds: string[]
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/documents`,
      method: HttpMethods.DELETE,
      body: {
        documentIds
      }
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async getPresignedPreviewUrl(
    documentId: string
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/documents/${documentId}/preview`,
      method: HttpMethods.GET
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async getPresignedUploadUrl(
    fileName: string,
    groups: PermissionGroup[]
  ): Promise<BasicResponse<{ url: string; objectKey: string }>> {
    const request: FetchProps = {
      apiEndpoint: `/api/documents`,
      method: HttpMethods.POST,
      body: {
        name: fileName,
        groupIds: groups
      }
    };
    const response = await FetchUrl<{ url: string; objectKey: string }>(
      request
    );
    return response;
  }
}
