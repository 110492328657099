import { Container } from '@mui/material';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';

const terms = `
# Terms of Service

1. **Introduction**
   These Terms of Service ("Terms") govern your use of the Ava product ("Ava") provided by Stoic Software, LLC ("we," "us," or "our"). By accessing or using Ava, you ("you" or "user") agree to be bound by these Terms.
2. **License**
   Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable license to access and use Ava for personal or internal business purposes only.
3. **Account and Registration**
   To use Ava, you may be required to register for an account. You agree to provide accurate and complete information during registration and keep your account information up to date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
4. **Restrictions**
   You agree not to:
   a. Use Ava for any illegal, unauthorized, or harmful purposes.
   b. Modify, copy, or create derivative works based on Ava.
   c. Attempt to gain unauthorized access to Ava or our systems.
   d. Reverse engineer, decompile, or disassemble Ava.
   e. Interfere with the proper functioning of Ava or other users' enjoyment of Ava.
5. **User Content**
   You are responsible for any content you submit, post, or share while using Ava. You grant us a non-exclusive, royalty-free, transferable, sublicensable, worldwide license to use, store, display, reproduce, modify, and distribute your content in connection with Ava. You represent and warrant that you have the necessary rights to grant us this license.
6. **Intellectual Property**
   All intellectual property rights in Ava, including but not limited to trademarks, copyrights, and trade secrets, are owned by Stoic Software, LLC or its licensors. Your use of Ava does not grant you any rights in or to our intellectual property.
7. **Termination**
   We may terminate or suspend your access to Ava at any time, without prior notice or liability, for any reason, including but not limited to a breach of these Terms.
8. **Disclaimer and Limitation of Liability**
   Ava is provided "as is" without warranties of any kind, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. In no event shall Stoic Software, LLC be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use of or inability to use Ava, even if we have been advised of the possibility of such damages.
9. **Indemnification**
   You agree to indemnify, defend, and hold harmless Stoic Software, LLC and its officers, directors, employees, and agents from any claims, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or in connection with your use of Ava or your violation of these Terms.
10. **Changes to These Terms**
    We may update these Terms from time to time. We will notify you of any significant changes by posting the updated Terms on our website or within Ava, and we encourage you to review these Terms periodically.
11. **Governing Law and Jurisdiction**
    These Terms shall be governed by and construed in accordance with the laws of Pennsylvania, United States. Any disputes arising from these Terms will be resolved exclusively in the courts of Pennsylvania.
12. **Contact Us**
    If you have any questions or concerns about these Terms of Service, please contact us at legal@stoicsoftware.net.
`;

export const TermsOfService = () => {
  return (
    <>
      <Container
        sx={{
          my: 4
        }}
        maxWidth="md"
      >
        <ReactMarkdown children={terms} remarkPlugins={[remarkGfm]} />
      </Container>
    </>
  );
};
