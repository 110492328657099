import {
  Box,
  Card,
  Typography,
  Avatar,
  Button,
  CardActionArea,
  CardMedia,
  Divider,
  Tooltip,
  AvatarGroup,
  styled,
  useTheme
} from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Link as RouterLink, NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import Scrollbar from 'app/components/Scrollbar';
import { format } from 'date-fns';

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadiusSm};
    padding: ${theme.spacing(0.5, 1)};
  `
);

interface RecentActivityProps {}
function RecentActivityCard(props: RecentActivityProps) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const actityLog = [
    {
      timestamp: new Date('11/11/2021 10:00:00').toISOString(),
      activity: 'New User',
      description: 'You added a new user.'
    },
    {
      timestamp: new Date('10/11/2021 10:00:00').toISOString(),
      activity: 'New Chatlog',
      description: 'You added a new chatlog.'
    }
  ];

  return (
    <Card>
      <Box
        p={2.5}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography gutterBottom variant="h4">
            {t('Recent Activity')}
          </Typography>
          <Typography variant="subtitle2">
            {t('Your recent activities')}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          height: 350
        }}
      >
        <Scrollbar>
          <Timeline>
            {actityLog.map(log => (
              <TimelineItem
                sx={{
                  p: 0
                }}
              >
                <TimelineOppositeContent
                  sx={{
                    width: '125px',
                    flex: 'none'
                  }}
                  color="text.secondary"
                >
                  {format(new Date(log.timestamp), 'dd MMM yyyy h:mm a')}
                </TimelineOppositeContent>
                <TimelineSeparator
                  sx={{
                    position: 'relative'
                  }}
                >
                  <TimelineDot
                    sx={{
                      marginTop: 0,
                      top: theme.spacing(1.2)
                    }}
                    variant="outlined"
                    color="primary"
                  />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    pb: 4
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    {log.activity}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {log.description}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Scrollbar>
      </Box>
      <Divider />
      <Box
        p={2}
        sx={{
          textAlign: 'center'
        }}
      >
        <NavLink
          to={'/dashboard/security/auditlog'}
          style={{ textDecoration: 'none' }}
        >
          <Button
            sx={{
              textTransform: 'uppercase',
              fontSize: `${theme.typography.pxToRem(12)}`
            }}
            variant="contained"
            endIcon={<KeyboardArrowRightTwoToneIcon />}
          >
            {t('View all activity')}
          </Button>
        </NavLink>
      </Box>
    </Card>
  );
}

export default RecentActivityCard;
