import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDuKIS7p95qMrEbjTACKzvRi-RzqdL5JfE',
  authDomain: 'ava-dev-b91d9.firebaseapp.com',
  projectId: 'ava-dev-b91d9',
  storageBucket: 'ava-dev-b91d9.appspot.com',
  messagingSenderId: '217178250517',
  appId: '1:217178250517:web:406f984575e58897221d1c',
  measurementId: 'G-DR7TFW91YW'
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;
