import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { LanguageMap } from 'common/helpers';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text';
import SettingsDialog from './Dialog/SettingsDialog';

function SettingsTab() {
  const { organization } = useAuth();
  const { t } = useTranslation();
  const [openEditPanel, setOpenEditPanel] = useState(false);
  const [openSAMLSetup, setOpenSAMLSetup] = useState(false);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {t('Organization Details')}
              </Typography>
              <Typography variant="subtitle2">
                {t('Manage settings related to your organization details.')}
              </Typography>
            </Box>
            <Button
              variant="text"
              startIcon={<EditTwoToneIcon />}
              onClick={() => {
                setOpenEditPanel(true);
              }}
            >
              Edit
            </Button>
          </Box>
          <Divider />
          <CardContent sx={{ p: 4 }}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                <Box pr={3} pb={2}>
                  Organization Name:
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Text color="black">{organization?.orgName}</Text>
              </Grid>
              <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                <Box pr={3} pb={2}>
                  Language:
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Text color="black">
                  {LanguageMap[organization?.settings.language ?? 'Undefined']}
                </Text>
              </Grid>
              <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                <Box pr={3} pb={2}>
                  Timezone:
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Text color="black">{organization?.settings.timezone}</Text>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <SettingsDialog open={openEditPanel} setOpen={setOpenEditPanel} />
      </Grid>
      {/* 
      <Grid item xs={12} mb={2}>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {t('Single Sign-On (SSO)')}
              </Typography>
              <Typography variant="subtitle2">
                {t('Manage your SAML settings.')}
              </Typography>
            </Box>
            {!organization?.settings.samlSettings && (
              <Button
                variant="text"
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  setOpenSAMLSetup(true);
                }}
              >
                Setup
              </Button>
            )}
          </Box>
          {organization?.settings.samlSettings && (
            <>
              <Divider />
              <CardContent sx={{ p: 4 }}></CardContent>
            </>
          )}
        </Card>
        <SetupSAMLDialog open={openSAMLSetup} setOpen={setOpenSAMLSetup} />
      </Grid>*/}
    </Grid>
  );
}

export default SettingsTab;
