import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
  Typography
} from '@mui/material';
import { IntegrationSyncStatus, IntegrationType } from 'common/types';
import { ReactNode } from 'react';
import { FieldType, IntegrationField } from './types';

export const statusChips: Record<string, ReactNode> = {
  Active: <Chip label="Active" icon={<CheckCircleIcon />} color="success" />,
  Inactive: <Chip label="Inactive" icon={<CloseIcon />} />,
  Disabled: (
    <Chip label="Disabled" icon={<DoNotDisturbOnIcon />} color="primary" />
  ),
  Error: <Chip label="Error" icon={<ErrorIcon />} color="error" />
};

export const syncStatusChips: Record<string, ReactNode> = {
  [IntegrationSyncStatus.SUCCESS]: (
    <Chip label="Success" size="small" color="success" />
  ),
  [IntegrationSyncStatus.ERROR]: (
    <Chip label="Failure" size="small" color="error" />
  ),
  [IntegrationSyncStatus.WARNING]: (
    <Chip label="Disabled" size="small" color="primary" />
  )
};

export const getFieldComponent = (
  field: IntegrationField,
  type: IntegrationType,
  values: object,
  onChange: (object: object) => void
) => {
  switch (field.type) {
    case FieldType.TEXT:
      return (
        <TextField
          label={field.label}
          type="text"
          name={field.key}
          value={
            values[type]
              ? values[type][field.key]
                ? values[type][field.key]
                : ''
              : ''
          }
          onChange={e =>
            onChange({
              [type]: {
                ...values[type],
                [field.key]: e.target.value
              }
            })
          }
        />
      );
    case FieldType.PASSWORD:
      return (
        <TextField
          label={field.label}
          type="password"
          name={field.key}
          value={
            values[type]
              ? values[type][field.key]
                ? values[type][field.key]
                : ''
              : ''
          }
          onChange={e =>
            onChange({
              [type]: {
                ...values[type],
                [field.key]: e.target.value
              }
            })
          }
        />
      );

    case FieldType.CHECKBOX:
      return (
        <FormControlLabel
          control={
            <Checkbox
              value={
                values[type]
                  ? values[type][field.key]
                    ? values[type][field.key]
                    : false
                  : false
              }
              onChange={e =>
                onChange({
                  [type]: {
                    ...values[type],
                    [field.key]: e.target.checked
                  }
                })
              }
            />
          }
          label={<Typography fontSize={'1rem'}>{field.label}</Typography>}
        />
      );

    default:
      return null;
  }
};
