import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  Link,
  Slide,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Formik } from 'formik';
import { ReactElement, Ref, forwardRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import Logo from 'app/components/Logo';
import { UserApi } from 'common/api';
import { FrontendRoutes } from 'common/types';
import { useTranslation } from 'react-i18next';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      top: -${theme.spacing(6)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);
interface ResetPasswordProps {
  userId: string;
  code: string;
}

function ResetPassword(props: ResetPasswordProps) {
  const { t }: { t: any } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Container maxWidth="sm">
        <Logo size="l" />
        <Card
          sx={{
            mt: 3,
            p: 4
          }}
        >
          <Box>
            <Typography
              variant="h2"
              sx={{
                mb: 1
              }}
            >
              {t('Reset Password')}
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{
                mb: 3
              }}
            >
              {t('Enter your new password below')}
            </Typography>
          </Box>

          <Formik
            initialValues={{
              password: '',
              password_confirm: '',
              submit: null
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(8)
                .max(255)
                .required(t('The password field is required')),
              password_confirm: Yup.string()
                .oneOf(
                  [Yup.ref('password')],
                  t('Both password fields need to be the same')
                )
                .required(t('This field is required'))
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const response = await UserApi.resetPassword(
                  props.userId,
                  props.code,
                  values.password
                );
                if (response.success) {
                  handleOpenDialog();
                  setStatus({ success: true });
                  setSubmitting(false);
                } else {
                  setStatus({ success: false });
                  setErrors({ submit: response.errorMessage });
                  setSubmitting(false);
                }
              } catch (err: any) {
                console.error(err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              isSubmitting,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {Boolean(touched.submit && errors.submit) && (
                  <Box textAlign="center" pb={2}>
                    <Alert severity="error">{errors.submit}</Alert>
                  </Box>
                )}
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label={t('Password')}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.password_confirm && errors.password_confirm
                      )}
                      fullWidth
                      helperText={
                        touched.password_confirm && errors.password_confirm
                      }
                      label={t('Confirm Password')}
                      name="password_confirm"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password_confirm}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Button
                  sx={{
                    mt: 3
                  }}
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  {t('Change Password')}
                </Button>
              </form>
            )}
          </Formik>
        </Card>
        <Box mt={3} textAlign="right">
          <Typography
            component="span"
            variant="subtitle2"
            color="text.primary"
            fontWeight="bold"
          >
            {t('Want to try to sign in again?')}
          </Typography>{' '}
          <Link component={RouterLink} to={FrontendRoutes.LOGIN}>
            <b>Click here</b>
          </Link>
        </Box>
      </Container>
      <DialogWrapper
        open={openDialog}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
      >
        <Box
          sx={{
            px: 4,
            pb: 4,
            pt: 10
          }}
        >
          <AvatarSuccess>
            <CheckTwoToneIcon />
          </AvatarSuccess>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 10
            }}
            variant="h3"
          >
            {t(
              'Your password has been successfully reset. You can now login with your new password.'
            )}
          </Typography>

          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={handleCloseDialog}
            component={RouterLink}
            to={FrontendRoutes.LOGIN}
          >
            {t('Continue to login')}
          </Button>
        </Box>
      </DialogWrapper>
    </>
  );
}

export default ResetPassword;
