import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Button, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImportFileDialog from './Dialogs/ImportFile';

function PageHeader() {
  const { t }: { t: any } = useTranslation();
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleFilesSelected = (files: File[]) => {
    console.log('Files selected:', files);
    setIsImportDialogOpen(false);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('Knowledge Base Manager')}
          </Typography>
          <Typography variant="subtitle2">{t('Description')}</Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            onClick={() => setIsImportDialogOpen(true)}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {t('Import File')}
          </Button>
        </Grid>
      </Grid>
      <ImportFileDialog
        open={isImportDialogOpen}
        onClose={() => setIsImportDialogOpen(false)}
      />
    </>
  );
}

export default PageHeader;
