import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
  Zoom
} from '@mui/material';
import { OrganizationApi } from 'common/api/organization-service-api';
import { WorkflowKey } from 'common/types';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface ToolsSettingsDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ToolsSettingsDialog = (props: ToolsSettingsDialogProps) => {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { organization, refreshOrganization } = useAuth();

  const handleUpdateSuccess = () => {
    enqueueSnackbar(t('Organiztation settings were updated successfully'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });

    props.setOpen(false);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={handleClose}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t('Update AVA Tools')}
        </Typography>
        <Typography variant="subtitle2">
          {t('Select the tools you want to enable for your organization.')}
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          googleSearch: organization?.settings?.enabledTools.includes(
            WorkflowKey.GOOGLE
          ),
          summarizeUrl: organization?.settings?.enabledTools.includes(
            WorkflowKey.SUMMARIZE_URL
          ),
          generateReport: organization?.settings?.enabledTools.includes(
            WorkflowKey.GENERATE_REPORT
          ),
          generatePresentation: organization?.settings?.enabledTools.includes(
            WorkflowKey.GENERATE_PRESENTATION
          ),
          submit: null
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          if (!organization || !organization.orgId) {
            setStatus({ success: false });
            setErrors({ submit: 'Unexpected Error' });
            setSubmitting(false);
            return;
          }
          try {
            setSubmitting(true);
            console.log('values', _values);
            let enabledTools: WorkflowKey[] =
              organization.settings.enabledTools;
            if (_values.googleSearch) {
              enabledTools.push(WorkflowKey.GOOGLE);
            } else {
              enabledTools = enabledTools.filter(
                tool => tool !== WorkflowKey.GOOGLE
              );
            }

            if (_values.summarizeUrl) {
              enabledTools.push(WorkflowKey.SUMMARIZE_URL);
            } else {
              enabledTools = enabledTools.filter(
                tool => tool !== WorkflowKey.SUMMARIZE_URL
              );
            }

            if (_values.generateReport) {
              enabledTools.push(WorkflowKey.GENERATE_REPORT);
            } else {
              enabledTools = enabledTools.filter(
                tool => tool !== WorkflowKey.GENERATE_REPORT
              );
            }

            if (_values.generatePresentation) {
              enabledTools.push(WorkflowKey.GENERATE_PRESENTATION);
            } else {
              enabledTools = enabledTools.filter(
                tool => tool !== WorkflowKey.GENERATE_PRESENTATION
              );
            }

            const enabledToolsSet = new Set(enabledTools);

            const response = await OrganizationApi.updateOrganization(
              organization.orgId,
              {
                orgSettings: {
                  ...organization.settings,
                  enabledTools: Array.from(enabledToolsSet)
                }
              }
            );
            if (response.success && response.data) {
              await refreshOrganization();
              setStatus({ success: true });
              setSubmitting(false);
              handleUpdateSuccess();
              resetForm();
            } else {
              setStatus({ success: false });
              setErrors({ submit: response.errorMessage });
              setSubmitting(false);
            }
          } catch (err: any) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12} lg={7}>
                  <FormControlLabel
                    control={<Checkbox checked={values.googleSearch} />}
                    name="googleSearch"
                    onChange={handleChange}
                    label="Google Searching:"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={12} lg={7}>
                  <FormControlLabel
                    control={<Checkbox checked={values.summarizeUrl} />}
                    name="summarizeUrl"
                    onChange={handleChange}
                    label="Summarize URL:"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={12} lg={7}>
                  <FormControlLabel
                    control={<Checkbox checked={values.generateReport} />}
                    name="generateReport"
                    onChange={handleChange}
                    label="Generate Report:"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={12} lg={7}>
                  <FormControlLabel
                    control={<Checkbox checked={values.generatePresentation} />}
                    name="generatePresentation"
                    onChange={handleChange}
                    label="Generate Presentation:"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                p: 3
              }}
            >
              <Button color="secondary" onClick={handleClose}>
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={Boolean(errors.submit) || isSubmitting}
                variant="contained"
              >
                {t('Update')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ToolsSettingsDialog;
