import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntegrationWizard } from './IntegrationWizard';
import { IntegrationOption } from './lib';

interface PageHeaderProps {
  activeIntegrations: IntegrationOption[];
  onIntegrationAdded: () => void;
}

function PageHeader(props: PageHeaderProps) {
  const { t }: { t: any } = useTranslation();
  const [openSetupWizard, setOpenSetupWizard] = useState(false);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('Integration Manager')}
          </Typography>
          <Typography variant="subtitle2">{t('Description')}</Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            onClick={() => setOpenSetupWizard(true)}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {t('Add Integration')}
          </Button>
        </Grid>
      </Grid>
      <IntegrationWizard
        activeIntegrations={props.activeIntegrations}
        open={openSetupWizard}
        onClose={() => setOpenSetupWizard(false)}
        onSubmit={async () => {
          await props.onIntegrationAdded();
          setOpenSetupWizard(false);
        }}
      />
    </>
  );
}

export default PageHeader;
