import LaunchIcon from '@mui/icons-material/Launch';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useState } from 'react';
import parse from 'url-parse';

export const SourceChips = ({ urls }) => {
  const [open, setOpen] = useState(false);
  const maxUrls = 3;

  const truncate = str => {
    const maxLength = 20;
    return str.length > maxLength
      ? str.substring(0, maxLength - 3) + '...'
      : str;
  };

  const handleRedirect = url => {
    window.open(url, '_blank');
  };

  const createChipLabel = url => {
    let parsed = parse(url);
    let hostname = parsed.hostname.replace('www.', '');
    return truncate(hostname);
  };

  const chips = urls.slice(0, maxUrls).map((url, index) => (
    <li key={index}>
      <Chip
        sx={{ margin: 0.5 }}
        icon={<LaunchIcon fontSize="small" />}
        label={createChipLabel(url)}
        clickable
        title={url}
        onClick={() => handleRedirect(url)}
      />
    </li>
  ));

  if (urls.length > maxUrls) {
    chips.push(
      <li key={urls.length}>
        <Chip
          sx={{ margin: 0.5 }}
          label={`+${urls.length - maxUrls}`}
          clickable
          onClick={() => setOpen(true)}
        />
      </li>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: 0.5,
        margin: 0
      }}
    >
      {chips}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Additional URLs</DialogTitle>
        <DialogContent>
          {urls.slice(maxUrls).map((url, index) => (
            <Chip
              key={index}
              sx={{ margin: 0.5 }}
              icon={<LaunchIcon fontSize="small" />}
              label={createChipLabel(url)}
              clickable
              title={url}
              onClick={() => handleRedirect(url)}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
