import { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';

import { Grid } from '@mui/material';
import PageTitleWrapper from 'app/components/PageTitleWrapper';

import {
  OrganizationApi,
  OrganizationMember
} from 'common/api/organization-service-api';
import useAuth from 'hooks/useAuth';
import Results from './Results';
import { UpdateType } from './lib';

function ManagementUsers() {
  const [users, setUsers] = useState<OrganizationMember[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { organization } = useAuth();

  const updateUsers = (type: UpdateType, items: OrganizationMember[]) => {
    const updatedUsers = [...users];
    if (type === 'create') {
      updatedUsers.concat(items);
    } else if (type === 'update') {
      for (const item of items) {
        updatedUsers[updatedUsers.findIndex(u => u.userId === item.userId)] =
          item;
      }
    } else if (type === 'delete') {
      for (const item of items) {
        updatedUsers.splice(
          updatedUsers.findIndex(u => u.userId === item.userId),
          1
        );
      }
    }
    setUsers(updatedUsers);
  };

  const fetchUsers = async () => {
    if (!organization) return;
    const response = await OrganizationApi.getMembers(organization.orgId);
    if (response.success && response.data) {
      setUsers(response.data.items);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <Helmet>
        <title>Users - Management</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader updateUsers={updateUsers} organization={organization} />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 2
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Results
            users={users}
            isLoadingUsers={isLoading}
            updateUsers={updateUsers}
            organization={organization}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ManagementUsers;
