import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { ProfanityFilterMap } from 'common/helpers';
import { WorkflowKey } from 'common/types';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../components/Text';
import ModerationDialog from './Dialog/ModerationDialog';
import ToolsSettingsDialog from './Dialog/ToolsSettingsDialog';

function ChatSettingsTab() {
  const { organization } = useAuth();
  const { t } = useTranslation();
  const [openEditModeration, setOpenEditModeration] = useState(false);
  const [openEditTools, setOpenEditTools] = useState(false);

  return (
    <Grid container spacing={3} mb={2}>
      <Grid item xs={12}>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {t('Moderation')}
              </Typography>
              <Typography variant="subtitle2">
                {t('Manage settings related to chat moderation.')}
              </Typography>
            </Box>
            <Button
              variant="text"
              startIcon={<EditTwoToneIcon />}
              onClick={() => {
                setOpenEditModeration(true);
              }}
            >
              Edit
            </Button>
          </Box>
          <Divider />
          <CardContent sx={{ p: 4 }}>
            <Typography variant="subtitle2">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    Profanity Filter Level:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    {
                      ProfanityFilterMap[
                        organization?.settings.profanityFilterLevel ??
                          'Undefined'
                      ]
                    }
                  </Text>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
        <ModerationDialog
          open={openEditModeration}
          setOpen={setOpenEditModeration}
        />
      </Grid>

      <Grid item xs={12}>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {t('AVA Tools')}
              </Typography>
              <Typography variant="subtitle2">
                {t('Enable or disable tools that are available to users.')}
              </Typography>
            </Box>
            <Button
              variant="text"
              startIcon={<EditTwoToneIcon />}
              onClick={() => {
                setOpenEditTools(true);
              }}
            >
              Edit
            </Button>
          </Box>
          <Divider />
          <CardContent sx={{ p: 4 }}>
            <Typography variant="subtitle2">
              <Grid container spacing={0}>
                <Grid item xs={6} sm={4} md={3} textAlign="right">
                  <Box pr={3} pb={2}>
                    Google Searching:
                  </Box>
                </Grid>
                <Grid item xs={6} sm={8} md={9}>
                  <Text color="black">
                    {organization?.settings.enabledTools.includes(
                      WorkflowKey.GOOGLE
                    )
                      ? 'Enabled'
                      : 'Disabled'}
                  </Text>
                </Grid>
                <Grid item xs={6} sm={4} md={3} textAlign="right">
                  <Box pr={3} pb={2}>
                    Summarize External Url:
                  </Box>
                </Grid>
                <Grid item xs={6} sm={8} md={9}>
                  <Text color="black">
                    {organization?.settings.enabledTools.includes(
                      WorkflowKey.SUMMARIZE_URL
                    )
                      ? 'Enabled'
                      : 'Disabled'}
                  </Text>
                </Grid>

                <Grid item xs={6} sm={4} md={3} textAlign="right">
                  <Box pr={3} pb={2}>
                    Generate Report:
                  </Box>
                </Grid>
                <Grid item xs={6} sm={8} md={9}>
                  <Text color="black">
                    {organization?.settings.enabledTools.includes(
                      WorkflowKey.GENERATE_REPORT
                    )
                      ? 'Enabled'
                      : 'Disabled'}
                  </Text>
                </Grid>

                <Grid item xs={6} sm={4} md={3} textAlign="right">
                  <Box pr={3} pb={2}>
                    Generate Presentation:
                  </Box>
                </Grid>
                <Grid item xs={6} sm={8} md={9}>
                  <Text color="black">
                    {organization?.settings.enabledTools.includes(
                      WorkflowKey.GENERATE_PRESENTATION
                    )
                      ? 'Enabled'
                      : 'Disabled'}
                  </Text>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
        <ToolsSettingsDialog open={openEditTools} setOpen={setOpenEditTools} />
      </Grid>
    </Grid>
  );
}

export default ChatSettingsTab;
