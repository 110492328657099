import { Settings } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
  styled
} from '@mui/material';

import { JobTypeMap } from 'common/helpers';
import { useTranslation } from 'react-i18next';
import { IntegrationOption } from './lib';
import { statusChips } from './lib/helpers';

const CardWrapper = styled(Box)(
  ({ theme }) => `
  .MuiPaper-root {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
`
);
interface IntegrationDirectoryProps {
  availableIntegrations: IntegrationOption[];
  onSetupClick: (integration: IntegrationOption) => void;
  onSettingsClick: (integration: IntegrationOption) => void;
}
const IntegrationDirectory = (props: IntegrationDirectoryProps) => {
  const { t }: { t: any } = useTranslation();

  const handleSettingsClick = (integration: IntegrationOption) => {
    props.onSettingsClick(integration);
  };
  return (
    <>
      <Card
        variant="outlined"
        sx={{
          p: 3
        }}
      >
        <Grid container spacing={3}>
          <>
            {props.availableIntegrations.length === 0 && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                  }}
                >
                  <Typography variant="h6" color="textSecondary">
                    {t(
                      'No active integrations found. Click "Add Integration" to get started.'
                    )}
                  </Typography>
                </Box>
              </Grid>
            )}
            {props.availableIntegrations.map(integration => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={integration.id}>
                <CardWrapper>
                  <Card>
                    <CardHeader
                      title={
                        <Box display="flex" justifyContent="center">
                          <Typography
                            variant="h4"
                            sx={{
                              ml: '25px'
                            }}
                          >
                            {integration.name}
                          </Typography>
                        </Box>
                      }
                      action={
                        <IconButton
                          onClick={() => handleSettingsClick(integration)}
                        >
                          <Settings />
                        </IconButton>
                      }
                    />
                    <CardContent
                      sx={{
                        display: 'flex',
                        pt: '0px',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                    >
                      {integration.logo}
                      <Box pt={1} pb={1}>
                        <Box>
                          <b>Type: </b>
                          {integration.details?.settings.syncType
                            ? JobTypeMap[integration.details?.settings.syncType]
                            : `Not Configured`}
                        </Box>
                        <Box>
                          <b>Last Sync: </b>
                          {integration.details?.lastRunTime
                            ? new Date(
                                integration.details?.lastRunTime
                              ).toLocaleString()
                            : `Not Synced`}
                        </Box>
                      </Box>

                      {integration.status
                        ? statusChips[integration.status]
                        : ''}
                    </CardContent>
                  </Card>
                </CardWrapper>
              </Grid>
            ))}
          </>
        </Grid>
      </Card>
    </>
  );
};

export default IntegrationDirectory;
