import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'app/layouts/BaseLayout';
import SidebarLayout from 'app/layouts/SidebarLayout';

import { ProtectedRoute } from 'app/components/ProtectedRoute';
import LoginPage from 'app/pages/Auth/Login';
import RecoverPasswordBasic from 'app/pages/Auth/RecoverPassword';
import RegisterWizard from 'app/pages/Auth/RegistrationWizard';
import { AccountSetupWizard } from 'app/pages/Auth/RegistrationWizard/AccountSetupWizard';
import { ChatContainer } from 'app/pages/Chat/ChatContainer';
import DashboardHomePage from 'app/pages/Dashboard/DashboardHomePage';
import DashboardFinance from 'app/pages/Dashboard/Finance';
import GroupsManagementPage from 'app/pages/Dashboard/GroupManagement';
import IntegrationDashboard from 'app/pages/Dashboard/IntegrationDashboard';
import KnowledgebaseManagement from 'app/pages/Dashboard/KnowledgeBaseManager';
import PreferencesPage from 'app/pages/Dashboard/Preferences';
import ManagementUsers from 'app/pages/Dashboard/UserManagement';
import HomePage from 'app/pages/HomePage';
import { TermsOfService } from 'app/pages/Policies/TermsOfService';
import Status404 from 'app/pages/Status/Status404';
import Status500 from 'app/pages/Status/Status500';
import { UnauthorizedPage } from 'app/pages/Status/UnauthorizedPage';
import { UnverifiedPage } from 'app/pages/Status/UnverifiedPage';
import ManagementUserSettings from 'app/pages/UserPreferences/UserSettings';
import { OrgRole, UserRole } from './common/types';

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: 'setup',
        element: (
          <ProtectedRoute isLoggedIn orgRequired={false}>
            <AccountSetupWizard />
          </ProtectedRoute>
        )
      },
      {
        path: 'account',
        element: <ProtectedRoute isLoggedOut />,
        children: [
          {
            path: 'login',
            element: <LoginPage />
          },
          {
            path: 'register',
            element: <RegisterWizard />
          },
          {
            path: 'recover',
            element: <RecoverPasswordBasic />
          }
        ]
      },
      {
        path: 'unauthorized',
        element: <UnauthorizedPage />
      },
      {
        path: 'unverified',
        element: (
          <ProtectedRoute isNotVerified isLoggedIn>
            <UnverifiedPage />
          </ProtectedRoute>
        )
      },
      {
        path: 'tos',
        element: <TermsOfService />
      },
      {
        path: '404',
        element: <Status404 />
      },
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: '/*',
        element: <Navigate to="404" replace />
      }
    ]
  },
  {
    path: 'chat',
    element: (
      <ProtectedRoute isLoggedIn isVerified orgRequired>
        <ChatContainer />
      </ProtectedRoute>
    )
  },
  {
    path: 'profile',
    element: (
      <ProtectedRoute isLoggedIn>
        <BaseLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="settings" replace />
      },
      {
        path: 'settings',
        element: <ManagementUserSettings />
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <ProtectedRoute
        isLoggedIn
        isVerified
        orgRequired
        allowedOrgRoles={[OrgRole.ADMIN, OrgRole.OWNER]}
      >
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="overview" replace />
      },
      {
        path: 'overview',
        element: <DashboardHomePage />
      },
      {
        path: 'knowledgebase',
        element: <KnowledgebaseManagement />
      },
      {
        path: 'security',
        children: [
          {
            path: 'users',
            element: <ManagementUsers />
          },
          {
            path: 'groups',
            element: <GroupsManagementPage />
          },
          {
            path: 'auditlog',
            element: <DashboardFinance />
          }
        ]
      },
      {
        path: 'settings',
        children: [
          {
            path: 'preferences',
            element: <PreferencesPage />
          },
          {
            path: 'integrations',
            element: <IntegrationDashboard />
          }
        ]
      },
      {
        path: 'billing',
        children: [
          {
            path: 'settings',
            element: <DashboardFinance />
          },
          {
            path: 'usage',
            element: <DashboardFinance />
          }
        ]
      }
    ]
  },
  {
    path: 'admin',
    element: (
      <ProtectedRoute allowedRoles={[UserRole.ADMIN]}>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="overview" replace />
      },
      {
        path: 'overview',
        element: <DashboardFinance />
      },
      {
        path: 'settings',
        element: <DashboardFinance />
      },
      {
        path: 'analytics',
        element: <DashboardFinance />
      },
      {
        path: 'manage',
        children: [
          {
            path: '',
            element: <Navigate to="users" replace />
          },
          {
            path: 'users',
            element: <ManagementUsers />
          }
        ]
      }
    ]
  }
];

export default routes;
