import { Autocomplete, TextField } from '@mui/material';
import { OrgPermissionGroup } from 'common/types';
import useAuth from 'hooks/useAuth';

export type UserGroup = Pick<OrgPermissionGroup, 'groupId' | 'name'>;

interface MultiSelectUserGroupsProps {
  onChange: (event: any) => void;
  value: UserGroup[];
  name?: string;
  label?: string;
}

const MultiSelectUserGroups = (props: MultiSelectUserGroupsProps) => {
  const { organization } = useAuth();
  const groups = (organization?.permissionGroups ?? []).map(group => {
    return {
      groupId: group.groupId,
      name: group.name
    };
  });

  const handleSelectionChange = (event: any, newValue: UserGroup[]) => {
    props.onChange({
      target: {
        name: props.name,
        value: newValue
      }
    });
  };

  const isOptionDisabled = (option: UserGroup) => {
    return props.value.some(selected => selected.groupId === option.groupId);
  };

  return (
    <div>
      <Autocomplete
        multiple
        fullWidth
        id="user-groups-autocomplete"
        options={groups}
        getOptionLabel={option => option.name}
        getOptionDisabled={isOptionDisabled}
        filterSelectedOptions
        value={props.value}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={props.label ?? 'User Groups'}
            placeholder="Select user groups"
          />
        )}
        onChange={handleSelectionChange}
      />
    </div>
  );
};

export default MultiSelectUserGroups;
