import {
  BasicResponse,
  FullName,
  HttpMethods,
  Organization,
  OrgIndustry,
  OrgInvite,
  OrgPermissionGroup,
  OrgRole,
  OrgSettings,
  OrgSize,
  SubscriptionType,
  UsageSnapshot,
  UsageStats
} from 'common/types';
import { BulkResponse, FetchProps, FetchUrl } from './api-client';

export interface OrganizationMember {
  userId: string;
  role: OrgRole;
  groups: { groupId: string; name: string }[];
  name: FullName;
  email: string;
  createdAt: string;
}

export class OrganizationApi {
  public static async get(orgId: string): Promise<BasicResponse<Organization>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}`,
      method: HttpMethods.GET
    };
    const response = await FetchUrl<Organization>(request);
    return response;
  }

  public static async createOrganization(
    orgName: string,
    companyIndustry: OrgIndustry,
    companySize: OrgSize,
    subscriptionType: SubscriptionType
  ): Promise<BasicResponse<Organization>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations`,
      method: HttpMethods.POST,
      body: {
        orgName: orgName,
        companyIndustry: companyIndustry,
        companySize: companySize,
        subscriptionType: subscriptionType
      }
    };

    const response = await FetchUrl<Organization>(request);
    return response;
  }

  public static async updateOrganization(
    orgId: string,
    updates: {
      orgName?: string;
      orgSettings?: OrgSettings;
    }
  ): Promise<BasicResponse<Organization>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}`,
      method: HttpMethods.PUT,
      body: {
        orgName: updates.orgName,
        orgSettings: updates.orgSettings
      }
    };
    const response = await FetchUrl<Organization>(request);
    return response;
  }

  public static async getInvites(
    orgId: string
  ): Promise<BasicResponse<OrgInvite[]>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/invites`,
      method: HttpMethods.GET
    };
    const response = await FetchUrl<OrgInvite[]>(request);
    return response;
  }

  public static async inviteMember(
    orgId: string,
    email: string,
    role: OrgRole
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/invites`,
      method: HttpMethods.POST,
      body: {
        email: email,
        role: role
      }
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async getInvite(
    orgId: string,
    inviteId: string
  ): Promise<BasicResponse<OrgInvite>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/invite`,
      method: HttpMethods.GET,
      parameters: new URLSearchParams({ inviteId: inviteId })
    };
    const response = await FetchUrl<OrgInvite>(request);
    return response;
  }

  public static async deleteInvite(
    orgId: string,
    inviteId: string
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/invite`,
      method: HttpMethods.DELETE,
      parameters: new URLSearchParams({ inviteId: inviteId })
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async resendInvite(
    orgId: string,
    inviteId: string
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/invite/resend`,
      method: HttpMethods.POST,
      parameters: new URLSearchParams({ inviteId: inviteId })
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async getGroups(
    orgId: string
  ): Promise<BasicResponse<OrgPermissionGroup[]>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/groups`,
      method: HttpMethods.GET
    };
    const response = await FetchUrl<OrgPermissionGroup[]>(request);
    return response;
  }

  public static async createGroup(
    orgId: string,
    groupName: string,
    userIds?: string[]
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/groups`,
      method: HttpMethods.POST,
      body: {
        groupName: groupName,
        userIds: userIds
      }
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async updateGroup(
    orgId: string,
    groupId: string,
    updates: {
      groupName?: string;
      addUserIds?: string[];
      removeUserIds?: string[];
    }
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/group`,
      method: HttpMethods.PUT,
      parameters: new URLSearchParams({ groupId: groupId }),
      body: {
        groupName: updates.groupName,
        addUserIds: updates.addUserIds,
        removeUserIds: updates.removeUserIds
      }
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async deleteGroup(
    orgId: string,
    groupId: string
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/group`,
      method: HttpMethods.DELETE,
      parameters: new URLSearchParams({ groupId: groupId })
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async deleteGroups(
    orgId: string,
    groupIds: string[]
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/groups`,
      method: HttpMethods.DELETE,
      body: {
        groupIds: groupIds
      }
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async delete(orgId: string): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}`,
      method: HttpMethods.DELETE
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async getMembers(
    orgId: string
  ): Promise<BasicResponse<BulkResponse<OrganizationMember>>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/members`,
      method: HttpMethods.GET
    };
    const response = await FetchUrl<BulkResponse<OrganizationMember>>(request);
    return response;
  }

  public static async updateMembers(
    orgId: string,
    userIds: string[],
    updates: {
      role?: OrgRole;
      groupIds?: string[];
    }
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/members`,
      method: HttpMethods.PUT,
      body: {
        userIds: userIds,
        role: updates.role,
        groupIds: updates.groupIds
      }
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async deleteMembers(
    orgId: string,
    userIds: string[]
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/members`,
      method: HttpMethods.DELETE,
      body: {
        userIds: userIds
      }
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async setupSAMLSettings(params: {
    domainName: string;
    ssoURL: string;
    certs: string[];
    entityID: string;
    rpEntityID: string;
  }): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/saml`,
      method: HttpMethods.POST,
      body: {
        domainName: params.domainName,
        ssoURL: params.ssoURL,
        certs: params.certs,
        entityID: params.entityID,
        rpEntityID: params.rpEntityID
      }
    };

    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async updateSAMLSettings(params: {
    ssoURL?: string;
    certs?: string[];
    entityID?: string;
    rpEntityID?: string;
  }): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/saml`,
      method: HttpMethods.PUT,
      body: {
        ssoURL: params.ssoURL,
        certs: params.certs,
        entityID: params.entityID,
        rpEntityID: params.rpEntityID
      }
    };

    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async deleteSAMLSettings(): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/saml`,
      method: HttpMethods.DELETE
    };

    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async getUsageStats(
    orgId: string,
    startDate?: string,
    endDate?: string
  ): Promise<BasicResponse<UsageStats | UsageSnapshot[]>> {
    const queryParams = new URLSearchParams();
    if (startDate) {
      queryParams.append('startDate', startDate);
    }
    if (endDate) {
      queryParams.append('endDate', endDate);
    }
    const request: FetchProps = {
      apiEndpoint: `/api/organizations/${orgId}/usage`,
      method: HttpMethods.GET,
      parameters: queryParams
    };

    const response = await FetchUrl<UsageStats | UsageSnapshot[]>(request);
    return response;
  }
}
