import { useContext } from 'react';

import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  alpha,
  lighten,
  styled,
  useTheme
} from '@mui/material';
import { SidebarContext } from '../../../../providers/SidebarContext';

import Logo from 'app/components/Logo';
import HeaderNotifications from 'app/layouts/SidebarLayout/Header/Buttons/Notifications';
import { FrontendRoutes } from 'common/types';
import useAuth from 'hooks/useAuth';
import { Link } from 'react-router-dom';
import HeaderUserbox from '../../SidebarLayout/Header/Userbox';
import HeaderMenu from './Menu';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(
          theme.header.background?.toString() ?? '',
          0.95
        )};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const { isAuthenticated, user } = useAuth();

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`
      }}
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <Box component={Link} to={FrontendRoutes.HOME}>
          <Logo size="l" variant="text-right" />
        </Box>

        <HeaderMenu />
      </Stack>
      <Box display="flex" alignItems="center">
        {isAuthenticated && (
          <Box>
            <Button
              component={Link}
              to="/chat"
              size="medium"
              sx={{ mx: 1 }}
              variant="contained"
            >
              Chat
            </Button>
            <Box sx={{ mx: 1 }} component="span">
              <HeaderNotifications />
            </Box>
            <HeaderUserbox />
          </Box>
        )}

        {!isAuthenticated && (
          <Box>
            <Button
              component={Link}
              to="/account/login"
              variant="outlined"
              size="medium"
              sx={{ mx: 0.5 }}
            >
              Login
            </Button>
            <Button
              component={Link}
              to="/account/register"
              variant="contained"
              size="medium"
              sx={{ mx: 0.5 }}
            >
              Sign Up
            </Button>
          </Box>
        )}

        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
