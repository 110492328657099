import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { Box, Card, InputAdornment, Tab, Tabs, TextField } from '@mui/material';
import { TabsContainerWrapper } from 'app/components/TabsContainerWrapper';
import { OrganizationMember } from 'common/api/organization-service-api';
import {
  OrgInvite,
  OrgInviteStatus,
  OrgPermissionGroup,
  OrgRole,
  Organization
} from 'common/types';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BulkActions from './BulkActions';
import { InvitesTable } from './InvitesTable';
import UserFilter from './UserFilter';
import { UserTable } from './UserTable';
import { UpdateType } from './lib';
import { orgRoles } from './lib/contants';

enum UserTab {
  Users = 0,
  Invites = 1
}

interface Filter {
  role?: OrgRole;
  groups?: OrgPermissionGroup[];
}

const applyUserFilters = (
  users: OrganizationMember[],
  query: string,
  filter: Filter
): OrganizationMember[] => {
  return users.filter(user => {
    let matches = true;
    if (query) {
      const properties = ['email', 'name'];
      let containsQuery = false;

      properties.forEach(property => {
        if (property === 'name') {
          if (user.name.fullName.toLowerCase().includes(query.toLowerCase())) {
            containsQuery = true;
          }
        } else if (user[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    if (filter.groups && filter.groups.length > 0) {
      let containsGroup = false;
      filter.groups.forEach(group => {
        if (group.userIds.includes(user.userId)) {
          containsGroup = true;
        }
      });
      if (!containsGroup) {
        matches = false;
      }
    }

    if (filter.role && filter.role !== user.role) {
      matches = false;
    }

    return matches;
  });
};

const applyInviteFilters = (
  invites: OrgInvite[],
  query: string,
  filter: Filter
): OrgInvite[] => {
  return invites.filter(invite => {
    let matches = true;
    if (query) {
      const properties = ['email'];
      let containsQuery = false;

      properties.forEach(property => {
        if (invite[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    if (filter.role && filter.role !== invite.role) {
      matches = false;
    }

    if (invite.status === OrgInviteStatus.ACCEPTED) {
      matches = false;
    }

    return matches;
  });
};

interface ResultsProps {
  users: OrganizationMember[];
  isLoadingUsers: boolean;
  organization: Organization | undefined;
  updateUsers: (type: UpdateType, items: OrganizationMember[]) => void;
}

const Results: FC<ResultsProps> = ({
  isLoadingUsers,
  users,
  organization,
  updateUsers
}) => {
  const [selectedUsers, setSelectedUsers] = useState<OrganizationMember[]>([]);
  const [selectedInvites, setSelectedInvites] = useState<OrgInvite[]>([]);
  const { t }: { t: any } = useTranslation();
  const [query, setQuery] = useState<string>('');
  const [filters, setFilters] = useState<Filter>({});
  const filteredUsers = applyUserFilters(users, query, filters);
  const filteredInvites = applyInviteFilters(
    organization?.invites ?? [],
    query,
    filters
  );
  const [currentTab, setCurrentTab] = useState<UserTab>(0);
  const selectedUserBulkActions = selectedUsers.length > 0;
  const selectedInviteBulkActions = selectedInvites.length > 0;
  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleFilterChange = (
    role: OrgRole | undefined,
    groups: OrgPermissionGroup[] | undefined
  ) => {
    setFilters({ role: role, groups: groups });
    setSelectedUsers([]);
    setSelectedInvites([]);
  };
  return (
    <>
      <Card>
        <Box
          sx={{ pl: '15px', pr: '15px', pt: '6px' }}
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <Box sx={{ minWidth: '50%' }} p={2}>
            {!selectedUserBulkActions && (
              <TextField
                sx={{
                  m: 0
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t('Search by name or email...')}
                value={query}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
              />
            )}
            {selectedUserBulkActions && (
              <BulkActions
                updateUsers={updateUsers}
                selectedUsers={selectedUsers}
                organization={organization}
              />
            )}
          </Box>
          <Box p={2}>
            <UserFilter
              roles={orgRoles}
              groups={organization?.permissionGroups ?? []}
              onFilterChange={handleFilterChange}
            />
          </Box>
        </Box>

        <TabsContainerWrapper>
          <Tabs
            value={currentTab}
            onChange={(event, newValue) => {
              setCurrentTab(newValue);
            }}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab
              icon={<PersonIcon />}
              iconPosition="start"
              label="Users"
              value={UserTab.Users}
            />
            <Tab
              icon={<EmailIcon />}
              iconPosition="start"
              label="Invites"
              value={UserTab.Invites}
            />
          </Tabs>
        </TabsContainerWrapper>

        <Card variant="outlined">
          {currentTab === UserTab.Users && (
            <UserTable
              updateUsers={updateUsers}
              isUsersLoading={isLoadingUsers}
              users={filteredUsers}
              organization={organization}
              onSelectChange={users => {
                setSelectedUsers(users);
              }}
            />
          )}

          {currentTab === UserTab.Invites && (
            <InvitesTable
              invites={filteredInvites}
              organization={organization}
              onSelectChange={invites => {
                setSelectedInvites(invites);
              }}
            />
          )}
        </Card>
      </Card>
    </>
  );
};

export default Results;
