import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Zoom
} from '@mui/material';
import MultiSelectUserGroups from 'app/components/MultiSelectUserGroups';
import { DocumentApi } from 'common/api';
import { Document } from 'common/types';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

interface EditFileDialogProps {
  document: Document;
  open: boolean;
  onClose: () => void;
  onSuccess: (item: Document) => void;
}
function EditFileDialog(props: EditFileDialogProps) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { organization } = useAuth();
  const documentGroups = props.document.permissionGroups
    .map(group => {
      return {
        groupId: group.key,
        name:
          organization?.permissionGroups.find(g => g.groupId === group.key)
            ?.name ?? ''
      };
    })
    .filter(group => group.name !== '');

  const handleSuccess = () => {
    enqueueSnackbar(t('The file metadata was updated successfully'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t('Edit File Metadata')}
        </Typography>
        <Typography variant="subtitle2">
          {t('Edit the file metadata below.')}
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          permissionGroups: documentGroups,
          submit: null
        }}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            setSubmitting(true);
            if (!organization) {
              setErrors({ submit: 'Unexpected Error' });
              setStatus({ success: true });
              setSubmitting(false);
              return;
            }

            const response = await DocumentApi.updateDocument(
              props.document.documentId,
              {
                setPermissionGroups: _values.permissionGroups.map(group => {
                  return { key: group.groupId, effect: 'allow' };
                })
              }
            );
            if (response.success && response.data) {
              setStatus({ success: true });
              setSubmitting(false);
              handleSuccess();
              props.onSuccess({
                ...props.document,
                permissionGroups: _values.permissionGroups.map(group => {
                  return { key: group.groupId, effect: 'allow' };
                })
              });
              resetForm();
            } else {
              setStatus({ success: false });
              setErrors({ submit: response.errorMessage });
              setSubmitting(false);
            }
          } catch (err: any) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            {Boolean(touched.submit && errors.submit) && (
              <Alert severity="error">{errors.submit}</Alert>
            )}
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} lg={7}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={t('File Name')}
                        value={`${props.document.fileName}.${props.document.fileType}`}
                        disabled
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MultiSelectUserGroups
                        value={values.permissionGroups ?? []}
                        onChange={handleChange}
                        name="permissionGroups"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                p: 3
              }}
            >
              <Button color="secondary" onClick={props.onClose}>
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={Boolean(errors.submit) || isSubmitting}
                variant="contained"
              >
                {t('Save')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default EditFileDialog;
