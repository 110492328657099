import {
  BasicResponse,
  HttpMethods,
  Integration,
  IntegrationConnectorProperties,
  IntegrationScope,
  IntegrationType,
  IntegrationWizardForm,
  JobType,
  Organization,
  PermissionGroup
} from 'common/types';
import { FetchProps, FetchUrl } from './api-client';

export class IntegrationApi {
  public static async getIntegrations(
    orgId: string
  ): Promise<BasicResponse<Integration[]>> {
    const request: FetchProps = {
      apiEndpoint: `/api/integrations`,
      method: HttpMethods.GET,
      parameters: new URLSearchParams({ orgId: orgId })
    };
    const response = await FetchUrl<Integration[]>(request);
    return response;
  }

  public static async getIntegration(
    integrationId: string
  ): Promise<BasicResponse<Integration>> {
    const request: FetchProps = {
      apiEndpoint: `/api/integrations/${integrationId}`,
      method: HttpMethods.GET
    };
    const response = await FetchUrl<Integration>(request);
    return response;
  }

  public static async saveWizardForm(formData: {
    scope: IntegrationScope;
    type: IntegrationType;
    startDateTime?: string;
    syncFrequency?: number;
    syncType: JobType;
    defaultPermissions: PermissionGroup[];
    additionalInformation?: Record<string, string>;
  }): Promise<BasicResponse<IntegrationWizardForm>> {
    const request: FetchProps = {
      apiEndpoint: `/api/oauth/forms`,
      method: HttpMethods.POST,
      body: { ...formData }
    };

    const response = await FetchUrl<IntegrationWizardForm>(request);
    return response;
  }

  public static async createIntegration(params: {
    orgId: string;
    type: IntegrationType;
    startDateTime?: string;
    scope: IntegrationScope;
    connector: IntegrationConnectorProperties;
    syncFrequency?: number;
    syncType?: JobType;
    defaultPermissions?: PermissionGroup[];
  }): Promise<BasicResponse<Integration>> {
    const request: FetchProps = {
      apiEndpoint: `/api/integrations`,
      method: HttpMethods.POST,
      body: {
        ...params
      }
    };
    const response = await FetchUrl<Integration>(request);
    return response;
  }

  public static async verifyCredentials(
    type: IntegrationType,
    connector: IntegrationConnectorProperties
  ): Promise<BasicResponse<boolean>> {
    const request: FetchProps = {
      apiEndpoint: `/api/integrations/verify`,
      method: HttpMethods.POST,
      body: {
        type: type,
        connector: connector
      }
    };
    const response = await FetchUrl<boolean>(request);
    return response;
  }

  public static async updateSettings(
    integrationId: string,
    params: {
      nextRunTime?: string;
      syncFrequency?: number;
      syncType?: JobType;
      defaultPermissions?: PermissionGroup[];
      scope?: IntegrationScope;
    }
  ): Promise<BasicResponse<Integration>> {
    const request: FetchProps = {
      apiEndpoint: `/api/integrations/${integrationId}/settings`,
      method: HttpMethods.PUT,
      body: {
        ...params
      }
    };
    const response = await FetchUrl<Integration>(request);
    return response;
  }

  public static async updateConnector(
    integrationId: string,
    connector: IntegrationConnectorProperties
  ): Promise<BasicResponse<Integration>> {
    const request: FetchProps = {
      apiEndpoint: `/api/integrations/${integrationId}/credentials`,
      method: HttpMethods.PUT,
      body: {
        connector: connector
      }
    };
    const response = await FetchUrl<Integration>(request);
    return response;
  }

  public static async deleteIntegration(
    integrationId: string
  ): Promise<BasicResponse<Organization>> {
    const request: FetchProps = {
      apiEndpoint: `/api/integrations/${integrationId}`,
      method: HttpMethods.DELETE
    };
    const response = await FetchUrl<Organization>(request);
    return response;
  }

  public static async syncIntegration(
    integrationId: string
  ): Promise<BasicResponse<Organization>> {
    const request: FetchProps = {
      apiEndpoint: `/api/integrations/${integrationId}/sync`,
      method: HttpMethods.POST
    };
    const response = await FetchUrl<Organization>(request);
    return response;
  }
}
