import { useRef, useState } from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Chat from '@mui/icons-material/Chat';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import { NavLink } from 'react-router-dom';

import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { OrgRoleMap } from 'common/helpers';
import { OrgRole } from 'common/types';
import useAuth from 'hooks/useAuth';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

interface UserProfileBoxProps {
  onChatSettingsClick: () => void;
}
export const UserProfileBox = (props: UserProfileBoxProps) => {
  const theme = useTheme();
  const { user, logout } = useAuth();

  const memberRole = user?.orgRole;

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton
        sx={{
          color: theme.colors.alpha.trueWhite[70],
          background: alpha(theme.colors.alpha.trueWhite[100], 0.08),
          '&:hover': {
            background: alpha(theme.colors.alpha.trueWhite[100], 0.15)
          }
        }}
        color="secondary"
        ref={ref}
        onClick={handleOpen}
      >
        <Avatar
          variant="rounded"
          alt={user?.name.fullName}
          src={'' /*TODO: Avatars*/}
        />

        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar
            variant="rounded"
            alt={user?.name.fullName}
            src={'null' /*TODO: Avatars*/}
          />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user?.name.fullName}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {memberRole && (
                <UserBoxDescription variant="body2">
                  {OrgRoleMap[memberRole]}
                </UserBoxDescription>
              )}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/profile/settings" component={NavLink}>
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Account Settings" />
          </ListItem>
          <ListItem button onClick={props.onChatSettingsClick}>
            <Chat fontSize="small" />
            <ListItemText primary="Chat Settings" />
          </ListItem>
          {(user?.orgRole === OrgRole.ADMIN ||
            user?.orgRole === OrgRole.OWNER) && (
            <ListItem button to="/dashboard/overview" component={NavLink}>
              <AccountBalanceIcon fontSize="small" />
              <ListItemText primary="Admin Console" />
            </ListItem>
          )}
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={() => logout()}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
};
