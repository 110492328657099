import { FilterList } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { OrgPermissionGroup, OrgRole } from 'common/types';
import React, { useState } from 'react';

type UserFilterProps = {
  roles: { label: string; value: OrgRole }[];
  groups: OrgPermissionGroup[];
  onFilterChange: (
    role: OrgRole | undefined,
    groups: OrgPermissionGroup[] | undefined
  ) => void;
};

const UserFilter: React.FC<UserFilterProps> = ({
  roles,
  groups,
  onFilterChange
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRole, setSelectedRole] = useState<OrgRole | undefined>(
    undefined
  );
  const [selectedGroups, setSelectedGroups] = useState<OrgPermissionGroup[]>(
    []
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRoleChange = (event: SelectChangeEvent<OrgRole>) => {
    setSelectedRole(event.target.value as OrgRole);
  };

  const handleGroupChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value as string[];
    setSelectedGroups(groups.filter(g => selectedIds.includes(g.groupId)));
  };

  const applyFilters = () => {
    onFilterChange(selectedRole, selectedGroups);
    handleClose();
  };

  const resetFilters = () => {
    setSelectedRole(undefined);
    setSelectedGroups([]);
    onFilterChange(undefined, []);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <FilterList />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: { minWidth: '300px' }
        }}
      >
        <MenuItem>
          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select value={selectedRole || ''} onChange={handleRoleChange}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {roles.map((role, index) => (
                <MenuItem key={index} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MenuItem>
        <MenuItem>
          <FormControl fullWidth>
            <InputLabel>Groups</InputLabel>
            <Select
              multiple
              value={selectedGroups.map(g => g.groupId)}
              onChange={handleGroupChange}
              renderValue={selected => {
                const names: string[] = [];
                for (let i = 0; i < selected.length; i++) {
                  const group = groups.find(g => g.groupId === selected[i]);
                  if (!group) continue;
                  names.push(group.name);
                }
                return names.join(', ');
              }}
            >
              {groups.map((group, index) => (
                <MenuItem key={index} value={group.groupId}>
                  <ListItemText primary={group.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MenuItem>
        <Box
          sx={{ pl: '15px', pr: '15px', pt: '6px' }}
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <Button onClick={resetFilters} color="primary" variant="outlined">
            Reset
          </Button>
          <Button onClick={applyFilters} color="primary" variant="contained">
            Apply Filters
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default UserFilter;
