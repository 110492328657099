import { Box, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Header from './Header';

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = () => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>Ava - Artifical Virtual Assistant</title>
      </Helmet>
      <Header />
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          display: 'block',
          flex: 1,
          pt: `${theme.header.height}`
        }}
      >
        <Outlet />
      </Box>
    </>
  );
};

export default BaseLayout;
