import { Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

function PageHeader() {
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        {t('Admin Dashboard')}
      </Typography>
      <Typography variant="subtitle2">{t('Description')}</Typography>
    </>
  );
}

export default PageHeader;
