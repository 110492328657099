import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';

import { Box } from '@mui/material';
import PageTitleWrapper from 'app/components/PageTitleWrapper';
import FileExplorer from './FileExplorer';

function KnowledgebaseManagement() {
  return (
    <>
      <Helmet>
        <title>Knowledge Base - Management</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Box
        sx={{
          px: 2
        }}
      >
        <FileExplorer />
      </Box>
    </>
  );
}

export default KnowledgebaseManagement;
