import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom,
  styled
} from '@mui/material';
import MultiSelectUserGroups, {
  UserGroup
} from 'app/components/MultiSelectUserGroups';
import axios from 'axios';
import { DocumentApi } from 'common/api';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Dropzone = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '200px',
  width: '100%',
  backgroundColor: '#f0f0f0',
  border: '2px dashed #aaaaaa',
  borderRadius: '4px',
  cursor: 'pointer'
});

interface ImportFileDialogProps {
  open: boolean;
  onClose: () => void;
}

const ImportFileDialog: React.FC<ImportFileDialogProps> = ({
  open,
  onClose
}) => {
  const { t }: { t: any } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isDragActive, setIsDragActive] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<UserGroup[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSuccess = () => {
    enqueueSnackbar(
      t(
        'The file(s) were updated successfully uploaded. It may take a few minutes for the file to process and display.'
      ),
      {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      }
    );
  };
  const handleError = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const resetForm = () => {
    setSelectedFiles([]);
    setSelectedGroups([]);
    setUploadProgress(0);
    setIsUploading(false);
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(false);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(false);
    setSelectedFiles(prevFiles => [
      ...prevFiles,
      ...Array.from(e.dataTransfer.files)
    ]);
  };

  const addMoreFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files).filter(
        newFile =>
          !selectedFiles.some(
            existingFile => existingFile.name === newFile.name
          )
      );

      setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles(prevFiles => [
        ...prevFiles,
        ...Array.from(e.target.files ?? [])
      ]);
    }
  };

  const removeFile = (index: number) => {
    setSelectedFiles(prevFiles => {
      return prevFiles.filter((_, i) => i !== index);
    });
  };

  const handleImport = async () => {
    setIsUploading(true);
    setUploadProgress(0);

    // Upload files to S3 bucket here, and update `uploadProgress` state accordingly
    for (let fileIndex = 0; fileIndex < selectedFiles.length; fileIndex++) {
      const file = selectedFiles[fileIndex];

      const presignedUrl = await DocumentApi.getPresignedUploadUrl(
        file.name,
        selectedGroups.map(group => {
          return { key: group.groupId, effect: 'allow' };
        })
      );

      if (!presignedUrl.data) {
        console.error('No presigned URL returned');
        handleError(t('Unexpected error occurred.'));
        throw new Error('No presigned URL returned');
      }

      await axios.put(presignedUrl.data.url, file, {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(
            percentCompleted * ((fileIndex + 1) / selectedFiles.length)
          );
        }
      });
    }

    handleSuccess();
    resetForm();
    onClose();
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        resetForm();
        onClose();
      }}
    >
      <DialogTitle>
        <Typography variant="h4" gutterBottom>
          {t('Import Files')}
        </Typography>
        <Typography variant="subtitle2">
          {t('Select files to import. You can select multiple files at once.')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {selectedFiles.length === 0 ? (
          <Box sx={{ mb: 2 }}>
            <Dropzone
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDragOver={onDragOver}
              onDrop={onDrop}
            >
              <Typography variant="h6">
                {isDragActive ? 'Drop files here' : 'Drag & Drop files here'}
              </Typography>
              <Typography variant="body2">or</Typography>
              <input
                accept="*/*"
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                onChange={handleFileInputChange}
                multiple
              />
              <label htmlFor="contained-button-file">
                <Button component="span">Select Files</Button>
              </label>
            </Dropzone>
          </Box>
        ) : (
          <Box sx={{ mb: 2 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell align="right">Size (bytes)</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedFiles.map((file, index) => (
                    <TableRow key={file.name}>
                      <TableCell component="th" scope="row">
                        {file.name}
                      </TableCell>
                      <TableCell align="right">{file.size}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => removeFile(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <input
                accept="*/*"
                style={{ display: 'none' }}
                id="add-more-files"
                type="file"
                onChange={addMoreFiles}
                multiple
              />
              <label htmlFor="add-more-files">
                <Button
                  component="span"
                  color="primary"
                  variant="outlined"
                  startIcon={<AddIcon />}
                >
                  Add Files
                </Button>
              </label>
            </Box>
            <Box sx={{ mt: 2 }}>
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                color={isUploading ? 'primary' : 'secondary'}
              />
            </Box>
          </Box>
        )}

        <Divider sx={{ mb: 2 }} />
        <Box sx={{ mb: 2 }}>
          <Typography variant="h5" gutterBottom>
            {t('File Settings')}
          </Typography>
        </Box>

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={11}>
            <FormControl fullWidth variant="outlined">
              <MultiSelectUserGroups
                value={selectedGroups}
                onChange={event => setSelectedGroups(event.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleImport}
          color="primary"
          variant="contained"
          disabled={isUploading}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportFileDialog;
