import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';

import { Grid } from '@mui/material';
import PageTitleWrapper from 'app/components/PageTitleWrapper';

import Results from './Results';

function GroupsManagementPage() {
  return (
    <>
      <Helmet>
        <title>Groups - Management</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 2
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Results />
        </Grid>
      </Grid>
    </>
  );
}

export default GroupsManagementPage;
