import { Grid } from '@mui/material';
import PageTitleWrapper from 'app/components/PageTitleWrapper';
import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';

import ActiveSubscriptions from './ActiveSubscriptions';
import AllExpenses from './AllExpenses';
import Budget from './Budget';
import MyCards from './MyCards';
import RecentTransactions from './RecentTransactions';
import UpgradeAccount from './UpgradeAccount';

function DashboardFinance() {
  return (
    <>
      <Helmet>
        <title>Finance Dashboard</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item lg={7} md={6} xs={12}>
          <RecentTransactions />
        </Grid>
        <Grid item lg={5} md={6} xs={12}>
          <MyCards />
        </Grid>
        <Grid item xs={12}>
          <UpgradeAccount />
        </Grid>
        <Grid item xs={12}>
          <ActiveSubscriptions />
        </Grid>
        <Grid item md={6} lg={5} xs={12}>
          <Budget />
        </Grid>
        <Grid item md={6} lg={7} xs={12}>
          <AllExpenses />
        </Grid>
      </Grid>
    </>
  );
}

export default DashboardFinance;
