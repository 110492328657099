import ReportIcon from '@mui/icons-material/Report';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Slide,
  Typography,
  styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ConversationApi } from 'common/api';
import { ConversationStatus } from 'common/types';
import { ReactElement, Ref, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
        z-index: 10;
      }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface ArchiveAllConversationsDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const ArchiveAllConversationsDialog = ({
  open,
  onClose,
  onSuccess
}: ArchiveAllConversationsDialogProps) => {
  const { t }: { t: any } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const archiveConversationRequest = async () => {
    const response = await ConversationApi.updateConversations(
      ConversationStatus.ARCHIVED
    );
    if (!response.success) {
      console.error(response.errorMessage);
    } else {
      await onSuccess();
    }
  };

  return (
    <DialogWrapper
      open={open}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      sx={{
        zIndex: 2000
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={5}
      >
        <Avatar>
          <ReportIcon />
        </Avatar>

        <Typography
          align="center"
          sx={{
            py: 4,
            px: 6
          }}
          variant="h3"
        >
          {t('Are you sure you want to archive all conversations')}?
        </Typography>

        <Box>
          <Button
            variant="text"
            size="large"
            sx={{
              mx: 1
            }}
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={async () => {
              setIsLoading(true);
              await archiveConversationRequest();
              setIsLoading(false);
            }}
            size="large"
            sx={{
              mx: 1,
              px: 3
            }}
            variant="contained"
            startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
            disabled={isLoading}
          >
            {t('Archive')}
          </Button>
        </Box>
      </Box>
    </DialogWrapper>
  );
};
