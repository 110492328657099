import { Button } from '@mui/material';
import { Container, Typography } from '@mui/material';
import Footer from 'app/components/Footer';
import { useNavigate } from 'react-router-dom';

export const UnauthorizedPage = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Container component="main" maxWidth="xs">
      <Typography variant="h5">Unauthorized</Typography>
      <br />
      <Typography variant="subtitle1">
        You do not have access to the requested page.
      </Typography>
      <Button onClick={goBack}>Go Back</Button>
      <Footer />
    </Container>
  );
};
