import {
  DocumentSource,
  IntegrationType,
  JobType,
  Language,
  OrgIndustry,
  OrgRole,
  OrgSize,
  Timezone
} from './types';

export const ProfanityFilterMap = {
  high: 'High',
  medium: 'Medium',
  low: 'Low'
};

export const OrgRoleMap = {
  [OrgRole.ADMIN]: 'Admin',
  [OrgRole.OWNER]: 'Owner',
  [OrgRole.USER]: 'User'
};

export const OrgIndustryMap = {
  [OrgIndustry.AGRICULTURE]: 'Agriculture',
  [OrgIndustry.AUTOMOTIVE]: 'Automotive',
  [OrgIndustry.CONSTRUCTION]: 'Construction',
  [OrgIndustry.EDUCATION]: 'Education',
  [OrgIndustry.FINANCE]: 'Finance',
  [OrgIndustry.HEALTHCARE]: 'Healthcare',
  [OrgIndustry.HOSPITALITY]: 'Hospitality',
  [OrgIndustry.MANUFACTURING]: 'Manufacturing',
  [OrgIndustry.MEDIA]: 'Media',
  [OrgIndustry.REAL_ESTATE]: 'Real Estate',
  [OrgIndustry.RETAIL]: 'Retail',
  [OrgIndustry.TECHNOLOGY]: 'Technology',
  [OrgIndustry.TRANSPORTATION]: 'Transportation',
  [OrgIndustry.OTHER]: 'Other'
};

export const OrgSizeMap = {
  [OrgSize.SOLO]: 'Solo - 1 Employee',
  [OrgSize.MICRO]: 'Micro - 2-9 Employees',
  [OrgSize.SMALL]: 'Small - 10-49 Employees',
  [OrgSize.MEDIUM]: 'Medium - 50-249 Employees',
  [OrgSize.LARGE]: 'Large - 250-999 Employees',
  [OrgSize.ENTERPRISE]: 'Enterprise - 1000+ Employees'
};

export const DocumentSourceMap = {
  [DocumentSource.CONFLUENCE]: 'Confluence',
  [DocumentSource.DIRECT_UPLOAD]: 'Direct Upload',
  [DocumentSource.NOTION]: 'Notion'
};

export const IntegrationMap = {
  [IntegrationType.NOTION]: 'Notion',
  [IntegrationType.CONFLUENCE]: 'Confluence',
  [IntegrationType.JIRA]: 'Jira',
  [IntegrationType.SALESFORCE]: 'Salesforce',
  [IntegrationType.SHAREPOINT]: 'Sharepoint',
  [IntegrationType.MICROSOFT_DYNAMICS_365]: 'Microsoft Dynamics 365'
};

export const LanguageMap = {
  [Language.ENGLISH]: 'English',
  [Language.FRENCH]: 'French',
  [Language.GERMAN]: 'German',
  [Language.SPANISH]: 'Spanish'
};

export const TimezoneMap = {
  [Timezone.AST]: 'Atlantic Standard Time',
  [Timezone.EST]: 'Eastern Standard Time',
  [Timezone.CST]: 'Central Standard Time',
  [Timezone.PST]: 'Pacific Standard Time'
};

export const JobTypeMap = {
  [JobType.ON_DEMAND]: 'On Demand',
  [JobType.REOCCURING]: 'Scheduled Reoccurring'
};

export const MapToOptions = (map: any) => {
  return Object.keys(map).map(key => {
    return {
      value: key,
      label: map[key]
    };
  });
};
