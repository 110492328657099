import { AccountStatus, OrgRole, UserRole } from 'common/types';

export type UpdateType = 'create' | 'update' | 'delete';

export const orgRoles = [
  { label: 'Owner', value: OrgRole.OWNER },
  { label: 'Administrator', value: OrgRole.ADMIN },
  { label: 'User', value: OrgRole.USER }
];

export const userRoles = [
  { label: 'Administrator', value: UserRole.ADMIN },
  { label: 'User', value: UserRole.USER }
];

export const statuses = [
  { label: 'Verified', value: AccountStatus.VERIFIED },
  { label: 'Unverified', value: AccountStatus.UNVERIFIED }
];
