import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Zoom
} from '@mui/material';
import { OrganizationApi } from 'common/api/organization-service-api';
import { UserRole } from 'common/types';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface PageHeaderProps {}

function PageHeader(props: PageHeaderProps) {
  const { t }: { t: any } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user, refreshOrganization: fetchOrganization } = useAuth();

  const handleCreateOpen = () => {
    setOpen(true);
  };

  const handleCreateClose = () => {
    setOpen(false);
  };

  const handleCreateSuccess = () => {
    enqueueSnackbar(t('The group was created successfully'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });

    setOpen(false);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('Group Management')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'All aspects related to the management of groups are handled here'
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            onClick={handleCreateOpen}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {t('Create Group')}
          </Button>
        </Grid>
      </Grid>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleCreateClose}>
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('Add new group')}
          </Typography>
          <Typography variant="subtitle2">
            {t('Fill in the fields below to create a new group.')}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={{
            name: '',
            role: UserRole.USER,
            submit: null
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .min(3, t('The name must be at least 3 characters'))
              .max(64, t('The name must be less than 64 characters'))
              .required(t('The name field is required'))
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              setSubmitting(true);
              if (!user?.orgId) {
                setErrors({ submit: 'Unexpected Error' });
                setStatus({ success: false });
                setSubmitting(false);
                return;
              }

              const response = await OrganizationApi.createGroup(
                user?.orgId,
                _values.name
              );
              if (response.success && response.data) {
                await fetchOrganization();
                handleCreateSuccess();
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
              } else {
                setStatus({ success: false });
                setErrors({ submit: response.errorMessage });
                setSubmitting(false);
              }
            } catch (err: any) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} lg={7}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label={t('Group name')}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={handleCreateClose}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {t('Create Group')}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default PageHeader;
