import { Typography } from '@mui/material';
import { ConfluenceLogo, NotionLogo } from 'common/svgs/logos';
import { IntegrationType } from 'common/types';
import { FieldType, IntegrationOption, IntegrationStatus } from './types';

export const AvailableIntegrations: IntegrationOption[] = [
  {
    id: IntegrationType.CONFLUENCE,
    name: 'Confluence',
    logo: <ConfluenceLogo />,
    status: IntegrationStatus.INACTIVE,
    wizardDetails: {
      connectionStep: {
        description: (
          <Typography variant="body1" fontSize="1rem" gutterBottom>
            Connect to your Confluence account by clicking the "Connect" button
            below. You will be redirected to Confluence to authorize the
            connection.
          </Typography>
        ),
        fields: [{ label: 'URL', type: FieldType.TEXT, key: 'domainUrl' }]
      },
      scopeStep: {
        description: (
          <Typography variant="body1" fontSize="1rem" gutterBottom>
            Select which types of Confluence data you would like to sync.
          </Typography>
        ),
        fields: [
          { label: 'Pages', type: FieldType.CHECKBOX, key: 'pages' },
          {
            label: 'Page Attachments',
            type: FieldType.CHECKBOX,
            key: 'pageAttachments'
          },
          {
            label: 'Archived Spaces',
            type: FieldType.CHECKBOX,
            key: 'archivedSpaces'
          },
          {
            label: 'Archived Pages',
            type: FieldType.CHECKBOX,
            key: 'archivedPages'
          }
        ]
      }
    }
  },
  {
    id: IntegrationType.NOTION,
    name: 'Notion',
    logo: <NotionLogo />,
    status: IntegrationStatus.INACTIVE,
    wizardDetails: {
      connectionStep: {
        description: (
          <Typography variant="body1" fontSize="1rem" gutterBottom>
            Connect to your Notion account by clicking the "Connect" button
            below. You will be redirected to Notion to authorize the connection.
          </Typography>
        ),
        fields: []
      },
      scopeStep: {
        description: (
          <Typography variant="body1" fontSize="1rem" gutterBottom>
            Select which types of Notion data you would like to sync.
          </Typography>
        ),
        fields: [
          { label: 'Pages', type: FieldType.CHECKBOX, key: 'pages' },
          {
            label: 'Archived Pages',
            type: FieldType.CHECKBOX,
            key: 'archivedPages'
          }
        ]
      }
    }
  },
  {
    id: IntegrationType.SALESFORCE,
    name: 'Salesforce',
    logo: (
      <img
        width={100}
        height={100}
        src="/images/logo/SalesforceLogo.svg"
        alt="Salesforce Logo"
      />
    ),
    status: IntegrationStatus.INACTIVE,
    wizardDetails: {
      connectionStep: {
        description:
          'Connect to your Salesforce account by clicking the "Connect" button below. You will be redirected to Salesforce to authorize the connection.',
        fields: []
      },
      scopeStep: {
        description: (
          <Typography variant="body1" fontSize="1rem" gutterBottom>
            Select which types of Salesforce data you would like to sync.
          </Typography>
        ),
        fields: [
          {
            label: 'Accounts',
            type: FieldType.CHECKBOX,
            key: 'accounts'
          }
        ]
      }
    }
  },
  {
    id: IntegrationType.MICROSOFT_DYNAMICS_365,
    name: 'Microftsoft Dynamics 365 CRM',
    logo: (
      <img
        width={100}
        height={100}
        src="/images/logo/dynamics.svg"
        alt="Microftsoft Dynamics 365 Logo"
      />
    ),
    status: IntegrationStatus.INACTIVE,
    wizardDetails: {
      connectionStep: {
        description:
          'Connect to your Microftsoft Dynamics 365 account by clicking the "Connect" button below. You will be redirected to Microftsoft Dynamics 365 to authorize the connection.',
        fields: [
          { label: 'Instance Url', type: FieldType.TEXT, key: 'instanceUrl' }
        ]
      },
      scopeStep: {
        description: (
          <Typography variant="body1" fontSize="1rem" gutterBottom>
            Select which types of Microftsoft Dynamics 365 data you would like
            to sync.
          </Typography>
        ),
        fields: [
          {
            label: 'Accounts',
            type: FieldType.CHECKBOX,
            key: 'accounts'
          }
        ]
      }
    }
  },
  {
    id: IntegrationType.JIRA,
    name: 'Jira Cloud',
    logo: (
      <img
        width={100}
        height={100}
        src="/images/logo/jira.svg"
        alt="Jira Logo"
      />
    ),
    status: IntegrationStatus.INACTIVE,
    wizardDetails: {
      connectionStep: {
        description:
          'Connect to your Jira account by clicking the "Connect" button below. You will be redirected to Jira to authorize the connection.',
        fields: [{ label: 'URL', type: FieldType.TEXT, key: 'domainUrl' }]
      },
      scopeStep: {
        description: (
          <Typography variant="body1" fontSize="1rem" gutterBottom>
            Select which types of Jira data you would like to sync.
          </Typography>
        ),
        fields: []
      }
    }
  },
  {
    id: IntegrationType.SHAREPOINT,
    name: 'Sharepoint Online',
    logo: (
      <img
        width={100}
        height={100}
        src="/images/logo/sharepoint.svg"
        alt="Jira Logo"
      />
    ),
    status: IntegrationStatus.INACTIVE,
    wizardDetails: {
      connectionStep: {
        description:
          'Connect to your Sharepoint account by clicking the "Connect" button below. You will be redirected to Sharepoint to authorize the connection.',
        fields: [{ label: 'Tenant Id', type: FieldType.TEXT, key: 'tenantId' }]
      },
      scopeStep: {
        description: (
          <Typography variant="body1" fontSize="1rem" gutterBottom>
            Select which types of Sharepoint data you would like to sync.
          </Typography>
        ),
        fields: []
      }
    }
  }
];
