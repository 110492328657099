import { InsertDriveFile } from '@mui/icons-material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { OrganizationMember } from 'common/api';
import { DocumentSourceMap } from 'common/helpers';
import { Document, Organization } from 'common/types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface FileRowProps {
  document: Document;
  organization: Organization | undefined;
  isSelected: boolean;
  handleSelect: (document: Document) => void;
  onViewClick: (document: Document) => void;
  onEditClick: (document: Document) => void;
  onDeleteClick: (document: Document) => void;
}

export const FileRow = (props: FileRowProps) => {
  const { t }: { t: any } = useTranslation();
  const members = (props.organization?.members ?? []) as OrganizationMember[];
  return (
    <TableRow hover key={props.document.documentId} selected={props.isSelected}>
      <TableCell padding="checkbox">
        <Checkbox
          checked={props.isSelected}
          onChange={() => props.handleSelect(props.document)}
          value={props.isSelected}
        />
      </TableCell>
      <TableCell
        sx={{ maxWidth: '250px' }}
        onClick={() => props.onViewClick(props.document)}
        style={{ cursor: 'pointer' }}
      >
        <Box display="flex">
          <InsertDriveFile />
          <Typography
            sx={{
              ml: '4px',
              mt: '3px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {props.document.fileName}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>{props.document.fileType}</TableCell>
      <TableCell>{props.document.fileSize}</TableCell>
      <TableCell>{DocumentSourceMap[props.document.source]}</TableCell>
      <TableCell sx={{ maxWidth: '150px' }}>
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {props.document.permissionGroups?.length
            ? props.document.permissionGroups
                .map(
                  group =>
                    props.organization?.permissionGroups.find(
                      g => g.groupId === group.key
                    )?.name
                )
                .join(', ')
            : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        {props.document.uploadedByUser !== 'System'
          ? members.find(m => m.userId === props.document.uploadedByUser)?.name
              .fullName ?? ''
          : 'System'}
      </TableCell>
      <TableCell>
        {format(new Date(props.document.dateUploaded), 'dd MMMM yyyy h:mm a')}
      </TableCell>
      <TableCell align="center">
        <Typography noWrap>
          <Tooltip title={t('Edit')} arrow>
            <IconButton
              onClick={() => {
                props.onEditClick(props.document);
              }}
              color="primary"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('Delete')} arrow>
            <IconButton
              onClick={() => {
                props.onDeleteClick(props.document);
              }}
              color="primary"
            >
              <DeleteTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
      </TableCell>
    </TableRow>
  );
};
