import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Dialog, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  AvatarError,
  ButtonError,
  Transition
} from 'app/components/StyledComponents';
import { OrganizationApi } from 'common/api';
import useAuth from 'hooks/useAuth';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DeleteOrganizationDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
export const DeleteOrganizationDialog = (
  props: DeleteOrganizationDialogProps
) => {
  const { t }: { t: any } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { organization, refreshData } = useAuth();

  const resetFormState = () => {
    props.setOpen(false);
  };

  const handleDelete = async () => {
    if (!organization?.orgId) return;
    setIsLoading(true);
    await OrganizationApi.delete(organization.orgId);
    await refreshData();
    setIsLoading(false);
    resetFormState();
  };

  return (
    <Dialog
      open={props.open}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={resetFormState}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={5}
      >
        <AvatarError>
          <CloseIcon />
        </AvatarError>

        <Typography
          align="center"
          sx={{
            py: 4,
            px: 6
          }}
          variant="h4"
        >
          {t(
            'You are about to delete this organization. This action cannot be undone and all data will be lost. Are you sure you want to continue?'
          )}
        </Typography>

        <Box>
          <Button
            variant="text"
            size="large"
            sx={{
              mx: 1
            }}
            onClick={resetFormState}
          >
            {t('Cancel')}
          </Button>
          <ButtonError
            onClick={handleDelete}
            size="large"
            sx={{
              mx: 1,
              px: 3
            }}
            variant="contained"
            startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
            disabled={isLoading}
          >
            {t('Delete')}
          </ButtonError>
        </Box>
      </Box>
    </Dialog>
  );
};
