import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Box, IconButton, Typography, styled, useTheme } from '@mui/material';
import { formatDistance } from 'date-fns';
import { useEffect, useState } from 'react';

import Chat from '@mui/icons-material/Chat';
import { Conversation } from 'common/types';

const RootWrapper = styled(Box)(
  ({ theme }) => `
        @media (min-width: ${theme.breakpoints.values.md}px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
      }
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  background: ${theme.colors.alpha.white[100]};
`
);

const ChatTopBar = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.white[100]};
        border-bottom: ${theme.colors.alpha.black[10]} solid 1px;
        padding: ${theme.spacing(1.5)};
        align-items: center;
`
);
interface TopBarProps {
  conversation: Conversation | undefined;
  onMenuOpen: () => void;
  onToolsOpen: () => void;
}

function TopBar(props: TopBarProps) {
  const [lastMessageSent, setLastMessageSent] = useState<Date | undefined>();
  const theme = useTheme();
  const isMobile = window.innerWidth < theme.breakpoints.values.md;

  useEffect(() => {
    const convo = { ...props.conversation };
    if (!convo || !convo.messages || convo.messages.length === 0) {
      setLastMessageSent(undefined);
      return;
    }
    const lastMessage = convo.messages[convo.messages.length - 1];
    if (lastMessage) {
      setLastMessageSent(new Date(lastMessage.timestamp));
    }
  }, [props.conversation]);

  return (
    <ChatTopBar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
      }}
    >
      <Box display="flex" alignItems="center" sx={{ marginRight: 'auto' }}>
        <IconButtonToggle
          sx={{
            display: { lg: 'none', xs: 'flex' },
            mr: 2
          }}
          color="primary"
          onClick={() => props.onMenuOpen()}
          size="small"
        >
          <MenuTwoToneIcon />
        </IconButtonToggle>
        <RootWrapper>
          <Box display="flex" alignItems="center">
            <Chat />
            <Box ml={2}>
              <Typography variant="h4">
                {props.conversation
                  ? props.conversation.name ?? 'Undefined Name'
                  : 'New Conversation'}
              </Typography>
              <Typography variant="subtitle1">
                {lastMessageSent
                  ? formatDistance(lastMessageSent, new Date(), {
                      addSuffix: true
                    })
                  : 'Ask AVA anything!'}
              </Typography>
            </Box>
          </Box>
        </RootWrapper>
      </Box>
      <Box sx={{ display: isMobile ? 'block' : 'none' }}>
        <IconButtonToggle onClick={() => props.onToolsOpen()} size="small">
          <BuildCircleIcon />
        </IconButtonToggle>
      </Box>
    </ChatTopBar>
  );
}

export default TopBar;
