import CloseIcon from '@mui/icons-material/Close';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Zoom,
  styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import LogoLoading from 'app/components/LogoLoading';
import { OrganizationApi } from 'common/api/organization-service-api';
import { OrgPermissionGroup, OrgRole, Undefinable } from 'common/types';
import { format } from 'date-fns';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  ChangeEvent,
  FC,
  ReactElement,
  Ref,
  forwardRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import BulkActions from './BulkActions';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

interface Filter {
  role?: OrgRole;
  groups?: OrgPermissionGroup[];
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const applyFilters = (
  groups: OrgPermissionGroup[],
  query: string
): OrgPermissionGroup[] => {
  return groups.filter(group => {
    let matches = true;
    if (query) {
      const properties = ['name'];
      let containsQuery = false;

      properties.forEach(property => {
        if (group[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = (
  groups: OrgPermissionGroup[],
  page: number,
  limit: number
): OrgPermissionGroup[] => {
  return groups.slice(page * limit, page * limit + limit);
};

interface ResultsProps {}

const Results: FC<ResultsProps> = () => {
  const [selectedGroups, setSelectedGroups] = useState<OrgPermissionGroup[]>(
    []
  );
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    user,
    organization,
    refreshOrganization: fetchOrganization
  } = useAuth();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState<string>('');
  const filteredGroups = applyFilters(
    organization?.permissionGroups ?? [],
    query
  );
  const paginatedGroups = applyPagination(filteredGroups, page, limit);
  const selectedBulkActions = selectedGroups.length > 0;
  const selectedSomeGroups =
    selectedGroups.length > 0 &&
    selectedGroups.length < (organization?.permissionGroups?.length ?? 0);
  const selectedAllGroups =
    selectedGroups.length === organization?.permissionGroups?.length;

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openEditGroup, setOpenEditGroup] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [interactedGroup, setInterectedGroup] =
    useState<Undefinable<OrgPermissionGroup>>(undefined);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedGroups(
      event.target.checked ? organization?.permissionGroups ?? [] : []
    );
  };

  const handleSelectOne = (
    _event: ChangeEvent<HTMLInputElement>,
    group: OrgPermissionGroup
  ): void => {
    if (!selectedGroups.find(g => g.groupId === group.groupId)) {
      setSelectedGroups(prevSelected => [...prevSelected, group]);
    } else {
      setSelectedGroups(prevSelected =>
        prevSelected.filter(prev => prev.groupId !== group.groupId)
      );
    }
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleConfirmDelete = (group: OrgPermissionGroup) => {
    setInterectedGroup(group);
    setOpenConfirmDelete(true);
  };

  const handleEdit = (group: OrgPermissionGroup) => {
    setInterectedGroup(group);
    setOpenEditGroup(true);
  };

  const handleUpdateCompleted = async () => {
    resetFormState();
    enqueueSnackbar(t('The group has been updated'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const resetFormState = () => {
    setIsLoading(false);
    setInterectedGroup(undefined);
    setOpenConfirmDelete(false);
    setOpenEditGroup(false);
  };

  const handleDeleteCompleted = async () => {
    if (!interactedGroup || !user?.orgId) return;
    setIsLoading(true);
    const response = await OrganizationApi.deleteGroup(
      user.orgId,
      interactedGroup.groupId
    );
    if (response.success && response.data) {
      await fetchOrganization();
      resetFormState();
      enqueueSnackbar(t('The group has been removed'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    } else {
      console.error(response.errorMessage);
    }
  };

  return (
    <>
      <Card>
        <Box
          sx={{ pl: '15px', pr: '15px', pt: '6px' }}
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <Box sx={{ minWidth: '50%' }} p={2}>
            {!selectedBulkActions && (
              <TextField
                sx={{
                  m: 0
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t('Search by name...')}
                value={query}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
              />
            )}
            {selectedBulkActions && (
              <BulkActions selectedGroups={selectedGroups} />
            )}
          </Box>
        </Box>

        <Divider />

        {paginatedGroups.length === 0 ? (
          <>
            <Typography
              sx={{
                py: 10
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {!organization ? (
                <Box display="flex" justifyContent="center">
                  <LogoLoading size="l" />
                </Box>
              ) : (
                t("We couldn't find any groups")
              )}
            </Typography>
          </>
        ) : (
          <>
            <TableContainer
              sx={{
                minHeight: `390px`,
                maxHeight: `390px`,
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAllGroups}
                        indeterminate={selectedSomeGroups}
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>{t('Name')}</TableCell>
                    <TableCell>{t('# of Users')}</TableCell>
                    <TableCell>{t('Created On')}</TableCell>
                    <TableCell align="center">{t('Actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedGroups.map(tableGroup => {
                    const isGroupSelected = selectedGroups.find(
                      g => g.groupId === tableGroup.groupId
                    )
                      ? true
                      : false;
                    return (
                      <TableRow
                        hover
                        key={tableGroup.groupId}
                        selected={isGroupSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isGroupSelected}
                            onChange={event =>
                              handleSelectOne(event, tableGroup)
                            }
                            value={isGroupSelected}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography variant="h5">
                            {tableGroup.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{tableGroup.userIds.length}</Typography>
                        </TableCell>
                        <TableCell>
                          {format(
                            new Date(tableGroup.createdOn),
                            'dd MMMM yyyy h:mm a'
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Typography noWrap>
                            <Tooltip title={t('Edit')} arrow>
                              <IconButton
                                onClick={() => {
                                  handleEdit(tableGroup);
                                }}
                                color="primary"
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t('Delete')} arrow>
                              <IconButton
                                onClick={() => {
                                  handleConfirmDelete(tableGroup);
                                }}
                                color="primary"
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              p={'0px 20px'}
              sx={{ borderTop: '1px solid', borderColor: '#afafaf' }}
            >
              <TablePagination
                component="div"
                count={filteredGroups.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[10, 25, 50]}
              />
            </Box>
          </>
        )}
      </Card>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openEditGroup}
        onClose={resetFormState}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('Update user')}
          </Typography>
          <Typography variant="subtitle2">
            {t('Fill in the fields below to update a user')}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={{
            groupId: interactedGroup?.groupId ?? null,
            name: interactedGroup?.name ?? '',
            submit: null
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .min(3, t('The name must be at least 3 characters'))
              .max(64, t('The name must be less than 64 characters'))
              .required(t('The name field is required'))
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              setSubmitting(true);
              if (!user?.orgId || !_values.groupId) {
                setErrors({ submit: 'Unexpected Error' });
                setStatus({ success: false });
                setSubmitting(false);
                return;
              }

              if (_values.name === interactedGroup?.name) {
                setErrors({ submit: 'Group name has not changed.' });
                setStatus({ success: false });
                setSubmitting(false);
                return;
              }

              const response = await OrganizationApi.updateGroup(
                user.orgId,
                _values.groupId,
                {
                  groupName: _values.name
                }
              );
              if (response.success && response.data) {
                await fetchOrganization();
                handleUpdateCompleted();
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
              } else {
                setStatus({ success: false });
                setErrors({ submit: response.errorMessage });
                setSubmitting(false);
              }
            } catch (err: any) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              {Boolean(touched.submit && errors.submit) && (
                <Box textAlign="center">
                  <Alert severity="error">{errors.submit}</Alert>
                </Box>
              )}
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} lg={7}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label={t('Group name')}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={resetFormState}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {t('Update Group')}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>

      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={resetFormState}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 6
            }}
            variant="h3"
          >
            {t(
              'Are you sure you want to remove this group from the organization'
            )}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={resetFormState}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
              startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
              disabled={isLoading}
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

Results.propTypes = {
  groups: PropTypes.array.isRequired
};

Results.defaultProps = {
  groups: []
};

export default Results;
