import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { UserApi } from 'common/api';
import { FrontendRoutes, FullName, OrgInvite } from 'common/types';
import { Field, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const BoxActions = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]}
`
);

interface EmailRegistrationProps {
  orgId?: string;
  inviteInfo?: OrgInvite;
}

export const EmailRegistration = (props: EmailRegistrationProps) => {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const { register, user, organization } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          terms: true,
          promo: true,
          password: '',
          password_confirm: '',
          email: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t('The email provided should be a valid email address'))
            .max(255)
            .required(t('The email field is required')),
          first_name: Yup.string()
            .max(255)
            .required(t('The first name field is required')),
          last_name: Yup.string()
            .max(255)
            .required(t('The first name field is required')),
          password: Yup.string()
            .min(8)
            .max(255)
            .required(t('The password field is required')),
          password_confirm: Yup.string()
            .oneOf(
              [Yup.ref('password')],
              t('Both password fields need to be the same')
            )
            .required(t('This field is required'))
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          setSubmitting(true);
          console.log(values);
          try {
            if (!values.terms) {
              setErrors({
                submit: t('The terms and conditions must be accepted')
              });
              setStatus({ success: false });
              setSubmitting(false);
              return;
            }
            const response = await UserApi.doesEmailExist(values.email);
            if (response.success && !response.data) {
              setErrors({
                submit: t('The email provided already exists')
              });
              setStatus({ success: false });
              setSubmitting(false);
              return;
            }

            const fullName: FullName = {
              firstName: values.first_name,
              lastName: values.last_name,
              fullName: `${values.first_name} ${values.last_name}`
            };
            const registrationResponse = await register(
              values.email,
              fullName,
              values.password,
              props.orgId,
              props.inviteInfo?.inviteId
            );
            if (registrationResponse.success && registrationResponse.data) {
              navigate(FrontendRoutes.SETUP);
              setStatus({ success: true });
              setSubmitting(false);
            } else {
              setStatus({ success: false });
              setErrors({ submit: 'Unexpected Error' });
              setSubmitting(false);
            }
          } catch (error) {
            console.log(error);
            setErrors({ submit: 'Unexpected Error' });
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            {Boolean(touched.submit && errors.submit) && (
              <Box textAlign="center">
                <Alert severity="error">{errors.submit}</Alert>
              </Box>
            )}
            <Grid container spacing={3} justifyContent="center" p={4}>
              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('Email')}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(touched.first_name && errors.first_name)}
                  fullWidth
                  helperText={touched.first_name && errors.first_name}
                  label={t('First Name')}
                  name="first_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="first_name"
                  value={values.first_name}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(touched.last_name && errors.last_name)}
                  fullWidth
                  helperText={touched.last_name && errors.last_name}
                  label={t('Last Name')}
                  name="last_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="last_name"
                  value={values.last_name}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t('Password')}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {!showPassword ? (
                          <IconButton
                            aria-label="show"
                            onClick={() => {
                              setShowPassword(true);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="show"
                            onClick={() => {
                              setShowPassword(false);
                            }}
                          >
                            <VisibilityOffIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(
                    touched.password_confirm && errors.password_confirm
                  )}
                  fullWidth
                  helperText={
                    touched.password_confirm && errors.password_confirm
                  }
                  label={t('Confirm Password')}
                  name="password_confirm"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={values.password_confirm}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="terms"
                  type="checkbox"
                  component={CheckboxWithLabel}
                  Label={{
                    label: (
                      <Typography variant="body2">
                        {t('I accept the')}{' '}
                        <Link component={NavLink} to={FrontendRoutes.TERMS}>
                          {t('terms and conditions')}
                        </Link>
                        .
                      </Typography>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Box
              pb={2}
              pr={3}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
              >
                {t('Sign Up')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};
