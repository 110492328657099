export enum FrontendRoutes {
  HOME = '/',
  CHAT = '/chat',
  LOGIN = '/account/login',
  SIGNUP = '/account/signup',
  SETUP = '/setup',
  ACCOUNT_SETTINGS = '/profile/settings',
  PRICING = '/pricing',
  DASHBOARD = '/dashboard',
  UNAUTHORIZED = '/unauthorized',
  TERMS = '/tos',
  UNVERIFIED = '/unverified'
}

export enum ExternalRoutes {
  PLACEHOLDER
}

export enum ApiRoutes {
  USER_GET = '/api/user/{userId}',
  USER_GET_ALL = '/api/user/users',
  USER_FETCH = '/api/user/fetch',
  USER_LOGIN = '/api/user/login',
  USER_REGISTER = '/api/user/register',
  USER_CREATE = '/api/user/create',
  USER_UPDATE = '/api/user/update',
  USER_UPDATE_PASSWORD = '/api/user/update/password',
  USER_DELETE = '/api/user/delete',
  CONVERSATION_GET = '/api/conversation/get',
  CONVERSATION_SEND = '/api/conversation/message',
  CONVERSATION_CANCEL = '/api/conversation/cancel',
  CONVERSATION_DELETE = '/api/conversation/delete',
  CONVERSATION_ARCHIVE = '/api/conversation/archive',
  CONVERSATION_UNARCHIVE = '/api/conversation/unarchive'
}
