import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Zoom
} from '@mui/material';
import { ButtonError } from 'app/components/StyledComponents';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArchiveAllConversationsDialog } from './ArchiveAllConversations';
import { DeleteAllConversationsDialog } from './DeleteAllConversations';

interface ConversationSettingsDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDeleteAll: () => void;
  onArchiveAll: () => void;
}

const ConversationSettingsDialog = (props: ConversationSettingsDialogProps) => {
  const { t }: { t: any } = useTranslation();
  const [openDelete, setOpenDelete] = useState(false);
  const [openArchive, setOpenArchive] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleArchiveSuccess = async () => {
    await props.onArchiveAll();
    enqueueSnackbar(t('All conversations have been archived'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });

    props.setOpen(false);
  };

  const handleDeleteSuccess = async () => {
    await props.onDeleteAll();
    enqueueSnackbar(t('All conversations have been deleted'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });

    props.setOpen(false);
  };

  return (
    <>
      <Dialog maxWidth="xs" open={props.open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h4" gutterBottom>
            {t('Chat Settings')}
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            p: 3
          }}
        >
          <Grid container spacing={3} alignContent="center">
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                {' '}
                <Button
                  variant="contained"
                  onClick={async () => {
                    setOpenArchive(true);
                  }}
                >
                  Archive All Conversations
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <ButtonError
                  onClick={async () => {
                    setOpenDelete(true);
                  }}
                >
                  Delete All Conversations
                </ButtonError>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <DeleteAllConversationsDialog
        open={openDelete}
        onClose={() => {
          setOpenDelete(false);
        }}
        onSuccess={async () => {
          await handleDeleteSuccess();
          setOpenDelete(false);
        }}
      />
      <ArchiveAllConversationsDialog
        open={openArchive}
        onClose={() => {
          setOpenArchive(false);
        }}
        onSuccess={async () => {
          await handleArchiveSuccess();
          setOpenArchive(false);
        }}
      />
    </>
  );
};

export default ConversationSettingsDialog;
