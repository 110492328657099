import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  Zoom
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { ButtonError } from 'app/components/StyledComponents';
import { UserApi } from 'common/api';
import { LanguageMap } from 'common/helpers';
import { AccountStatus } from 'common/types';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { AccountStatusLabel } from '../../../components/Label';
import Text from '../../../components/Text';
import { DeleteOrganizationDialog } from './Dialogs/DeleteOrganizationDialog';
import { LeaveOrganizationDialog } from './Dialogs/LeaveOrganizationDialog';
import PersonalDetailsDialog from './Dialogs/PersonalDetailsDialog';

function EditProfileTab() {
  const { user, organization } = useAuth();

  const [openPersonalDetails, setOpenPersonalDetails] = useState(false);
  const [openDeleteOrganization, setOpenDeleteOrganization] = useState(false);
  const [openLeaveOrganization, setOpenLeaveOrganization] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [lastEmailSentAt, setLastEmailSentAt] = useState<Date | undefined>(
    undefined
  );

  const emailLimitInSeconds = 60;
  let secondsSinceLastEmail = 999;
  if (lastEmailSentAt) {
    secondsSinceLastEmail = Math.floor(
      (new Date().getTime() - lastEmailSentAt.getTime()) / 1000
    );
  }

  const errorPopup = () => {
    enqueueSnackbar('Unexpected Error', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const sendVerificationEmail = async () => {
    if (!user?.userId) return;
    if (secondsSinceLastEmail < emailLimitInSeconds) {
      return;
    }
    setIsLoading(true);

    try {
      const response = await UserApi.resendVerificationEmail(user?.userId);
      if (response.success) {
        setLastEmailSentAt(new Date());
        enqueueSnackbar('Verification email sent', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      } else {
        errorPopup();
      }
    } catch (err: any) {
      console.error(err);
      errorPopup();
    }
    setIsLoading(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                Personal Details
              </Typography>
              <Typography variant="subtitle2">
                Manage informations related to your personal details
              </Typography>
            </Box>
            <Button
              variant="text"
              startIcon={<EditTwoToneIcon />}
              onClick={() => {
                setOpenPersonalDetails(true);
              }}
            >
              Edit
            </Button>
          </Box>
          <Divider />
          <CardContent sx={{ p: 4 }}>
            <Typography variant="subtitle2">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    Name:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">{user?.name.fullName}</Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2} mt="4px">
                    Email:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">{user?.email}</Text>
                  <Box pl={1} component="span">
                    <AccountStatusLabel
                      status={user?.status ?? AccountStatus.UNVERIFIED}
                    />
                  </Box>
                  {user?.status === AccountStatus.UNVERIFIED && (
                    <Button
                      sx={{ ml: 2 }}
                      variant="contained"
                      size="small"
                      onClick={sendVerificationEmail}
                      disabled={
                        isLoading || secondsSinceLastEmail < emailLimitInSeconds
                      }
                      startIcon={
                        isLoading ? <CircularProgress size="1rem" /> : null
                      }
                    >
                      Resend Email
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    Language:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">{LanguageMap[user?.language ?? '']}</Text>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
        <PersonalDetailsDialog
          open={openPersonalDetails}
          setOpen={setOpenPersonalDetails}
        />
      </Grid>

      {organization !== undefined && (
        <Grid item xs={12}>
          <Card>
            <Box
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h4" gutterBottom>
                  Organization Details
                </Typography>
                <Typography variant="subtitle2">
                  Manage informations related to your organization details
                </Typography>
              </Box>
              {organization !== undefined &&
              organization?.ownerId === user?.userId ? (
                <ButtonError
                  sx={{
                    ml: 1
                  }}
                  size="small"
                  startIcon={<DeleteIcon />}
                  variant="contained"
                  onClick={() => setOpenDeleteOrganization(true)}
                >
                  Delete Organization
                </ButtonError>
              ) : (
                <ButtonError
                  sx={{
                    ml: 1
                  }}
                  size="small"
                  startIcon={<ExitToAppIcon />}
                  variant="contained"
                  onClick={() => setOpenLeaveOrganization(true)}
                >
                  Leave Organization
                </ButtonError>
              )}
            </Box>
            <Divider />
            <CardContent sx={{ p: 4 }}>
              <Typography variant="subtitle2">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      Name:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">{organization?.orgName ?? ''}</Text>
                  </Grid>
                </Grid>
              </Typography>
            </CardContent>
          </Card>

          <DeleteOrganizationDialog
            open={openDeleteOrganization}
            setOpen={setOpenDeleteOrganization}
          />
          <LeaveOrganizationDialog
            open={openLeaveOrganization}
            setOpen={setOpenLeaveOrganization}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default EditProfileTab;
