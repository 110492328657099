import CloseIcon from '@mui/icons-material/Close';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Zoom
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Label } from 'app/components/Label';
import LogoLoading from 'app/components/LogoLoading';
import MultiSelectUserGroups from 'app/components/MultiSelectUserGroups';
import {
  OrganizationApi,
  OrganizationMember
} from 'common/api/organization-service-api';
import { Organization, OrgRole, Undefinable } from 'common/types';
import { format } from 'date-fns';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import {
  ChangeEvent,
  FC,
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { UpdateType } from './lib';
import { orgRoles } from './lib/contants';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const getUserRoleLabel = (userRole: OrgRole): JSX.Element => {
  const map = {
    owner: {
      text: 'Owner',
      color: 'success'
    },
    admin: {
      text: 'Administrator',
      color: 'error'
    },
    user: {
      text: 'User',
      color: 'info'
    }
  };

  const { text, color }: any = map[userRole];

  return <Label color={color}>{text}</Label>;
};

const applyPagination = (
  users: OrganizationMember[],
  page: number,
  limit: number
): OrganizationMember[] => {
  return users.slice(page * limit, page * limit + limit);
};

interface UserTableProps {
  users: OrganizationMember[];
  isUsersLoading: boolean;
  organization: Organization | undefined;
  updateUsers: (type: UpdateType, items: OrganizationMember[]) => void;
  onSelectChange: (selectedUsers: OrganizationMember[]) => void;
}

export const UserTable: FC<UserTableProps> = ({
  users,
  isUsersLoading,
  updateUsers,
  onSelectChange
}) => {
  const [selectedUsers, setSelectedUsers] = useState<OrganizationMember[]>([]);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const paginatedUsers = applyPagination(users, page, limit);
  const selectedSomeUsers =
    selectedUsers.length > 0 && selectedUsers.length < users.length;
  const selectedAllUsers = selectedUsers.length === users.length;

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [interactedUser, setInterectedUser] =
    useState<Undefinable<OrganizationMember>>(undefined);

  const handleSelectAllUsers = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedUsers(event.target.checked ? users : []);
  };

  useEffect(() => {
    onSelectChange(selectedUsers);
  }, [selectedUsers]);

  const handleSelectOneUser = (
    _event: ChangeEvent<HTMLInputElement>,
    user: OrganizationMember
  ): void => {
    if (!selectedUsers.find(u => u.userId === user.userId)) {
      setSelectedUsers(prevSelected => [...prevSelected, user]);
    } else {
      setSelectedUsers(prevSelected =>
        prevSelected.filter(prevUser => prevUser.userId !== user.userId)
      );
    }
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleConfirmDelete = (user: OrganizationMember) => {
    setInterectedUser(user);
    setOpenConfirmDelete(true);
  };

  const handleEditUser = (user: OrganizationMember) => {
    setInterectedUser(user);
    setOpenEditUser(true);
  };

  const handleUpdateCompleted = async () => {
    resetFormState();
    enqueueSnackbar(t('The user has been updated'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const resetFormState = () => {
    setIsLoading(false);
    setInterectedUser(undefined);
    setOpenConfirmDelete(false);
    setOpenEditUser(false);
  };

  const handleDeleteCompleted = async () => {
    if (!interactedUser || !user?.orgId) return;
    setIsLoading(true);
    const response = await OrganizationApi.deleteMembers(user.orgId, [
      interactedUser.userId
    ]);
    if (response.success) {
      updateUsers('delete', [interactedUser]);
      resetFormState();
      enqueueSnackbar(t('The user has been removed'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    } else {
      console.error(response.errorMessage);
    }
  };

  return (
    <>
      {paginatedUsers.length === 0 ? (
        <>
          <Typography
            sx={{
              py: 10
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {isUsersLoading ? (
              <Box display="flex" justifyContent="center">
                <LogoLoading size="l" />
              </Box>
            ) : (
              t('No users found')
            )}
          </Typography>
        </>
      ) : (
        <>
          <TableContainer
            sx={{
              minHeight: `350px`,
              maxHeight: `350px`,
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAllUsers}
                      indeterminate={selectedSomeUsers}
                      onChange={handleSelectAllUsers}
                    />
                  </TableCell>
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell>{t('Email')}</TableCell>
                  <TableCell>{t('Role')}</TableCell>
                  <TableCell>{t('Groups')}</TableCell>
                  <TableCell>{t('Created On')}</TableCell>
                  <TableCell align="center">{t('Actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map(tableUser => {
                  const isUserSelected = selectedUsers.find(
                    u => u.userId === tableUser.userId
                  )
                    ? true
                    : false;
                  return (
                    <TableRow
                      hover
                      key={tableUser.userId}
                      selected={isUserSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isUserSelected}
                          onChange={event =>
                            handleSelectOneUser(event, tableUser)
                          }
                          value={isUserSelected}
                        />
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Avatar
                            sx={{
                              mr: 1
                            }}
                            src={'' /* TODO: Avatars */}
                          />
                          <Box>{tableUser.name.fullName}</Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography>{tableUser.email}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {getUserRoleLabel(tableUser.role)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {tableUser.groups?.length
                          ? tableUser.groups.map(g => g.name).join(', ')
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {format(
                          new Date(tableUser.createdAt),
                          'dd MMMM yyyy h:mm a'
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap>
                          <Tooltip title={t('Edit')} arrow>
                            <IconButton
                              onClick={() => {
                                handleEditUser(tableUser);
                              }}
                              color="primary"
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('Delete')} arrow>
                            <span>
                              <IconButton
                                onClick={() => {
                                  handleConfirmDelete(tableUser);
                                }}
                                color="primary"
                                disabled={tableUser.userId === user?.userId}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            p={'0px 20px'}
            sx={{ borderTop: '1px solid', borderColor: '#afafaf' }}
          >
            <TablePagination
              component="div"
              count={users.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[10, 25, 50]}
            />
          </Box>
        </>
      )}
      <Dialog
        fullWidth
        maxWidth="md"
        open={openEditUser}
        onClose={resetFormState}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('Update user')}
          </Typography>
          <Typography variant="subtitle2">
            {t('Fill in the fields below to update a user')}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={{
            userId: interactedUser?.userId ?? null,
            permissionGroups: interactedUser?.groups ?? [],
            role: interactedUser?.role ?? OrgRole.USER,
            submit: null
          }}
          validationSchema={Yup.object().shape({
            role: Yup.string().required(t('The role field is required'))
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              setSubmitting(true);
              if (!user?.orgId || !_values.userId || !interactedUser) return;

              const response = await OrganizationApi.updateMembers(
                user.orgId,
                [_values.userId],
                {
                  role: _values.role,
                  groupIds: _values.permissionGroups.map(g => g.groupId)
                }
              );
              if (response.success && response.data) {
                const updatedUser = {
                  ...interactedUser,
                  role: _values.role,
                  groups: _values.permissionGroups
                };
                updateUsers('update', [updatedUser]);
                setStatus({ success: true });
                setSubmitting(false);
                handleUpdateCompleted();
                resetForm();
              } else {
                setStatus({ success: false });
                setErrors({ submit: response.errorMessage });
                setSubmitting(false);
              }
            } catch (err: any) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              {Boolean(touched.submit && errors.submit) && (
                <Box textAlign="center">
                  <Alert severity="error">{errors.submit}</Alert>
                </Box>
              )}
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} lg={7}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MultiSelectUserGroups
                          value={values.permissionGroups ?? []}
                          onChange={handleChange}
                          name="permissionGroups"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputLabel variant="standard">Role</InputLabel>
                        <Select
                          error={Boolean(touched.role && errors.role)}
                          fullWidth
                          name="role"
                          label={t('Role')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          value={values.role}
                        >
                          {orgRoles.map(r => (
                            <MenuItem key={r.value} value={r.value}>
                              {r.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={resetFormState}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {t('Update user')}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>

      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={resetFormState}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 6
            }}
            variant="h3"
          >
            {t(
              'Are you sure you want to remove this user from the organization?'
            )}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={resetFormState}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
              startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
              disabled={isLoading}
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};
