import { Box, Card, Divider, Grid, Typography, useTheme } from '@mui/material';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts';

import { UsageStats } from 'common/types';
import useAuth from 'hooks/useAuth';

interface StorageStatsProps {
  usageStats: UsageStats;
}
function StorageStats(props: StorageStatsProps) {
  const theme = useTheme();
  const { organization } = useAuth();

  const totalSizeUsed = props.usageStats?.totalDocumentsSize ?? 0;
  const storageLimit = organization?.limits.documentSizeLimit ?? totalSizeUsed;
  let freeSpace = storageLimit ? storageLimit - totalSizeUsed : undefined;

  const chartSeries: {
    name: string;
    value: number;
  }[] = [];

  if (freeSpace && storageLimit && freeSpace < 0) {
    freeSpace = 0;
  }

  if (freeSpace && storageLimit && freeSpace >= 0) {
    chartSeries.push({
      name: 'Free Space',
      value: (freeSpace / storageLimit) * 100
    });
  }

  //Direct Upload
  if (props.usageStats.totalDirectUploadDocumentsSize) {
    chartSeries.push({
      name: 'Direct Upload',
      value:
        (props.usageStats.totalDirectUploadDocumentsSize / storageLimit) * 100
    });
  }

  //Notion
  if (props.usageStats.totalNotionDocumentsSize) {
    chartSeries.push({
      name: 'Notion',
      value: (props.usageStats.totalNotionDocumentsSize / storageLimit) * 100
    });
  }

  //Confluence
  if (props.usageStats.totalConfluenceDocumentsSize) {
    chartSeries.push({
      name: 'Confluence',
      value:
        (props.usageStats.totalConfluenceDocumentsSize / storageLimit) * 100
    });
  }

  //Jira
  if (props.usageStats.totalJiraDocumentsSize) {
    chartSeries.push({
      name: 'Jira',
      value: (props.usageStats.totalJiraDocumentsSize / storageLimit) * 100
    });
  }

  //Salesforce
  if (props.usageStats.totalSalesforceDocumentsSize) {
    chartSeries.push({
      name: 'Salesforce',
      value:
        (props.usageStats.totalSalesforceDocumentsSize / storageLimit) * 100
    });
  }

  //Microsoft Dynamics
  if (props.usageStats.totalDynamics365DocumentsSize) {
    chartSeries.push({
      name: 'Microsoft Dynamics',
      value:
        (props.usageStats.totalDynamics365DocumentsSize / storageLimit) * 100
    });
  }

  //Sharepoint
  if (props.usageStats.totalSharepointDocumentsSize) {
    chartSeries.push({
      name: 'Sharepoint',
      value:
        (props.usageStats.totalSharepointDocumentsSize / storageLimit) * 100
    });
  }

  const COLORS = [
    theme.palette.primary.main,
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.info.main,
    theme.palette.error.main,
    theme.palette.primary.light,
    theme.palette.success.light,
    theme.palette.warning.light,
    theme.palette.info.light,
    theme.palette.error.light
  ];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <Card>
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography gutterBottom variant="h4">
            Documents
          </Typography>
          <Typography variant="subtitle2">
            View the current document storage usage
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4">
            {getStorageUsedLabel(totalSizeUsed, storageLimit)}
          </Typography>
          <Typography variant="subtitle2">
            {!freeSpace ? `Total Used` : `Used/Limit`}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Grid py={1} container spacing={3}>
        <Grid
          md={12}
          item
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={350}
        >
          <ResponsiveContainer width="100%" height="100%">
            <PieChart
              width={400}
              height={400}
              margin={{ top: 0, right: 20, left: 0, bottom: 0 }}
            >
              <Pie
                data={chartSeries}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={120}
                dataKey="value"
                cx={'50%'}
                cy={'50%'}
              >
                {chartSeries.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                formatter={(value, name, props) => {
                  const fileSize = props.payload.value * storageLimit;
                  let label = props.payload.name;
                  if (fileSize < 1000 * 1000000) {
                    label = `${convertBytesToMB(fileSize).toFixed(2)} MB`;
                  } else {
                    label = `${convertBytesToGB(fileSize).toFixed(2)} GB`;
                  }
                  return `${label}`;
                }}
              />
              <Legend
                iconType="circle"
                verticalAlign="middle"
                layout="vertical"
                align="right"
                formatter={(value, entry, index) => {
                  const fileSize = chartSeries[index].value * storageLimit;
                  let label = chartSeries[index].name;
                  if (fileSize < 1000 * 1000000) {
                    label = `${convertBytesToMB(fileSize).toFixed(2)} MB`;
                  } else {
                    label = `${convertBytesToGB(fileSize).toFixed(2)} GB`;
                  }
                  return `${label} - ${value}`;
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Card>
  );
}

const convertBytesToMB = (bytes: number) => {
  return bytes / 1000 / 1000;
};

const convertBytesToGB = (bytes: number) => {
  return bytes / 1000 / 1000 / 1000;
};

//if used is less than 1GB, show MB, otherwise show GB
const getStorageUsedLabel = (used: number, limit?: number) => {
  if (limit) {
    if (limit < 1000 * 1000000) {
      return `${convertBytesToMB(used).toFixed(2)}/${convertBytesToMB(
        limit
      ).toFixed(2)} MB`;
    } else {
      return `${convertBytesToGB(used).toFixed(2)}/${convertBytesToGB(
        limit
      ).toFixed(2)} GB`;
    }
  } else {
    if (used < 1000 * 1000000) {
      return `${convertBytesToMB(used).toFixed(2)} MB`;
    } else {
      return `${convertBytesToGB(used).toFixed(2)} GB`;
    }
  }
};

export default StorageStats;
