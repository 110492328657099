import { UserApi } from 'common/api';
import { OrganizationApi } from 'common/api/organization-service-api';
import { PagesApi } from 'common/api/pages-service';
import {
  BasicResponse,
  FrontendRoutes,
  FrontendUser,
  FullName,
  Organization
} from 'common/types';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from '../utils/firebase';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: FrontendUser | undefined;
  organization: Organization | undefined;
}

interface AuthContextValue extends AuthState {
  login: (email: string, password: string) => Promise<BasicResponse>;
  logout: () => void;
  refreshUser: () => void;
  refreshData: () => void;
  refreshOrganization: () => void;
  register: (
    email: string,
    name: FullName,
    password?: string,
    orgId?: string,
    orgInviteId?: string,
    firebaseId?: string
  ) => Promise<BasicResponse<FrontendUser>>;
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: undefined,
  organization: undefined
};

const invalidResponse: BasicResponse<FrontendUser> = {
  success: false
};

export const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(invalidResponse),
  logout: () => Promise.resolve(),
  refreshUser: () => Promise.resolve(),
  refreshData: () => Promise.resolve(),
  refreshOrganization: () => Promise.resolve(),
  register: () => Promise.resolve(invalidResponse)
});

interface AuthProviderProps {
  children: React.ReactNode;
}
export const AuthProvider = (props: AuthProviderProps) => {
  const navigate = useNavigate();
  const [state, setState] = useState<AuthState>(initialAuthState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async user => {
        setState({
          ...state,
          isInitialized: false
        });
        if (user) {
          await refreshData();
          return;
        }
        setState({
          ...state,
          isInitialized: true,
          isAuthenticated: false,
          organization: undefined,
          user: undefined
        });
      }),
    []
  );

  const refreshData = async () => {
    const response = await PagesApi.getInitialData();
    if (response.success && response.data) {
      setState({
        ...state,
        isInitialized: true,
        isAuthenticated: true,
        user: response.data.user,
        organization: response.data.organization
      });
    }
  };

  const refreshUser = async () => {
    if (!state.user) return;
    const userResponse = await UserApi.getUser(state.user.userId);
    if (userResponse.success && userResponse.data) {
      setState({
        ...state,
        isAuthenticated: true,
        user: userResponse.data
      });
      return;
    }

    setState({
      ...state,
      isInitialized: false,
      isAuthenticated: false,
      user: undefined
    });
  };

  const refreshOrganization = async () => {
    if (state.user?.orgId === undefined) return;
    const orgResponse = await OrganizationApi.get(state.user.orgId);
    if (!orgResponse.success || !orgResponse.data) {
      console.error('Failed to fetch organization');
      return;
    }

    setState({
      ...state,
      organization: orgResponse.data
    });
  };

  const login = async (
    email: string,
    password: string
  ): Promise<BasicResponse> => {
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      console.error(error);
      if (error.code === 'auth/user-not-found') {
        return {
          success: false,
          errorMessage: 'Invalid email address'
        };
      } else if (error.code === 'auth/wrong-password') {
        return {
          success: false,
          errorMessage: 'Invalid password'
        };
      } else {
        return {
          success: false,
          errorMessage: 'An unknown error occured'
        };
      }
    }

    return {
      success: true
    };
  };

  const logout = async (): Promise<void> => {
    await firebase.auth().signOut();
    setState({
      ...state,
      isAuthenticated: false,
      user: undefined,
      organization: undefined
    });
    navigate(FrontendRoutes.HOME);
  };

  const register = async (
    email: string,
    name: FullName,
    password?: string,
    orgId?: string,
    orgInviteId?: string,
    firebaseId?: string
  ): Promise<BasicResponse<FrontendUser>> => {
    const response = await UserApi.registerUser(
      email,
      name,
      password,
      orgId,
      orgInviteId,
      firebaseId
    );

    if (response.success && response.data) {
      const auth = getAuth();
      if (!firebaseId && password) {
        await signInWithEmailAndPassword(auth, email, password);
      } else {
        await refreshData();
      }
    }

    return response;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        refreshData: refreshData,
        refreshUser: refreshUser,
        refreshOrganization: refreshOrganization
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
