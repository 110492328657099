import AttachFileIcon from '@mui/icons-material/AttachFile';
import MicIcon from '@mui/icons-material/Mic';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import LoadingButton from '@mui/lab/LoadingButton';
import SpeechRecognition, {
  useSpeechRecognition
} from 'react-speech-recognition';

import {
  Box,
  CircularProgress,
  IconButton,
  TextareaAutosize,
  styled,
  useTheme
} from '@mui/material';
import { ButtonError } from 'app/components/StyledComponents';
import { ConversationApi } from 'common/api';
import {
  Conversation,
  ConversationStatus,
  MessageRole,
  MessageStatus
} from 'common/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MessageInputWrapper = styled(TextareaAutosize)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(16)};
    font-family: ${theme.typography.fontFamily};
    padding: ${theme.spacing(1)};
    width: 100%;
`
);
interface ChatBarProps {
  isLoading: boolean;
  selectedConversation: Conversation | undefined;
  setSelectedConversation: (conversation: Conversation | undefined) => void;
  conversations: Conversation[];
  setConversations: (conversations: Conversation[]) => void;
}

export const ChatBar = (props: ChatBarProps) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const isMobile = window.innerWidth < theme.breakpoints.values.sm;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentInput, setCurrentInput] = useState<string | undefined>();
  const [isCanceling, setIsCanceling] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  const updateConversions = (conversation: Conversation) => {
    let conversationsCopy = [...props.conversations];
    const indexOfConversation = conversationsCopy.findIndex(
      c => c.conversationId === conversation.conversationId
    );

    if (indexOfConversation !== -1) {
      conversationsCopy[indexOfConversation] = conversation;
    } else {
      conversationsCopy.unshift(conversation);
      props.setSelectedConversation(conversation);
    }

    if (
      props.selectedConversation?.conversationId === conversation.conversationId
    ) {
      props.setSelectedConversation(conversation);
    }

    props.setConversations(conversationsCopy);
  };

  const handleSendMessage = async () => {
    if (!currentInput || currentInput.trim() === '') return;
    setCurrentInput(undefined);
    setIsLoading(true);

    const convo = { ...props.selectedConversation } as Conversation;
    if (!convo) return;
    if (!convo.messages || convo.messages.length === 0) {
      convo.messages = [];
    }
    convo.messages.push({
      id: '',
      content: currentInput,
      role: MessageRole.USER,
      status: MessageStatus.COMPLETED,
      timestamp: new Date().toISOString()
    });
    convo.messages.push({
      id: '',
      content: '',
      role: MessageRole.ASSISTANT,
      status: MessageStatus.PROCESSING,
      timestamp: new Date().toISOString()
    });

    updateConversions(convo);
    await ConversationApi.sendMessage(
      currentInput,
      props.selectedConversation?.conversationId
    );
    setIsLoading(false);
  };

  const handleCancelMessage = async () => {
    setIsCanceling(true);

    if (!props.selectedConversation) return;
    const lastAssistantMessage = props.selectedConversation.messages.find(
      m =>
        m.role === MessageRole.ASSISTANT &&
        m.status === MessageStatus.PROCESSING
    );
    if (!lastAssistantMessage || lastAssistantMessage.id === '') return;
    const response = await ConversationApi.cancelMessage(
      props.selectedConversation.conversationId,
      lastAssistantMessage.id
    );
    if (!response.success || !response.data) return;
    const updatedConvo = { ...response.data };

    updateConversions(updatedConvo);
    setIsCanceling(false);
    setIsLoading(false);
  };

  useEffect(() => {
    const convo = { ...props.selectedConversation };
    if (!convo || !convo.messages || convo.messages.length === 0) return;
    const messages = convo.messages.filter(
      m => m.role === 'assistant' || m.role === 'user'
    );
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.status === MessageStatus.PROCESSING) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [props.selectedConversation?.messages]);

  useEffect(() => {
    if (transcript && transcript !== '') {
      setCurrentInput(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    if (
      !props.selectedConversation ||
      props.selectedConversation.status !== ConversationStatus.PROCESSING
    ) {
      setIsLoading(false);
    } else if (
      props.selectedConversation.status === ConversationStatus.PROCESSING
    ) {
      setIsLoading(true);
    }
  }, []);

  return (
    <Box
      sx={{
        background: theme.colors.alpha.white[100],
        borderTop: `1px solid ${theme.colors.alpha.black[10]}`,
        display: 'flex',
        alignItems: 'center',
        px: { md: 3, sm: 2, xs: 1 },
        py: { md: 3, sm: 1 }
      }}
    >
      <Box flexGrow={1} display="flex" alignItems="center">
        <MessageInputWrapper
          autoFocus
          placeholder={t('Ask AVA Anything...')}
          onChange={event => {
            setCurrentInput(event.target.value);
          }}
          onKeyDown={event => {
            if (event.key === 'Enter' && !event.shiftKey) {
              handleSendMessage();
            }
          }}
          value={currentInput ?? ''}
          disabled={
            isLoading || props.selectedConversation?.status === 'processing'
          }
          sx={{
            border: '1px solid #858585',
            borderRadius: '4px',
            marginRight: { sm: '10px', xs: '5px' },
            padding: '10px 15px',
            maxHeight: '200px',
            minHeight: '20px',
            '&:focus': {
              outline: 'none',
              border: '1.5px solid #0C7DBC'
            }
          }}
        />
      </Box>
      <Box>
        {isLoading && !props.isLoading ? (
          <ButtonError
            variant="contained"
            disabled={
              isCanceling ||
              (props.selectedConversation &&
                props.selectedConversation.messages.length > 0 &&
                props.selectedConversation.messages[
                  props.selectedConversation.messages.length - 1
                ].id === '')
            }
            startIcon={isCanceling ? <CircularProgress size="1rem" /> : null}
            onClick={handleCancelMessage}
          >
            Cancel
          </ButtonError>
        ) : (
          <Box display="flex">
            {isMobile ? null : (
              <IconButton
                onClick={() => {}}
                disabled={
                  props.selectedConversation?.status === 'processing' ||
                  isLoading ||
                  props.isLoading
                }
                sx={{
                  marginRight: { sm: '10px', xs: '5px' }
                }}
              >
                <AttachFileIcon />
              </IconButton>
            )}

            <IconButton
              onClick={() => {
                setIsRecording(!isRecording);
                if (!isRecording) {
                  SpeechRecognition.startListening({ continuous: true });
                } else {
                  SpeechRecognition.stopListening();
                  resetTranscript();
                }
              }}
              disabled={
                props.selectedConversation?.status === 'processing' ||
                isLoading ||
                !browserSupportsSpeechRecognition ||
                props.isLoading
              }
              sx={{
                marginRight: { sm: '10px', xs: '5px' }
              }}
            >
              {!isRecording ? <MicIcon /> : <RecordVoiceOverIcon />}
            </IconButton>
            <LoadingButton
              onClick={handleSendMessage}
              loading={isLoading}
              disabled={
                props.selectedConversation?.status === 'processing' ||
                props.isLoading
              }
              startIcon={<SendTwoToneIcon />}
              variant="contained"
            >
              {isMobile ? null : t('Send')}
            </LoadingButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
