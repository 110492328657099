import {
  Avatar,
  Box,
  Card,
  Grid,
  Slide,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';

import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import SendIcon from '@mui/icons-material/Send';
import LogoLoading from 'app/components/LogoLoading';
import Scrollbar from 'app/components/Scrollbar';
import { ConversationApi } from 'common/api';
import { Conversation, MessageRole, MessageStatus } from 'common/types';
import { formatDistance } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { AvaMessage } from './Message/AvaMessage';

const CardWrapperPrimary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-right-radius: ${theme.general.borderRadius};
      display: inline-flex;
`
);

interface ChatContentProps {
  isLoading: boolean;
  selectedConversation: Conversation | undefined;
  setSelectedConversation: (conversation: Conversation | undefined) => void;
  conversations: Conversation[];
  setConversations: (conversations: Conversation[]) => void;
}

export const ChatContent = (props: ChatContentProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = window.innerWidth < theme.breakpoints.values.md;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end'
    });
  };

  const updateConversions = (conversation: Conversation) => {
    let conversationsCopy = [...props.conversations];
    const indexOfConversation = conversationsCopy.findIndex(
      c => c.conversationId === conversation.conversationId
    );

    if (indexOfConversation !== -1) {
      conversationsCopy[indexOfConversation] = conversation;
    } else {
      conversationsCopy.unshift(conversation);
      props.setSelectedConversation(conversation);
    }

    if (
      props.selectedConversation?.conversationId === conversation.conversationId
    ) {
      props.setSelectedConversation(conversation);
    }

    props.setConversations(conversationsCopy);
  };

  const handlePromptClick = async (prompt: string) => {
    if (isLoading) return;
    setIsLoading(true);
    await ConversationApi.sendMessage(
      prompt,
      props.selectedConversation?.conversationId
    );

    const convo = { ...props.selectedConversation } as Conversation;
    if (!convo) return;
    if (!convo.messages || convo.messages.length === 0) {
      convo.messages = [];
    }
    convo.messages.push({
      id: '',
      content: prompt,
      role: MessageRole.USER,
      status: MessageStatus.COMPLETED,
      timestamp: new Date().toISOString()
    });
    convo.messages.push({
      id: '',
      content: '',
      role: MessageRole.ASSISTANT,
      status: MessageStatus.PROCESSING,
      timestamp: new Date().toISOString()
    });

    updateConversions(convo);
    setIsLoading(false);
  };

  useEffect(() => {
    scrollToBottom();
  }, [props.selectedConversation?.messages.length]);

  const buildMessageChain = () => {
    if (!props.selectedConversation) return;

    let messageChain: JSX.Element[] = [];
    let messages = props.selectedConversation.messages.filter(
      m => m.role === 'assistant' || m.role === 'user'
    );
    messages.forEach((message, index) => {
      let content = (
        <Box
          key={index}
          display="flex"
          alignItems="flex-start"
          justifyContent={'flex-start'}
          py={3}
        >
          {!isMobile && message.role === 'assistant' && (
            <Avatar
              variant="rounded"
              sx={{
                width: 50,
                height: 50
              }}
              alt="Ava"
              src="/static/images/avatars/1.jpg"
            />
          )}
          {!isMobile && message.role === 'user' && (
            <Avatar
              variant="rounded"
              sx={{
                width: 50,
                height: 50
              }}
            />
          )}
          <Box
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            justifyContent="center"
            sx={{ flexGrow: 1 }}
            mx={{ sm: 1, md: 2 }}
          >
            {message.role === 'assistant' && props.selectedConversation && (
              <AvaMessage
                message={message}
                selectedConversation={props.selectedConversation}
                setSelectedConversation={props.setSelectedConversation}
                conversations={props.conversations}
                setConversations={props.setConversations}
              />
            )}
            {message.role === 'user' && (
              <CardWrapperPrimary>
                {DisplayMultilineText({ text: message.content })}
              </CardWrapperPrimary>
            )}
            <Typography
              variant="subtitle1"
              sx={{
                pt: 1,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <ScheduleTwoToneIcon
                sx={{
                  mr: 0.5
                }}
                fontSize="small"
              />
              {formatDistance(new Date(message.timestamp), new Date(), {
                addSuffix: true
              })}
            </Typography>
          </Box>
        </Box>
      );
      if (index === messages.length - 1) {
        content = (
          <Slide
            in={true}
            direction="up"
            timeout={300}
            mountOnEnter
            unmountOnExit
            key={index}
          >
            {content}
          </Slide>
        );
      }
      messageChain.push(content);
    });

    return messageChain;
  };

  return (
    <>
      <Box id="chat-window" sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <Scrollbar>
          <Box p={3} height="100%">
            {props.isLoading && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <LogoLoading size="xl" />{' '}
              </Box>
            )}
            {!props.isLoading && props.selectedConversation && (
              <Box>
                {buildMessageChain()}
                <div style={{ width: 'auto' }} ref={messagesEndRef}></div>
              </Box>
            )}
            {!props.isLoading && !props.selectedConversation && (
              <ExamplePrompts onClick={handlePromptClick} />
            )}
          </Box>
        </Scrollbar>
      </Box>
    </>
  );
};

const Examples = [
  {
    title: 'Brainstorm ideas',
    prompt: 'What are some ideas for a new product?'
  },
  {
    title: 'Example 2',
    prompt: 'Prompt 2'
  },
  {
    title: 'Example 3',
    prompt: 'Prompt 3'
  },
  {
    title: 'Example 4',
    prompt: 'Prompt 4'
  }
];

interface ExamplePromptProps {
  onClick: (prompt: string) => void;
}

const ExamplePrompts = (props: ExamplePromptProps) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Box width="70%">
        <Typography
          variant="h6"
          color={theme.palette.secondary.light}
          sx={{ padding: 2 }}
        >
          AVA is here to assist, learn, and engage in conversations with you on
          a wide range of topics. Here are some examples:
        </Typography>
        <Grid container spacing={3}>
          {Examples.map((example, index) => (
            <Grid item xs={12} md={6} key={index} sx={{ maxHeight: '200px' }}>
              <Card
                sx={{
                  background: theme.colors.alpha.white[100],
                  p: 2,
                  height: '100%',
                  '&:hover': {
                    background: alpha(theme.colors.alpha.white[100], 0.2)
                  },
                  '&:active': {
                    background: alpha(theme.colors.alpha.white[100], 0.6)
                  },
                  cursor: 'pointer'
                }}
                onClick={() => props.onClick(example.prompt)}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box sx={{ marginRight: 'auto' }}>
                    <Typography variant="h6">{example.title}</Typography>
                    <Typography variant="body1">{example.prompt}</Typography>
                  </Box>
                  <Box>
                    <SendIcon />
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

function DisplayMultilineText({ text }) {
  return (
    <div>
      {text.split('\n').map((str, index, array) => (
        <React.Fragment key={index}>
          {str}
          {/* Add a break element for every line except for the last one */}
          {index === array.length - 1 ? null : <br />}
        </React.Fragment>
      ))}
    </div>
  );
}
