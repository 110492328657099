import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { JobTypeMap } from 'common/helpers';
import { JobType } from 'common/types';
import { useState } from 'react';
import { IntegrationOption } from '../../lib';

interface IntegrationSettingsTabProps {
  integration: IntegrationOption;
}
export const IntegrationSettingsTab = (props: IntegrationSettingsTabProps) => {
  const [open, setOpen] = useState(false);
  const settings = props.integration.details?.settings;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography>
            <strong>Username:</strong> ******
          </Typography>
          <Typography>
            <strong>Api Token:</strong> ******
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>
            <strong>Assigned Groups:</strong>{' '}
            {settings && settings.defaultPermissions.length > 0
              ? settings.defaultPermissions.map(group => group.key).join(', ')
              : 'None'}
          </Typography>
          <Typography>
            <strong>Sync Type:</strong> {JobTypeMap[settings?.syncType ?? '']}
          </Typography>
          {settings?.syncType === JobType.REOCCURING && (
            <Typography>
              <strong>Sync Interval</strong>{' '}
              {`${settings?.syncFrequencyInHours} hours}`}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Edit
        </Button>
      </Box>
      <SettingsEditDialog open={open} onClose={handleClose} />
    </Box>
  );
};

interface SettingsEditDialogProps {
  open: boolean;
  onClose: () => void;
}

const SettingsEditDialog = (props: SettingsEditDialogProps) => {
  const { open, onClose } = props;

  const handleSave = () => {
    // Save your changes here
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Settings</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Setting 1"
              defaultValue="Value 1"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Setting 2"
              defaultValue="Value 2"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Setting 3"
              defaultValue="Value 3"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Setting 4"
              defaultValue="Value 4"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
