import { Integration, IntegrationType } from 'common/types';
import { ReactNode } from 'react';

export interface IntegrationOption {
  id: IntegrationType;
  name: string;
  logo: ReactNode;
  status?: IntegrationStatus;
  details?: Integration;
  wizardDetails: {
    connectionStep: {
      description?: ReactNode;
      fields: IntegrationField[];
    };
    scopeStep: {
      description?: ReactNode;
      fields: IntegrationField[];
    };
  };
}

export interface IntegrationField {
  label: string;
  type: FieldType;
  key: string;
}

export enum FieldType {
  TEXT = 'text',
  PASSWORD = 'password',
  SELECT = 'select',
  NUMBER = 'number',
  CHECKBOX = 'checkbox'
}

export enum IntegrationStatus {
  ACTIVE = 'Active',
  DISABLED = 'Disabled',
  INACTIVE = 'Inactive',
  ERROR = 'Error'
}

export const IntegrationStatusMap = {
  [IntegrationStatus.ACTIVE]: 'Active',
  [IntegrationStatus.DISABLED]: 'Disabled',
  [IntegrationStatus.INACTIVE]: 'Inactive',
  [IntegrationStatus.ERROR]: 'Error'
};
