import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, CircularProgress } from '@mui/material';
import { IntegrationApi, getOAuthInitiate } from 'common/api';
import { IntegrationType } from 'common/types';
import { useState } from 'react';
import { IntegrationWizardFormData } from '../IntegrationWizard';

interface OAuthButtonProps {
  type: IntegrationType;
  formData: IntegrationWizardFormData;
  onSuccess: () => void;
  onFailure: (error: string) => void;
}
export const OAuthButton = (props: OAuthButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const initiateOAuth = async () => {
    if (!props.formData.scope) {
      props.onFailure('No scope selected');
      return;
    }
    if (!props.formData.settings) {
      props.onFailure('No settings selected');
      return;
    }

    let additionalInfo: Record<string, string> = {};
    if (props.type === IntegrationType.JIRA) {
      if (props.formData.connection?.jira?.domainUrl) {
        additionalInfo.domainUrl = props.formData.connection?.jira?.domainUrl;
      } else {
        props.onFailure('Please enter a URL');
        return;
      }
    } else if (props.type === IntegrationType.CONFLUENCE) {
      if (props.formData.connection?.confluence?.domainUrl) {
        additionalInfo.domainUrl =
          props.formData.connection?.confluence?.domainUrl;
      } else {
        props.onFailure('Please enter a URL');
        return;
      }
    } else if (props.type === IntegrationType.SHAREPOINT) {
      if (props.formData.connection?.sharepoint?.tenantId) {
        additionalInfo.tenantId =
          props.formData.connection?.sharepoint?.tenantId;
      } else {
        props.onFailure('Please enter a Tenant Id');
        return;
      }
    } else if (props.type === IntegrationType.MICROSOFT_DYNAMICS_365) {
      if (props.formData.connection?.dynamics365?.instanceUrl) {
        additionalInfo.instanceUrl =
          props.formData.connection?.dynamics365?.instanceUrl;
      } else {
        props.onFailure('Please enter a Instance Url');
        return;
      }
    }

    setIsLoading(true);
    const response = await IntegrationApi.saveWizardForm({
      scope: props.formData.scope,
      type: props.type,
      startDateTime: props.formData.settings.startDateTime,
      syncFrequency: props.formData.settings.syncFrequencyInHours,
      syncType: props.formData.settings.syncType,
      defaultPermissions: props.formData.settings.defaultGroupIds.map(g => ({
        key: g,
        effect: 'allow'
      })),
      additionalInformation: additionalInfo
    });
    setIsLoading(false);
    if (!response || !response.data) {
      props.onFailure('Error saving state');
      return;
    }
    const oauthInit = getOAuthInitiate(props.type, response.data.id);
    const oauthWindow = window.open(
      oauthInit,
      'OAuth Connector',
      'width=600,height=600'
    );
    if (!oauthWindow) return;

    // Polling method to check if OAuth process is done
    const pollOAuthWindow = setInterval(() => {
      try {
        if (oauthWindow.document.URL.includes(`/callback`)) {
          //Check for errors in query params
          const url = new URL(oauthWindow.document.URL);
          const error = url.searchParams.get('error');
          if (error) {
            props.onFailure('Error connecting');
            oauthWindow.close();
            clearInterval(pollOAuthWindow);
            return;
          }
          props.onSuccess();
          oauthWindow.close();
          clearInterval(pollOAuthWindow);
        }
      } catch (e) {
        //console.log(e);
      }
    }, 100);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={initiateOAuth}
        endIcon={<OpenInNewIcon />}
        disabled={isLoading}
        startIcon={isLoading ? <CircularProgress size="1rem" /> : undefined}
      >
        Connect
      </Button>
    </>
  );
};
