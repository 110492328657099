import { FilterList } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { OrgPermissionGroup } from 'common/types';
import React, { useState } from 'react';

export interface FileFilters {
  fileTypes: string[];
  groups: OrgPermissionGroup[];
}

type FileFilterProps = {
  fileTypes: { label: string; value: string }[];
  groups: OrgPermissionGroup[];
  onFilterChange: (filters: FileFilters) => void;
};

const FileFilter: React.FC<FileFilterProps> = (props: FileFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFileTypes, setSelectedFileTypes] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<OrgPermissionGroup[]>(
    []
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFileTypeChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedFileTypes(event.target.value as string[]);
  };

  const handleGroupChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value as string[];
    console.log('selectedIds', selectedIds);
    setSelectedGroups(
      props.groups.filter(g => selectedIds.includes(g.groupId))
    );
  };

  const applyFilters = () => {
    props.onFilterChange({
      fileTypes: selectedFileTypes,
      groups: selectedGroups
    });
    handleClose();
  };

  const resetFilters = () => {
    props.onFilterChange({
      fileTypes: [],
      groups: []
    });
    setSelectedFileTypes([]);
    setSelectedGroups([]);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <FilterList />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: { minWidth: '300px' }
        }}
      >
        <MenuItem>
          <FormControl fullWidth>
            <InputLabel>File Types</InputLabel>
            <Select
              multiple
              value={selectedFileTypes}
              onChange={handleFileTypeChange}
              renderValue={selected => {
                const names: string[] = [];
                for (let i = 0; i < selected.length; i++) {
                  const type = props.fileTypes.find(
                    g => g.value === selected[i]
                  );
                  if (!type) continue;
                  names.push(type.label);
                }
                return names.join(', ');
              }}
            >
              {props.fileTypes.map((type, index) => (
                <MenuItem key={index} value={type.value}>
                  <ListItemText primary={type.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MenuItem>
        <MenuItem>
          <FormControl fullWidth>
            <InputLabel>Groups</InputLabel>
            <Select
              multiple
              value={selectedGroups.map(g => g.groupId)}
              onChange={handleGroupChange}
              renderValue={selected => {
                const names: string[] = [];
                for (let i = 0; i < selected.length; i++) {
                  const group = props.groups.find(
                    g => g.groupId === selected[i]
                  );
                  if (!group) continue;
                  names.push(group.name);
                }
                return names.join(', ');
              }}
            >
              {props.groups.map((group, index) => (
                <MenuItem key={index} value={group.groupId}>
                  <ListItemText primary={group.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MenuItem>
        <Box
          sx={{ pl: '15px', pr: '15px', pt: '6px' }}
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <Button onClick={resetFilters} color="primary" variant="outlined">
            Reset
          </Button>
          <Button onClick={applyFilters} color="primary" variant="contained">
            Apply Filters
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default FileFilter;
