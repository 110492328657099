import {
  Attachment,
  BasicResponse,
  Conversation,
  ConversationStatus,
  HttpMethods
} from 'common/types';
import { BulkResponse, FetchProps, FetchUrl } from './api-client';

export class ConversationApi {
  public static async getUserConversations(
    amount?: number,
    startCursor?: string
  ): Promise<BasicResponse<BulkResponse<Conversation>>> {
    const params = new URLSearchParams();
    if (amount) {
      params.append('amount', amount.toString());
    }
    if (startCursor) {
      params.append('startCursor', startCursor);
    }
    const request: FetchProps = {
      apiEndpoint: '/api/conversations',
      method: HttpMethods.GET,
      parameters: params.values.length > 0 ? params : undefined
    };

    const response = await FetchUrl<BulkResponse<Conversation>>(request);
    return response;
  }

  public static async sendMessage(
    message: string,
    conversationId?: string
  ): Promise<BasicResponse<Conversation>> {
    const request: FetchProps = {
      apiEndpoint: '/api/conversations',
      method: HttpMethods.POST,
      parameters: conversationId
        ? new URLSearchParams({ conversationId })
        : undefined,
      body: { message }
    };

    const response = await FetchUrl<Conversation>(request);
    return response;
  }

  public static async getConversation(
    conversationId: string
  ): Promise<BasicResponse<Conversation>> {
    const request: FetchProps = {
      apiEndpoint: `/api/conversations/${conversationId}`,
      method: HttpMethods.GET
    };
    const response = await FetchUrl<Conversation>(request);
    return response;
  }

  public static async updateConversation(
    conversationId: string,
    updates: {
      name?: string;
      status?: ConversationStatus;
    }
  ): Promise<BasicResponse<Conversation>> {
    const request: FetchProps = {
      apiEndpoint: `/api/conversations/${conversationId}`,
      method: HttpMethods.PUT,
      body: updates
    };
    const response = await FetchUrl<Conversation>(request);
    return response;
  }

  public static async deleteConversation(
    conversationId: string
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/conversations/${conversationId}`,
      method: HttpMethods.DELETE
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async getAttachments(
    conversationId: string
  ): Promise<BasicResponse<Attachment[]>> {
    const request: FetchProps = {
      apiEndpoint: `/api/conversations/${conversationId}/attachments`,
      method: HttpMethods.GET
    };
    const response = await FetchUrl<Attachment[]>(request);
    return response;
  }

  public static async getAttachment(
    conversationId: string,
    attachmentId: string
  ): Promise<BasicResponse<Attachment>> {
    const request: FetchProps = {
      apiEndpoint: `/api/conversations/${conversationId}/attachment`,
      method: HttpMethods.GET,
      parameters: new URLSearchParams({ attachmentId })
    };
    const response = await FetchUrl<Attachment>(request);
    return response;
  }

  public static async getAttachmentDownloadUrl(
    conversationId: string,
    attachmentId: string
  ): Promise<
    BasicResponse<{
      url: string;
      expiresOn: string;
    }>
  > {
    const request: FetchProps = {
      apiEndpoint: `/api/conversations/${conversationId}/attachment/download`,
      method: HttpMethods.POST,
      parameters: new URLSearchParams({ attachmentId })
    };

    const response = await FetchUrl<{
      url: string;
      expiresOn: string;
    }>(request);
    return response;
  }

  public static async cancelMessage(
    conversationId: string,
    messageId: string
  ): Promise<BasicResponse<Conversation>> {
    const request: FetchProps = {
      apiEndpoint: `/api/conversations/${conversationId}/message/cancel`,
      method: HttpMethods.POST,
      parameters: new URLSearchParams({ messageId })
    };
    const response = await FetchUrl<Conversation>(request);
    return response;
  }

  public static async approveMessage(
    conversationId: string,
    messageId: string,
    values: { [key: string]: string }
  ): Promise<BasicResponse<Conversation>> {
    const request: FetchProps = {
      apiEndpoint: `/api/conversations/${conversationId}/message/approve`,
      method: HttpMethods.POST,
      body: { values },
      parameters: new URLSearchParams({ messageId })
    };
    const response = await FetchUrl<Conversation>(request);
    return response;
  }

  public static async startWorkflow(params: {
    conversationId?: string;
    workflowId: string;
    values: { [key: string]: string };
  }): Promise<BasicResponse<Conversation>> {
    const request: FetchProps = {
      apiEndpoint: `/api/conversations/workflow`,
      method: HttpMethods.POST,
      body: {
        conversationId: params.conversationId,
        workflowId: params.workflowId,
        values: params.values
      }
    };
    const response = await FetchUrl<Conversation>(request);
    return response;
  }

  public static async retryMessage(
    conversationId: string,
    messageId: string
  ): Promise<BasicResponse<Conversation>> {
    const request: FetchProps = {
      apiEndpoint: `/api/conversations/${conversationId}/message/retry`,
      method: HttpMethods.POST,
      parameters: new URLSearchParams({ messageId })
    };
    const response = await FetchUrl<Conversation>(request);
    return response;
  }

  public static async updateConversations(
    status: ConversationStatus,
    conversationIds?: string[]
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: '/api/conversations',
      method: HttpMethods.PUT,
      body: { conversationIds, status: status }
    };

    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async deleteConversations(
    conversationIds?: string[]
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: '/api/conversations',
      method: HttpMethods.DELETE,
      body: { conversationIds }
    };
    const response = await FetchUrl<string>(request);
    return response;
  }
}
