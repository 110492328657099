import { CssBaseline } from '@mui/material';
import AppInit from 'app/components/AppInit';
import useAuth from 'hooks/useAuth';
import { SnackbarProvider } from 'notistack';
import { useRoutes } from 'react-router-dom';
import router from './router';
import { ThemeProviderWrapper as ThemeProvider } from './theme/ThemeProvider';

function App() {
  const content = useRoutes(router);
  const auth = useAuth();

  return (
    <ThemeProvider>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          {auth.isInitialized ? content : <AppInit />}
        </SnackbarProvider>
    </ThemeProvider>
  );
}
export default App;
