import { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  Grid,
  ListItem,
  List,
  ListItemText,
  Divider,
  Button,
  Switch,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from '@mui/material';

import { format } from 'date-fns';
import ChangePasswordDialog from './Dialogs/ChangePasswordDialog';
import useAuth from 'hooks/useAuth';

function SecurityTab() {
  const { user } = useAuth();
  const [openChangePassword, setOpenChangePassword] = useState(false);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box pb={2}>
          <Typography variant="h3">Security</Typography>
          <Typography variant="subtitle2">
            Change your security preferences below
          </Typography>
        </Box>
        <Card>
          <List>
            <ListItem sx={{ p: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1
                }}
                primary="Change Password"
                secondary="You can change your password here"
              />
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  setOpenChangePassword(true);
                }}
              >
                Change password
              </Button>
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  lineHeight: 1
                }}
                primary="Two-Factor Authentication"
                secondary="Enable PIN verification for all sign in attempts"
              />
              <Switch color="primary" />
            </ListItem>
          </List>
        </Card>
        <ChangePasswordDialog
          open={openChangePassword}
          setOpen={setOpenChangePassword}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            subheaderTypographyProps={{}}
            titleTypographyProps={{}}
            title="Access Logs"
            subheader="Recent sign in activity logs"
          />
          <Divider />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Browser</TableCell>
                  <TableCell>OS</TableCell>
                  <TableCell>IP Address</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Last Login Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user?.accessLogs.map(log => (
                  <TableRow key={log.lastAccessedAt} hover>
                    <TableCell>
                      {log.browser !== '' ? log.browser : 'Unknown'}
                    </TableCell>
                    <TableCell>
                      {log.operatingSystem !== ''
                        ? log.operatingSystem
                        : 'Unknown'}
                    </TableCell>
                    <TableCell>{log.ipAddress}</TableCell>
                    <TableCell>{log.country}</TableCell>
                    <TableCell>
                      {format(
                        new Date(log.lastAccessedAt),
                        'dd MMMM, yyyy - h:mm:ss a'
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Grid>
    </Grid>
  );
}

export default SecurityTab;
