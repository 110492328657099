import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import {
  Box,
  Button,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import { Integration, IntegrationType } from 'common/types';
import { useEffect, useState } from 'react';

import { IntegrationMap } from 'common/helpers';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { syncStatusChips } from '../IntegrationDashboard/lib/helpers';

interface IntegrationHistory {
  type: IntegrationType;
  runTime: Date;
  status: string;
  updated: number;
}

interface IntegrationHistoryProps {
  integrations: Integration[];
}
function IntegrationHistory(props: IntegrationHistoryProps) {
  const { t }: { t: any } = useTranslation();
  const [history, setHistory] = useState<IntegrationHistory[]>([]);
  const theme = useTheme();

  useEffect(() => {
    const history: IntegrationHistory[] = [];
    props.integrations.forEach(integration => {
      integration.history?.forEach(h => {
        history.push({
          type: integration.type,
          runTime: new Date(h.timestamp),
          status: h.status,
          updated: h.updates
        });
      });
    });
    //sort
    history.sort((a, b) => {
      return b.runTime.getTime() - a.runTime.getTime();
    });

    setHistory(history);
  }, [props.integrations]);

  return (
    <Card>
      <Box
        p={2.5}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography gutterBottom variant="h4">
            {t('Integration History')}
          </Typography>
          <Typography variant="subtitle2">
            {t('View integration sync history')}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          minHeight: 350
        }}
      >
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t('Integration')}</TableCell>
                <TableCell>{t('Run Time')}</TableCell>
                <TableCell>{t('Status')}</TableCell>
                <TableCell>{t('Updates')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((row, index) => (
                <TableRow hover key={index}>
                  <TableCell>{IntegrationMap[row.type]}</TableCell>
                  <TableCell>{row.runTime.toLocaleString()}</TableCell>
                  <TableCell>{syncStatusChips[row.status]}</TableCell>
                  <TableCell>{row.updated}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Divider />
      <Box
        p={2}
        sx={{
          textAlign: 'center'
        }}
      >
        <NavLink
          to={'/dashboard/settings/integrations'}
          style={{ textDecoration: 'none' }}
        >
          <Button
            sx={{
              textTransform: 'uppercase',
              fontSize: `${theme.typography.pxToRem(12)}`
            }}
            variant="contained"
            endIcon={<KeyboardArrowRightTwoToneIcon />}
          >
            {t('View all history')}
          </Button>
        </NavLink>
      </Box>
    </Card>
  );
}

export default IntegrationHistory;
