import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import GroupIcon from '@mui/icons-material/Group';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Grid, useTheme } from '@mui/material';
import LogoLoading from 'app/components/LogoLoading';
import PageTitleWrapper from 'app/components/PageTitleWrapper';
import { IntegrationApi, OrganizationApi } from 'common/api';
import { Integration, UsageSnapshot, UsageStats } from 'common/types';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ChatStats from './ChatStats';
import IntegrationHistory from './IntegrationHistory';
import PageHeader from './PageHeader';
import RecentActivityCard from './RecentActivity';
import StatCard from './StatCard';
import StorageStats from './StorageStats';

function DashboardHomePage() {
  const { t }: { t: any } = useTranslation();
  const [usageStats, setUsageStats] = useState<UsageStats | undefined>();
  const [currentMonthStats, setCurrentMonthStats] = useState<UsageSnapshot[]>(
    []
  );
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { organization } = useAuth();
  const theme = useTheme();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setIsLoading(true);
    const promises = [
      fetchUsageStats(),
      fetchIntegrations(),
      fetchUsageSnapshots()
    ];
    await Promise.all(promises);
    setIsLoading(false);
  };

  const fetchIntegrations = async () => {
    if (!organization?.orgId) return;

    const response = await IntegrationApi.getIntegrations(organization?.orgId);
    if (response.success && response.data) {
      const activeIntegrations = response.data;
      setIntegrations(activeIntegrations);
    }
  };

  const fetchUsageStats = async () => {
    if (!organization?.orgId) return;
    const response = await OrganizationApi.getUsageStats(organization.orgId);
    if (response.data) {
      setUsageStats(response.data as UsageStats);
    }
  };

  const fetchUsageSnapshots = async () => {
    if (!organization?.orgId) return;
    const startDate = new Date();
    startDate.setDate(1);
    const endDate = new Date();
    endDate.setDate(1);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(endDate.getDate() - 1);
    const response2 = await OrganizationApi.getUsageStats(
      organization.orgId,
      startDate.toISOString(),
      endDate.toISOString()
    );

    if (response2.data) {
      setCurrentMonthStats(response2.data as UsageSnapshot[]);
    }
  };

  return (
    <>
      <Helmet>
        <title>Admin Dashboard</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <LogoLoading size="l" />
        </Box>
      ) : (
        <Grid
          sx={{
            px: 4,
            pb: 2
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item xs={6} md={3}>
            <StatCard
              icon={<PersonIcon />}
              value={usageStats?.totalUsers.toString() ?? '0'}
              label={t('Users')}
              link={'/dashboard/security/users'}
              color={theme.colors.primary.main}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <StatCard
              icon={<GroupIcon />}
              value={organization?.permissionGroups.length.toString() ?? '0'}
              label={t('Groups')}
              link={'/dashboard/security/groups'}
              color={theme.colors.success.main}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <StatCard
              icon={<LibraryBooksIcon />}
              value={usageStats?.totalDocuments.toString() ?? '0'}
              label={t('Documents')}
              link={'/dashboard/knowledgebase'}
              color={theme.colors.warning.main}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <StatCard
              icon={<ElectricalServicesIcon />}
              value={usageStats?.totalIntegrations.toString() ?? '0'}
              label={t('Integrations')}
              link={'/dashboard/settings/integrations'}
              color={theme.colors.error.main}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {usageStats && (
              <ChatStats
                usageStats={usageStats}
                monthlyStats={currentMonthStats}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {usageStats && <StorageStats usageStats={usageStats} />}
          </Grid>
          <Grid item xs={12} md={6}>
            <IntegrationHistory integrations={integrations} />
          </Grid>
          <Grid item xs={12} md={6}>
            <RecentActivityCard />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default DashboardHomePage;
