import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Popover,
  Typography
} from '@mui/material';
import { CSSObject, Theme, useTheme } from '@mui/material/styles';
import { Conversation, WorkflowInputType, WorkflowKey } from 'common/types';
import useAuth from 'hooks/useAuth';
import { ReactNode, useState } from 'react';
import { ToolDialog } from './ToolDialog';

export interface ToolUI {
  title: string;
  description: string;
  icon: ReactNode;
  workflowKey: WorkflowKey;
  parameters: ToolParameter[];
}
export interface ToolParameter {
  key: string;
  label: string;
  description: string;
  type: WorkflowInputType;
  required?: boolean;
  valuesAllowed?: { label: string; value: string }[];
  value?: string;
}

export enum PresentationStyle {
  PROFESSIONAL = 'professional',
  FRIENDLY = 'friendly',
  HUMOROUS = 'humorous',
  CONVERSATIONAL = 'conversational',
  INFORMATIVE = 'informative'
}

const tools: ToolUI[] = [
  {
    title: 'Report',
    description: 'Generate a report',
    workflowKey: WorkflowKey.GENERATE_REPORT,
    icon: <SummarizeIcon />,
    parameters: [
      {
        key: 'objective',
        label: 'Report Objective',
        description:
          'What you want the report to be about. Make sure you always include important contextual information in your objective.',
        required: true,
        type: WorkflowInputType.TEXT
      },
      {
        key: 'sections',
        label: 'Report Sections',
        description: 'What sections do you want the report to have?',
        required: true,
        type: WorkflowInputType.TEXT
      },
      {
        key: 'fileType',
        label: 'File Type',
        description: 'What file type do you want the report to be?',
        required: true,
        type: WorkflowInputType.SELECT,
        valuesAllowed: [
          { label: 'PDF (.pdf)', value: 'pdf' },
          { label: 'Microsoft Word (.docx)', value: 'docx' },
          { label: 'OpenDocument (.odt)', value: 'odt' },
          { label: 'Markdown (.md)', value: 'md' },
          { label: 'Text (.txt)', value: '.txt' }
        ]
      },
      {
        key: 'specialRequests',
        label: 'Additional Information',
        description: 'Any there any special requests for the report?',
        required: false,
        type: WorkflowInputType.TEXT
      }
    ]
  },
  {
    title: 'Presentation',
    description: 'Generate a presentation',
    workflowKey: WorkflowKey.GENERATE_PRESENTATION,
    icon: <SlideshowIcon />,
    parameters: [
      {
        key: 'objective',
        label: 'Report Objective',
        description: 'What do you want the report to be about?',
        required: true,
        type: WorkflowInputType.TEXT
      },
      {
        key: 'tone',
        label: 'Tone',
        description: 'What tone do you want the presentation to be in?',
        required: true,
        type: WorkflowInputType.SELECT,
        valuesAllowed: [
          {
            label: 'Professional',
            value: PresentationStyle.PROFESSIONAL
          },
          {
            label: 'Friendly',
            value: PresentationStyle.FRIENDLY
          },
          {
            label: 'Humorous',
            value: PresentationStyle.HUMOROUS
          },
          {
            label: 'Conversational',
            value: PresentationStyle.CONVERSATIONAL
          },
          {
            label: 'Informative',
            value: PresentationStyle.INFORMATIVE
          }
        ]
      },
      {
        key: 'length',
        label: 'Length',
        description: 'How long should the presentation be?',
        required: true,
        type: WorkflowInputType.SELECT,
        valuesAllowed: [
          {
            label: 'Short (5 Slides)',
            value: '5 Slides, 5 minutes'
          },
          {
            label: 'Medium (10 Slides)',
            value: '10 Slides, 15 minutes'
          },
          {
            label: 'Long (20 Slides)',
            value: '20 Slides, 30 minutes'
          }
        ]
      }
    ]
  }
];

const sidebarWidth = '250px';
const openedMixin = (theme: Theme): CSSObject => ({
  width: sidebarWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(5)} + 1px)`
});

interface ToolDrawerProps {
  open: boolean;
  isLoading: boolean;
  setOpen: (open: boolean) => void;
  conversations: Conversation[];
  setConversations: (conversations: Conversation[]) => void;
  selectedConversation?: Conversation;
  setSelectedConversation: (conversation?: Conversation) => void;
}
export const ToolDrawer = (props: ToolDrawerProps) => {
  const theme = useTheme();
  const isMobile = window.innerWidth < theme.breakpoints.values.md;
  const [selectedTool, setSelectedTool] = useState<ToolUI | undefined>(
    undefined
  );
  const [openToolDialog, setOpenToolDialog] = useState(false);
  const { organization } = useAuth();
  const enabledTools = tools.filter(tool =>
    organization?.settings.enabledTools.includes(
      tool.workflowKey as WorkflowKey
    )
  );

  return (
    <Box sx={enabledTools.length === 0 ? { display: 'none' } : {}}>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={props.open}
        anchor="right"
        onClose={() => props.setOpen(false)}
        sx={{
          width: sidebarWidth,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          ...(props.open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme)
          }),
          ...(!props.open &&
            !isMobile && {
              ...closedMixin(theme),
              '& .MuiDrawer-paper': closedMixin(theme)
            }),
          position: 'relative',
          height: '100%',
          paddingBottom: '0px',
          zIndex: 1
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '17px',
            left: '1px'
          }}
        >
          <IconButton
            onClick={() => {
              props.setOpen(!props.open);
            }}
          >
            {props.open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '20px',
            left: '90px'
          }}
        >
          <Typography variant="h3">Tools</Typography>
        </Box>
        <Box mt="71px">
          <Divider />
          {enabledTools.map((tool: ToolUI, index: number) => (
            <ToolDrawerItem
              key={index}
              tool={tool}
              expanded={props.open}
              onClick={() => {
                if (props.isLoading) return;
                setSelectedTool(tool);
                setOpenToolDialog(true);
              }}
            />
          ))}
        </Box>
      </Drawer>
      {selectedTool && (
        <ToolDialog
          tool={selectedTool}
          open={openToolDialog}
          selectedConversation={props.selectedConversation}
          onClose={() => {
            setOpenToolDialog(false);
            setSelectedTool(undefined);
          }}
          onSuccess={async (conversation: Conversation) => {
            props.setSelectedConversation(conversation);
            props.setConversations(
              props.conversations.map(c => {
                if (c.conversationId === conversation.conversationId) {
                  return conversation;
                }
                return c;
              })
            );
            setOpenToolDialog(false);
            setSelectedTool(undefined);
          }}
        />
      )}
    </Box>
  );
};

interface ToolDrawerItemProps {
  tool: ToolUI;
  expanded: boolean;
  onClick: () => void;
}

const ToolDrawerItem = (props: ToolDrawerItemProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '&:hover': {
            background: theme.colors.alpha.black[10]
          },
          cursor: 'pointer',
          padding: '5px 10px',
          wordWrap: 'break-word'
        }}
        onClick={props.onClick}
        onMouseEnter={event => {
          setAnchorEl(event.currentTarget);
        }}
        onMouseLeave={() => {
          setAnchorEl(null);
        }}
      >
        <Box mr={2}>{props.tool.icon}</Box>
        <Box>
          <Typography variant="h6">{props.tool.title}</Typography>
          <Typography variant="subtitle1">{props.tool.description}</Typography>
        </Box>
      </Box>
      <Popover
        open={open && !props.expanded}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
        sx={{
          pointerEvents: 'none',
          '& .MuiPopover-paper': {
            pointerEvents: 'none',
            padding: 1
          }
        }}
      >
        <Typography>{props.tool.title}</Typography>
      </Popover>
    </>
  );
};
