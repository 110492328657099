import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import { SubscriptionTiers } from 'app/pages/Pricing/lib/constants';
import { OrganizationApi } from 'common/api';
import { MapToOptions, OrgIndustryMap, OrgSizeMap } from 'common/helpers';
import {
  FrontendRoutes,
  OrgIndustry,
  OrgSize,
  SubscriptionType
} from 'common/types';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const BoxActions = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]}
`
);

const MainContent = styled(Box)(
  () => `
    height: 100%;
    overflow: auto;
    flex: 1;
`
);

interface AccountSetupWizardProps {}

interface NewOrg {
  company_name?: string;
  company_size?: string;
  company_industry?: string;
}

export const AccountSetupWizard = (props: AccountSetupWizardProps) => {
  const { t }: { t: any } = useTranslation();
  const { refreshData } = useAuth();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const [newOrg, setNewOrg] = useState<NewOrg>({});
  const [selectedSubscription, setSelectedSubscription] =
    useState<SubscriptionType>(SubscriptionType.FREE);
  const orgSizeOptions = MapToOptions(OrgSizeMap);
  const orgIndustryOptions = MapToOptions(OrgIndustryMap);

  return (
    <>
      <Helmet>
        <title>Setup Account</title>
      </Helmet>
      <MainContent>
        <Container
          sx={{
            my: 4
          }}
          maxWidth={step === 0 ? 'md' : 'lg'}
        >
          <Card
            sx={{
              mt: 4,
              pt: 5
            }}
          >
            <Box px={4}>
              <Typography
                variant="h2"
                sx={{
                  mb: 1
                }}
              >
                {t('Setup Account')}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 1
                }}
              >
                {t('Fill in the fields below to setup your account.')}
              </Typography>
            </Box>

            <Stepper activeStep={step} alternativeLabel>
              <Step>
                <StepLabel>{t('Organization Details')}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{t('Subscription Details')}</StepLabel>
              </Step>
            </Stepper>
            {step === 0 && (
              <Formik
                initialValues={{
                  company_name: newOrg.company_name ?? '',
                  company_size: newOrg.company_size ?? '',
                  company_industry: newOrg.company_industry ?? '',
                  submit: null
                }}
                validationSchema={Yup.object().shape({
                  company_size: Yup.string().required(
                    t('The Company Size is required')
                  ),
                  company_name: Yup.string()
                    .max(64)
                    .required(t('The Company Name is required')),
                  company_industry: Yup.string().required(
                    t('The Company Industry is required')
                  )
                })}
                onSubmit={async (
                  values,
                  { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                  setSubmitting(true);
                  setStep(prev => prev + 1);
                  setNewOrg({
                    ...newOrg,
                    company_name: values.company_name,
                    company_size: values.company_size,
                    company_industry: values.company_industry
                  });
                  setStatus({ success: true });
                  setSubmitting(false);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    {Boolean(touched.submit && errors.submit) && (
                      <Box textAlign="center">
                        <Alert severity="error">{errors.submit}</Alert>
                      </Box>
                    )}
                    <Grid container spacing={3} justifyContent="center" p={4}>
                      <Grid item xs={12} md={8}>
                        <TextField
                          error={Boolean(
                            touched.company_name && errors.company_name
                          )}
                          fullWidth
                          helperText={
                            touched.company_name && errors.company_name
                          }
                          label={t('Company Name')}
                          name="company_name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="company_name"
                          value={values.company_name}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <InputLabel variant="standard">Company Size</InputLabel>
                        <Select
                          error={Boolean(
                            touched.company_size && errors.company_size
                          )}
                          fullWidth
                          name="company_size"
                          label={t('Company Size')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          value={values.company_size}
                        >
                          {orgSizeOptions.map(r => (
                            <MenuItem key={r.value} value={r.value}>
                              {r.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <InputLabel variant="standard">
                          Company Industry
                        </InputLabel>
                        <Select
                          error={Boolean(
                            touched.company_industry && errors.company_industry
                          )}
                          fullWidth
                          name="company_industry"
                          label={t('Company Industry')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          value={values.company_industry}
                        >
                          {orgIndustryOptions.map(r => (
                            <MenuItem key={r.value} value={r.value}>
                              {r.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                    <BoxActions
                      p={4}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        type="button"
                        disabled={true}
                        onClick={() => {
                          setStep(prev => prev - 1);
                        }}
                      >
                        {t('Previous')}
                      </Button>

                      <Button
                        startIcon={
                          isSubmitting ? <CircularProgress size="1rem" /> : null
                        }
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {t('Next Step')}
                      </Button>
                    </BoxActions>
                  </form>
                )}
              </Formik>
            )}
            {step === 1 && (
              <Formik
                initialValues={{
                  submit: null
                }}
                onSubmit={async (
                  values,
                  { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                  setSubmitting(true);

                  if (
                    !newOrg.company_name ||
                    !newOrg.company_size ||
                    !newOrg.company_industry
                  ) {
                    setStatus({ success: false });
                    setErrors({ submit: 'Please fill out all fields' });
                    setSubmitting(false);
                    return;
                  }

                  try {
                    const response = await OrganizationApi.createOrganization(
                      newOrg.company_name,
                      newOrg.company_industry as OrgIndustry,
                      newOrg.company_size as OrgSize,
                      selectedSubscription
                    );
                    if (response.success) {
                      await refreshData();
                      setStatus({ success: true });
                      setSubmitting(false);
                      navigate(FrontendRoutes.CHAT);
                    } else {
                      setErrors({ submit: 'Unexpected Error' });
                      setStatus({ success: false });
                      setSubmitting(false);
                    }
                  } catch (error) {
                    console.log(error);
                    setErrors({ submit: 'Unexpected Error' });
                    setStatus({ success: false });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    {Boolean(touched.submit && errors.submit) && (
                      <Box textAlign="center">
                        <Alert severity="error">{errors.submit}</Alert>
                      </Box>
                    )}

                    <Box p={4}>
                      <Grid container spacing={4}>
                        {SubscriptionTiers.map((tier, index) => (
                          <Grid item xs={12} md={6} lg={3} key={index}>
                            <Card
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'center'
                              }}
                            >
                              <CardContent style={{ flexGrow: 1 }}>
                                <CardHeader
                                  title={tier.name}
                                  titleTypographyProps={{ align: 'center' }}
                                  subheaderTypographyProps={{
                                    align: 'center'
                                  }}
                                  subheader={
                                    typeof tier.price !== 'undefined'
                                      ? `$${tier.price} / month`
                                      : `Contact Us`
                                  }
                                  action={
                                    tier.type === SubscriptionType.PRO ? (
                                      <Tooltip title={t('Most popular')}>
                                        <StarIcon style={{ color: 'gold' }} />
                                      </Tooltip>
                                    ) : null
                                  }
                                  style={{ backgroundColor: '#F4F6F8' }}
                                >
                                  {tier.type === SubscriptionType.PRO ? (
                                    <Typography
                                      variant="h3"
                                      color="textPrimary"
                                    >
                                      {t('Most Popular')}
                                    </Typography>
                                  ) : null}
                                </CardHeader>
                                <List>
                                  {tier.features.map((feature, index) => (
                                    <ListItem key={index}>
                                      <ListItemIcon style={{ color: 'green' }}>
                                        <CheckIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={feature} />
                                    </ListItem>
                                  ))}
                                  {tier.disabledFeatures.map(
                                    (disabledFeature, index) => (
                                      <ListItem key={index}>
                                        <ListItemIcon style={{ color: 'red' }}>
                                          <CloseIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={disabledFeature}
                                        />
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </CardContent>
                              <Divider />
                              <CardActions>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  width="100%"
                                >
                                  {tier.type === SubscriptionType.ENTERPRISE ? (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      href="mailto:sales@meetava.ai"
                                      style={{
                                        width: '80%',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                      }}
                                    >
                                      Contact Us
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      disabled={
                                        selectedSubscription === tier.type
                                      }
                                      onClick={() => {
                                        setSelectedSubscription(tier.type);
                                      }}
                                      style={{
                                        width: '80%',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                      }}
                                    >
                                      {selectedSubscription === tier.type
                                        ? t('Selected')
                                        : t('Select')}
                                    </Button>
                                  )}
                                </Box>
                              </CardActions>
                            </Card>
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <Typography variant="h6" align="center">
                            {t(
                              'You can change your subscription tier at any time.'
                            )}
                          </Typography>
                          <Typography variant="h6" align="center">
                            {t(
                              'Looking for a custom solution for your business? Contact our sales team for more information.'
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <BoxActions
                      p={4}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        type="button"
                        onClick={() => {
                          setStep(prev => prev - 1);
                        }}
                      >
                        {t('Previous')}
                      </Button>

                      <Button
                        startIcon={
                          isSubmitting ? <CircularProgress size="1rem" /> : null
                        }
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {t('Complete Registration')}
                      </Button>
                    </BoxActions>
                  </form>
                )}
              </Formik>
            )}
          </Card>
        </Container>
      </MainContent>
    </>
  );
};
