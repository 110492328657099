import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { ConversationApi } from 'common/api';
import { Conversation } from 'common/types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

interface EditConversationDialogProps {
  conversation: Conversation;
  open: boolean;
  onClose: () => void;
  onSuccess: (item: Conversation) => void;
}
function EditConversationDialog(props: EditConversationDialogProps) {
  const { t }: { t: any } = useTranslation();

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose}>
      <DialogTitle>
        <Typography variant="h4" gutterBottom>
          {t('Edit Name')}
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          name: props.conversation.name,
          submit: null
        }}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            setSubmitting(true);
            if (!_values.name) {
              setStatus({ success: false });
              setErrors({
                submit: t('Please enter a name for the conversation.')
              });
              setSubmitting(false);
              return;
            }

            const response = await ConversationApi.updateConversation(
              props.conversation.conversationId,
              { name: _values.name }
            );
            if (response.success) {
              setStatus({ success: true });
              setSubmitting(false);
              props.conversation.name = _values.name;
              props.onSuccess(props.conversation);
              resetForm();
            } else {
              setStatus({ success: false });
              setErrors({ submit: response.errorMessage });
              setSubmitting(false);
            }
          } catch (err: any) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            {Boolean(touched.submit && errors.submit) && (
              <Alert severity="error">{errors.submit}</Alert>
            )}
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} lg={7}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label={t('Conversation Name')}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                p: 2
              }}
            >
              <Button color="secondary" onClick={props.onClose}>
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={Boolean(errors.submit) || isSubmitting}
                variant="contained"
              >
                {t('Save')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default EditConversationDialog;
