import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Zoom
} from '@mui/material';
import {
  OrganizationApi,
  OrganizationMember
} from 'common/api/organization-service-api';
import { OrgRole, Organization } from 'common/types';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { UpdateType } from './lib';
import { orgRoles } from './lib/contants';

interface PageHeaderProps {
  updateUsers: (type: UpdateType, items: OrganizationMember[]) => void;
  organization: Organization | undefined;
}

function PageHeader(props: PageHeaderProps) {
  const { t }: { t: any } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user, refreshOrganization: fetchOrganization } = useAuth();

  const handleCreateUserOpen = () => {
    setOpen(true);
  };

  const handleCreateUserClose = () => {
    setOpen(false);
  };

  const handleCreateUserSuccess = () => {
    enqueueSnackbar(t('The user was invited successfully'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });

    setOpen(false);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('User Management')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'All aspects related to the app users can be managed from this page'
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            onClick={handleCreateUserOpen}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {t('Invite User')}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleCreateUserClose}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('Add new user')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Fill in the fields below to create and add a new user to the site'
            )}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={{
            email: '',
            role: OrgRole.USER,
            submit: null
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(t('The email provided should be a valid email address'))
              .max(64, t('Must be less than 64 characters'))
              .required(t('The email field is required')),
            role: Yup.string().required(t('The role field is required'))
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            if (!props.organization) return;
            try {
              setSubmitting(true);

              const response = await OrganizationApi.inviteMember(
                props.organization.orgId,
                _values.email,
                _values.role
              );
              if (response.success) {
                await fetchOrganization();
                setStatus({ success: true });
                setSubmitting(false);
                handleCreateUserSuccess();
                resetForm();
              } else {
                setStatus({ success: false });
                setErrors({ submit: response.errorMessage });
                setSubmitting(false);
              }
            } catch (err: any) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              {Boolean(touched.submit && errors.submit) && (
                <Box textAlign="center">
                  <Alert severity="error">{errors.submit}</Alert>
                </Box>
              )}
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} lg={7}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          label={t('Email address')}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="email"
                          value={values.email}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputLabel variant="standard">Role</InputLabel>
                        <Select
                          error={Boolean(touched.role && errors.role)}
                          fullWidth
                          name="role"
                          label={t('Role')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          value={values.role}
                        >
                          {orgRoles.map(r => (
                            <MenuItem
                              key={r.value}
                              value={r.value}
                              disabled={
                                r.value === 'owner' &&
                                props.organization?.ownerId !== user?.userId
                              }
                            >
                              {r.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={handleCreateUserClose}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {t('Invite User')}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default PageHeader;
