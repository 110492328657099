import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  Zoom,
  styled
} from '@mui/material';
import { UserApi } from 'common/api';
import { FrontendRoutes } from 'common/types';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link } from 'react-router-dom';
const MainContent = styled(Box)(
  ({ theme }) => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

export const UnverifiedPage = () => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [lastEmailSentAt, setLastEmailSentAt] = useState<Date | undefined>(
    undefined
  );

  const emailLimitInSeconds = 60;
  let secondsSinceLastEmail = 999;
  if (lastEmailSentAt) {
    secondsSinceLastEmail = Math.floor(
      (new Date().getTime() - lastEmailSentAt.getTime()) / 1000
    );
  }

  const errorPopup = () => {
    enqueueSnackbar('Unexpected Error', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const sendVerificationEmail = async () => {
    if (!user?.userId) return;
    if (secondsSinceLastEmail < emailLimitInSeconds) {
      return;
    }
    setIsLoading(true);

    try {
      const response = await UserApi.resendVerificationEmail(user?.userId);
      if (response.success) {
        setLastEmailSentAt(new Date());
        enqueueSnackbar('Verification email sent', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      } else {
        errorPopup();
      }
    } catch (err: any) {
      console.error(err);
      errorPopup();
    }
    setIsLoading(false);
  };

  return (
    <MainContent>
      <Container component="main" maxWidth="md">
        <Typography variant="h1" sx={{ textAlign: 'center' }}>
          Your email is not verified
        </Typography>
        <br />
        <Typography
          variant="subtitle1"
          sx={{ textAlign: 'center', mb: '15px' }}
        >
          Please check your email for a verification link.
        </Typography>
        <Box display="flex" justifyContent="center">
          <Button component={Link} to={FrontendRoutes.ACCOUNT_SETTINGS}>
            Change Email
          </Button>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            onClick={sendVerificationEmail}
            disabled={isLoading || secondsSinceLastEmail < emailLimitInSeconds}
            startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
          >
            Resend Email
          </Button>
        </Box>
      </Container>
    </MainContent>
  );
};
