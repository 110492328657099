import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Button, Divider, Drawer, IconButton } from '@mui/material';
import {
  CSSObject,
  Theme,
  darken,
  styled,
  useTheme
} from '@mui/material/styles';
import Logo from 'app/components/Logo';
import { Conversation, ConversationStatus, FrontendRoutes } from 'common/types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ConversationSettingsDialog from './Dialogs/ConversationSettingsDialog';
import { ConversationTabs } from './components/ConversationTabs';
import { UserProfileBox } from './components/UserProfileBox';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 0px;
`
);

const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.sidebar.width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

interface ConversationsDrawerProps {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  conversations: Conversation[];
  setConversations: (conversations: Conversation[]) => void;
  selectedConversation?: Conversation;
  setSelectedConversation: (conversation?: Conversation) => void;
}
export const ConversationsDrawer = (props: ConversationsDrawerProps) => {
  const theme = useTheme();
  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const isMobile = window.innerWidth < theme.breakpoints.values.md;

  const handleDrawerClose = () => {
    props.onClose();
  };

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={props.open}
      onClose={handleDrawerClose}
      sx={{
        width: theme.sidebar.width,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(props.open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme)
        }),
        position: 'relative',
        height: '100%',
        paddingBottom: '0px'
      }}
    >
      <SidebarWrapper
        sx={{
          background: darken(theme.colors.alpha.black[100], 0.5)
        }}
      >
        <Box
          pt={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ background: 'transparent' }}
        >
          <Box component={Link} to={FrontendRoutes.HOME}>
            <Logo size="l" variant="text-below-white" />
          </Box>
          <Box justifyContent="flex-end">
            <IconButton
              sx={{
                display: { lg: 'none', xs: 'flex' },
                position: 'absolute',
                right: '0px',
                top: '60px',
                color: theme.colors.alpha.trueWhite[70]
              }}
              onClick={handleDrawerClose}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
        </Box>

        <Divider
          sx={{
            m: 2,
            background: theme.colors.alpha.trueWhite[10]
          }}
        />
        <ConversationTabs
          conversations={props.conversations}
          isLoading={props.isLoading}
          selectedConversation={props.selectedConversation}
          setSelectedConversation={props.setSelectedConversation}
          setConversations={props.setConversations}
          onConversationClick={conversation =>
            props.setSelectedConversation(conversation)
          }
        />
        <Box
          style={{
            position: 'absolute',
            zIndex: 10,
            bottom: '0px',
            left: '0px',
            width: 'inherit',
            height: 'auto'
          }}
        >
          <Divider />
          <Box display="flex" alignItems="center" justifyContent="center" p={2}>
            <Box pr={1}>
              <UserProfileBox
                onChatSettingsClick={() => setOpenSettings(true)}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddIcon />}
                onClick={() => {
                  props.setSelectedConversation(undefined);
                }}
                sx={{
                  padding: '10px 20px'
                }}
              >
                New Chat
              </Button>
            </Box>
          </Box>
        </Box>
        <ConversationSettingsDialog
          open={openSettings}
          setOpen={setOpenSettings}
          onDeleteAll={() => {
            props.setConversations([]);
          }}
          onArchiveAll={() => {
            const updatedConversations = props.conversations.map(c => {
              c.status = ConversationStatus.ARCHIVED;
              return c;
            });
            props.setConversations(updatedConversations);
          }}
        />
      </SidebarWrapper>
    </Drawer>
  );
};
