import {
  BasicResponse,
  FrontendUser,
  FullName,
  HttpMethods,
  NotificationSettings,
  User
} from 'common/types';
import { BulkResponse, FetchProps, FetchUrl } from './api-client';

export class UserApi {
  public static async getUsersByOrgId(
    orgId: string,
    amount?: number,
    startCursor?: string
  ): Promise<BasicResponse<BulkResponse<FrontendUser>>> {
    const params = new URLSearchParams();
    if (amount) {
      params.append('amount', amount.toString());
    }
    if (startCursor) {
      params.append('startCursor', startCursor);
    }
    const request: FetchProps = {
      apiEndpoint: `/api/users/organization/${orgId}/users`,
      method: HttpMethods.GET,
      parameters: params
    };

    const response = await FetchUrl<BulkResponse<FrontendUser>>(request);
    return response;
  }

  public static async getUser(userId: string): Promise<BasicResponse<User>> {
    const request: FetchProps = {
      apiEndpoint: `/api/users/${userId}`,
      method: HttpMethods.GET
    };
    const response = await FetchUrl<User>(request);
    return response;
  }

  public static async updateUser(
    userId: string,
    updates: {
      name?: FullName;
      email?: string;
      notificationSettings?: NotificationSettings;
    }
  ): Promise<BasicResponse<FrontendUser>> {
    const request: FetchProps = {
      apiEndpoint: `/api/users/${userId}`,
      method: HttpMethods.PUT,
      body: updates
    };
    const response = await FetchUrl<FrontendUser>(request);
    return response;
  }

  public static async doesEmailExist(
    email: string
  ): Promise<BasicResponse<boolean>> {
    const request: FetchProps = {
      apiEndpoint: '/api/users/register-check',
      method: HttpMethods.POST,
      body: {
        email: email
      }
    };
    const response = await FetchUrl<boolean>(request);
    return response;
  }

  public static async registerUser(
    email: string,
    name: FullName,
    password?: string,
    orgId?: string,
    orgInviteId?: string,
    firebaseId?: string
  ): Promise<BasicResponse<FrontendUser>> {
    const request: FetchProps = {
      apiEndpoint: '/api/users/register',
      method: HttpMethods.POST,
      body: {
        email,
        password,
        name,
        orgId,
        orgInviteId,
        firebaseId
      }
    };
    const response = await FetchUrl<FrontendUser>(request);
    return response;
  }

  public static async leaveOrganization(
    userId: string
  ): Promise<BasicResponse<boolean>> {
    const request: FetchProps = {
      apiEndpoint: `/api/users/${userId}/leave-organization`,
      method: HttpMethods.DELETE
    };
    const response = await FetchUrl<boolean>(request);
    return response;
  }

  public static async resetPassword(
    userId: string,
    code: string,
    newPassword: string
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/users/${userId}/reset-password`,
      method: HttpMethods.PUT,
      body: {
        code,
        newPassword
      }
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async sendPasswordResetEmail(
    email: string
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: '/api/users/reset-password-request',
      method: HttpMethods.POST,
      body: {
        email
      }
    };
    const response = await FetchUrl<string>(request);
    return response;
  }

  public static async resendVerificationEmail(
    userId: string
  ): Promise<BasicResponse<string>> {
    const request: FetchProps = {
      apiEndpoint: `/api/users/${userId}/resend-code`,
      method: HttpMethods.POST
    };
    const response = await FetchUrl<string>(request);
    return response;
  }
}
