import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Slide,
  Typography,
  styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ConversationApi } from 'common/api';
import { Conversation } from 'common/types';
import { ReactElement, Ref, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface DeleteConversationDialogProps {
  conversation: Conversation;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const DeleteConversationDialog = ({
  conversation,
  open,
  onClose,
  onSuccess
}: DeleteConversationDialogProps) => {
  const { t }: { t: any } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const deleteConversationRequest = async () => {
    const response = await ConversationApi.deleteConversation(
      conversation.conversationId
    );
    if (!response.success) {
      console.error(response.errorMessage);
    } else {
      await onSuccess();
    }
  };

  return (
    <DialogWrapper
      open={open}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={5}
      >
        <AvatarError>
          <CloseIcon />
        </AvatarError>

        <Typography
          align="center"
          sx={{
            py: 4,
            px: 6
          }}
          variant="h3"
        >
          {t('Are you sure you want to delete this conversation')}?
        </Typography>

        <Box>
          <Button
            variant="text"
            size="large"
            sx={{
              mx: 1
            }}
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
          <ButtonError
            onClick={async () => {
              setIsLoading(true);
              await deleteConversationRequest();
              setIsLoading(false);
            }}
            size="large"
            sx={{
              mx: 1,
              px: 3
            }}
            variant="contained"
            startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
            disabled={isLoading}
          >
            {t('Delete')}
          </ButtonError>
        </Box>
      </Box>
    </DialogWrapper>
  );
};
