import {
  Box,
  Button,
  Card,
  CircularProgress,
  Typography,
  styled
} from '@mui/material';
import { ButtonError } from 'app/components/StyledComponents';
import { ConversationApi } from 'common/api';
import { Conversation, Message, MessageStatus } from 'common/types';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { AttachmentChips } from './AttachmentChips';
import { SourceChips } from './SourceChips';
import { UserApproval } from './UserApproval';
import { Worklog } from './Worklog';

const CardWrapperSecondary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      color: ${theme.colors.alpha.black[100]};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-left-radius: ${theme.general.borderRadius};
      display: flex;
      flex-direction: column;
`
);

const UserInputCardWrapper = styled(Card)(
  ({ theme }) => `
      background: white;
      color: ${theme.colors.alpha.black[100]};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-left-radius: ${theme.general.borderRadius};
      display: flex;
      flex-direction: column;
`
);

export enum MessageState {
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  WAITING_FOR_USER = 'WAITING_FOR_USER',
  ERROR = 'ERROR'
}

interface AvaMessageProps {
  message: Message;
  selectedConversation: Conversation;
  setSelectedConversation: (conversation: Conversation) => void;
  conversations: Conversation[];
  setConversations: (conversations: Conversation[]) => void;
}

export const AvaMessage = (props: AvaMessageProps) => {
  const [isRetrying, setIsRetrying] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [workLog, setWorkLog] = useState<Message[]>([]);
  const [currentState, setCurrentState] = useState<MessageState>(
    MessageState.PROCESSING
  );
  const [currentFunction, setCurrentFunction] = useState<Message | undefined>(
    undefined
  );
  let content =
    workLog.length === 0 ? (
      <CircularProgress sx={{ marginTop: '3px' }} size="1rem" />
    ) : (
      <Box></Box>
    );

  let waitingForUser =
    currentFunction &&
    currentFunction.workflowState &&
    currentFunction.workflowState.waitingForUserApproval;

  useEffect(() => {
    let parentId: string | undefined = props.message.id;
    const parentMessageIsErrored = props.message.status === MessageStatus.ERROR;
    let workLog: Message[] = [];
    const messagesCopy = [...props.selectedConversation.messages];
    while (parentId !== undefined) {
      const childFunction = messagesCopy.find(
        message => message.parentId === parentId && message.role === 'function'
      );
      if (!childFunction) break;

      if (childFunction.status === MessageStatus.PROCESSING) {
        setCurrentFunction(childFunction);
      }

      if (childFunction.workflowState?.waitingForUserApproval) {
        childFunction.workflowState.logs = [
          {
            log: `Waiting for user approval`,
            timestamp: childFunction.timestamp,
            id: 'userinput',
            status: MessageStatus.PROCESSING
          }
        ];
        setCurrentState(MessageState.WAITING_FOR_USER);
      } else if (childFunction.status === MessageStatus.COMPLETED) {
        setCurrentState(MessageState.DONE);
      } else {
        setCurrentState(MessageState.PROCESSING);
      }

      if (parentMessageIsErrored) {
        setCurrentState(MessageState.ERROR);
        childFunction.status = MessageStatus.ERROR;
      }

      workLog.push(childFunction);
      messagesCopy.splice(messagesCopy.indexOf(childFunction), 1);
      parentId = childFunction.id;
    }
    setWorkLog(workLog);
  }, [props.selectedConversation]);

  const updateConversions = (conversation: Conversation) => {
    let conversationsCopy = [...props.conversations];
    const indexOfConversation = conversationsCopy.findIndex(
      c => c.conversationId === conversation.conversationId
    );

    if (indexOfConversation !== -1) {
      conversationsCopy[indexOfConversation] = conversation;
    } else {
      conversationsCopy.unshift(conversation);
      props.setSelectedConversation(conversation);
    }

    if (
      props.selectedConversation?.conversationId === conversation.conversationId
    ) {
      props.setSelectedConversation(conversation);
    }

    props.setConversations(conversationsCopy);
  };

  const handleRetry = async () => {
    setIsRetrying(true);
    if (!props.message.parentId) return;

    const parentMessage = props.selectedConversation.messages.find(
      m => m.id === props.message.parentId
    );
    if (!parentMessage) return;

    const response = await ConversationApi.retryMessage(
      props.selectedConversation.conversationId,
      props.message.id
    );
    if (!response.success || !response.data) return;
    const updatedConvo = { ...response.data };

    updateConversions(updatedConvo);
    setIsRetrying(false);
  };

  const handleCancelMessage = async () => {
    setIsCanceling(true);
    const response = await ConversationApi.cancelMessage(
      props.selectedConversation.conversationId,
      props.message.id
    );
    if (!response.success || !response.data) return;
    const updatedConvo = { ...response.data };
    updateConversions(updatedConvo);
    setIsCanceling(false);
  };

  if (
    props.message.status === MessageStatus.PROCESSING &&
    waitingForUser &&
    currentFunction &&
    currentFunction.workflowState &&
    currentFunction.workflowState.userApproval
  ) {
    content = (
      <Box sx={{ width: 'min-content' }}>
        Before I can continue, I need some information from you.
        <UserInputCardWrapper sx={{ marginTop: '10px' }}>
          <UserApproval
            type={currentFunction.workflowState.userApproval.type}
            value={currentFunction.workflowState.userApproval.value}
            onApprove={async values => {
              const response = await ConversationApi.approveMessage(
                props.selectedConversation.conversationId,
                currentFunction.id,
                values
              );

              if (response.data) {
                updateConversions(response.data);
              }
            }}
          />
        </UserInputCardWrapper>
      </Box>
    );
  } else if (props.message.status === MessageStatus.ERROR) {
    content = (
      <Box>
        <span style={{ color: 'red' }}>{props.message.content}</span>
        <Button
          style={{ marginLeft: '10px' }}
          size="small"
          variant="contained"
          disabled={isCanceling}
          startIcon={
            isRetrying ? (
              <CircularProgress size="1rem" sx={{ color: 'white' }} />
            ) : null
          }
          onClick={handleRetry}
        >
          Retry
        </Button>
        <ButtonError
          variant="contained"
          size="small"
          sx={{ marginLeft: 1 }}
          disabled={isRetrying}
          startIcon={
            isCanceling ? (
              <CircularProgress size="1rem" sx={{ color: 'white' }} />
            ) : null
          }
          onClick={handleCancelMessage}
        >
          Cancel
        </ButtonError>
      </Box>
    );
  } else if (
    props.message.status === MessageStatus.COMPLETED ||
    props.message.content !== ''
  ) {
    content = (
      <Box>
        <ReactMarkdown
          children={props.message.content}
          remarkPlugins={[remarkGfm]}
        />
        <Box>
          {props.message.sources && props.message.sources.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Typography variant="h6">Sources: </Typography>
              <SourceChips urls={props.message.sources} />
            </Box>
          )}
          {props.message.attachments && props.message.attachments.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Typography variant="h6">Attachments: </Typography>
              <AttachmentChips
                conversationId={props.selectedConversation.conversationId}
                attachments={props.message.attachments}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <CardWrapperSecondary
      sx={{
        'table, th, td': {
          border: '1px solid',
          backgroundColor: 'whitesmoke'
        },
        'th, td': { padding: '5px', textAlign: 'center' },
        ':first-child': { marginTop: '0px' },
        ':last-child': { marginBottom: '0px' }
      }}
    >
      {workLog.length > 0 &&
        currentState !== MessageState.WAITING_FOR_USER &&
        props.message.content === '' && (
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent={'flex-start'}
            mb={2}
          >
            <Worklog workLog={workLog} state={currentState} />
          </Box>
        )}
      {content}
    </CardWrapperSecondary>
  );
};
