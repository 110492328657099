import { default as axios } from 'axios';
import firebase from 'firebase/compat/app';
import { BasicResponse, HttpMethods, IntegrationType } from '../types';

export interface FetchProps {
  apiEndpoint: string;
  method?: HttpMethods;
  body?: unknown;
  parameters?: URLSearchParams;
}

export interface BulkResponse<T> {
  items: T[];
  nextCursor: string;
}

export const WEBSOCKET_URL =
  'wss://syo022mf2m.execute-api.us-east-1.amazonaws.com/prod';

export const getOAuthInitiate = (type: IntegrationType, state: string) =>
  `https://ava.bryancantz.com/api/oauth/${type}/initiate?state=${state}`;

export const getOAuthCallback = (type: IntegrationType) =>
  `https://ava.bryancantz.com/api/oauth/${type}/callback`;

const apiClient = axios.create({
  baseURL: 'https://ava.bryancantz.com',
  timeout: 30000,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' }
});

export const FetchUrl = async <T>(
  props: FetchProps
): Promise<BasicResponse<T>> => {
  let url = props.apiEndpoint;

  if (props.parameters) {
    url = `${url}?${props.parameters}`;
  }

  try {
    const user = firebase.auth().currentUser;
    let token;

    if (user) {
      token = await user.getIdToken(true);
    }
    const res = await apiClient.request<T>({
      url: url,
      method: props.method || HttpMethods.GET,
      data: props.body ? JSON.stringify(props.body) : undefined,
      headers: token ? { Authorization: `Bearer ${token}` } : undefined
    });
    return {
      success: true,
      data: res.data
    };
  } catch (error: any) {
    console.error(error);
    return {
      success: false,
      errorMessage: error.message
    };
  }
};
