// PdfPreview.tsx
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  Typography,
  styled
} from '@mui/material';
import React from 'react';
import {
  Document as PDFDocument,
  Page
} from 'react-pdf/dist/esm/entry.webpack';

const PdfPageWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  position: relative;

  .react-pdf__Page {
    flex-shrink: 0;
    max-width: 100%;
    max-height: 100%;
  }

  .react-pdf__Page__canvas {
    object-fit: contain;
  }
`;

const NavigationButtons = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
`;

interface PdfPreviewProps {
  url?: string;
}

const PdfPreview = ({ url }: PdfPreviewProps) => {
  const [numPages, setNumPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);

  if (!url)
    return (
      <>
        <Typography>Loading PDF...</Typography>
        <CircularProgress size={75} />
      </>
    );

  return (
    <>
      <PDFDocument
        file={url}
        onLoadSuccess={({ numPages }) => {
          setNumPages(numPages);
          setCurrentPage(1);
        }}
        loading={<Typography>Loading PDF...</Typography>}
      >
        <PdfPageWrapper>
          <Page
            pageNumber={currentPage}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            renderMode="canvas"
          />
        </PdfPageWrapper>
      </PDFDocument>
      <NavigationButtons>
        <IconButton
          sx={{ mr: '10px', ml: '10px' }}
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
        >
          <NavigateBefore />
        </IconButton>
        <Typography>
          Page {currentPage} of {numPages}
        </Typography>
        <IconButton
          sx={{ mr: '10px', ml: '10px' }}
          disabled={currentPage === numPages}
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, numPages))}
        >
          <NavigateNext />
        </IconButton>
      </NavigationButtons>
    </>
  );
};

export default PdfPreview;
