import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled
} from '@mui/material';
import axios from 'axios';
import { DocumentApi } from 'common/api';
import { Document } from 'common/types';
import { useEffect, useState } from 'react';
import PdfPreview from './PDFViewer';

interface FilePreviewProps {
  document: Document | undefined;
  onClose: () => void;
}

const StyledDialog = styled(Dialog)`
  .MuiDialogTitle-root {
    padding-bottom: 0;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
`;

const FilePreviewDialog = ({ document, onClose }: FilePreviewProps) => {
  const [content, setContent] = useState<string | null>(null);
  const [presignedUrl, setPresignedUrl] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (document) {
      const fetchFileContent = async () => {
        const response = await DocumentApi.getPresignedPreviewUrl(
          document.documentId
        );
        if (!response.success || !response.data) {
          setContent(null);
          return;
        }
        const presignedUrl = response.data;
        setPresignedUrl(presignedUrl);

        if (['txt', 'csv', 'md'].includes(document.fileType)) {
          const fileResponse = await axios.get(presignedUrl, {
            responseType: 'blob'
          });
          const fileBlob = new Blob([fileResponse.data], {
            type: document.fileType
          });
          const reader = new FileReader();

          reader.onload = e => {
            if (e.target?.result) {
              setContent(e.target.result as string);
            }
          };

          reader.readAsText(fileBlob);
        }
      };

      fetchFileContent();
    } else {
      setContent(null);
      setPresignedUrl(undefined);
    }
  }, [document]);

  const handleClose = () => {
    onClose();
  };

  const renderContent = () => {
    if (!document) {
      return <Typography variant="body1">Error viewing file</Typography>;
    }

    if (['txt', 'csv', 'md'].includes(document.fileType)) {
      return content ? (
        <DialogContent>
          <pre>{content}</pre>{' '}
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography>Loading content...</Typography>
          <CircularProgress size={75} />
        </DialogContent>
      );
    } else if (document.fileType === 'pdf') {
      return (
        <StyledDialogContent>
          <PdfPreview url={presignedUrl} />
        </StyledDialogContent>
      );
    }

    return <Typography variant="body1">Unsupported file type.</Typography>;
  };

  return (
    <StyledDialog
      open={document !== undefined}
      onClose={handleClose}
      aria-labelledby="file-preview-title"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle id="file-preview-title">{document?.fileName}</DialogTitle>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {renderContent()}
    </StyledDialog>
  );
};

export default FilePreviewDialog;
