import { SubscriptionType } from 'common/types';

export const SubscriptionTiers = [
  {
    type: SubscriptionType.FREE,
    name: 'Free',
    price: 0,
    features: ['Feature 1', 'Feature 2'],
    disabledFeatures: ['Feature 3', 'Feature 4', 'Feature 5']
  },
  {
    type: SubscriptionType.BASIC,
    name: 'Basic',
    price: 49.99,
    features: ['Feature 1', 'Feature 2', 'Feature 3'],
    disabledFeatures: ['Feature 4', 'Feature 5']
  },
  {
    type: SubscriptionType.PRO,
    name: 'Pro',
    price: 199.99,
    features: ['Feature 1', 'Feature 2', 'Feature 3'],
    disabledFeatures: ['Feature 4', 'Feature 5']
  },
  {
    type: SubscriptionType.ENTERPRISE,
    name: 'Enterprise',
    price: undefined,
    features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5'],
    disabledFeatures: []
  }
];
