import { AccountStatus, FrontendRoutes, OrgRole, UserRole } from 'common/types';
import useAuth from 'hooks/useAuth';
import { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
  allowedRoles?: UserRole[];
  allowedOrgRoles?: OrgRole[];
  isLoggedIn?: boolean;
  orgRequired?: boolean;
  isLoggedOut?: boolean;
  isVerified?: boolean;
  isNotVerified?: boolean;
  children?: ReactNode;
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { user, isAuthenticated, organization } = useAuth();

  if (props.isLoggedIn) {
    if (!isAuthenticated) {
      return <Navigate to={FrontendRoutes.LOGIN} replace />;
    }

    if (props.orgRequired && !user?.orgId) {
      return <Navigate to={FrontendRoutes.SETUP} replace />;
    }

    if (props.orgRequired === false) {
      if (user?.orgId) {
        return <Navigate to={FrontendRoutes.HOME} replace />;
      }
    }
  } else if (props.isLoggedOut) {
    if (isAuthenticated) {
      return <Navigate to={FrontendRoutes.HOME} replace />;
    }
  }

  if (props.isVerified) {
    if (user?.status !== AccountStatus.VERIFIED) {
      return <Navigate to={FrontendRoutes.UNVERIFIED} replace />;
    }
  }

  if (props.isNotVerified) {
    if (user?.status === AccountStatus.VERIFIED) {
      return <Navigate to={FrontendRoutes.DASHBOARD} replace />;
    }
  }

  if (props.allowedRoles) {
    if (!isAuthenticated) {
      return <Navigate to={FrontendRoutes.LOGIN} replace />;
    }
    const userIsAuthorized =
      user?.role && props.allowedRoles?.includes(user.role);

    if (!userIsAuthorized) {
      return <Navigate to={FrontendRoutes.UNAUTHORIZED} replace />;
    }
  }

  if (props.allowedOrgRoles) {
    if (!isAuthenticated || !organization || !user) {
      return <Navigate to={FrontendRoutes.LOGIN} replace />;
    }
    if (!user.orgRole || !props.allowedOrgRoles.includes(user.orgRole)) {
      return <Navigate to={FrontendRoutes.UNAUTHORIZED} replace />;
    }
  }

  return <>{props.children ?? <Outlet />}</>;
};
