import { Formik } from 'formik';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Link,
  TextField
} from '@mui/material';
import { FrontendRoutes, FullName } from 'common/types';
import {
  GoogleAuthProvider,
  getAdditionalUserInfo,
  getAuth,
  signInWithPopup
} from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../../hooks/useAuth';

const LoginJWT: FC = () => {
  const { login, register } = useAuth();
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Button
        sx={{
          mt: 3
        }}
        color="primary"
        startIcon={
          <img
            width={24}
            height={24}
            src="/images/logo/google.svg"
            alt="Google Logo"
          />
        }
        fullWidth
        size="large"
        variant="outlined"
        onClick={() => {
          const provider = new GoogleAuthProvider();
          const auth = getAuth();

          signInWithPopup(auth, provider)
            .then(async result => {
              // This gives you a Google Access Token. You can use it to access the Google API.
              const credential =
                GoogleAuthProvider.credentialFromResult(result);

              if (credential === null) {
                throw new Error('No credential');
              }

              const user = result.user;
              const additionalUserInfo = getAdditionalUserInfo(result);

              if (
                user.email &&
                additionalUserInfo &&
                additionalUserInfo.isNewUser
              ) {
                const firstName = user.displayName?.split(' ')[0];
                const lastName = user.displayName?.split(' ')[1];
                const fullName: FullName = {
                  firstName: firstName ?? '',
                  lastName: lastName ?? '',
                  fullName: user.displayName ?? ''
                };
                await register(
                  user.email,
                  fullName,
                  undefined,
                  undefined,
                  undefined,
                  user.uid
                );

                navigate(FrontendRoutes.SETUP);
              } else {
                console.log('Logged in');
                navigate(FrontendRoutes.CHAT);
              }
            })
            .catch(error => {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              // The email of the user's account used.
              const email = error.customData.email;
              // The AuthCredential type that was used.
              const credential = GoogleAuthProvider.credentialFromError(error);
              // ...
            });
        }}
      >
        {t('Sign in with Google')}
      </Button>
      <Box
        sx={{
          mt: 3,
          mb: 1
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              mr: 2
            }}
          >
            <hr />
          </Box>
          <span>{t('OR')}</span>
          <Box
            sx={{
              flexGrow: 1,
              ml: 2
            }}
          >
            <hr />
          </Box>
        </Box>
      </Box>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .max(255)
            .required(t('The email field is required'))
            .email(t('The email must be a valid email address')),
          password: Yup.string()
            .max(255)
            .required(t('The password field is required'))
        })}
        isInitialValid={false}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting }
        ): Promise<void> => {
          try {
            const response = await login(values.email, values.password);
            if (response.success) {
              setStatus({ success: true });
              setSubmitting(false);
              navigate(FrontendRoutes.CHAT);
            } else {
              console.log();
              setStatus({ success: false });
              setErrors({ submit: response.errorMessage });
              setSubmitting(false);
            }
          } catch (err: any) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }): JSX.Element => (
          <form noValidate onSubmit={handleSubmit}>
            {Boolean(touched.submit && errors.submit) && (
              <Alert severity="error">{errors.submit}</Alert>
            )}
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              margin="normal"
              autoFocus
              helperText={touched.email && errors.email}
              label={t('Email')}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              margin="normal"
              helperText={touched.password && errors.password}
              label={t('Password')}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            <Box
              alignItems="center"
              display={{ xs: 'block', md: 'flex' }}
              justifyContent="space-between"
            >
              <Link component={RouterLink} to="/account/recover">
                <b>{t('Lost password?')}</b>
              </Link>
            </Box>

            <Button
              sx={{
                mt: 3
              }}
              color="primary"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
            >
              {t('Sign in')}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default LoginJWT;
