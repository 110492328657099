import type { ReactNode } from 'react';

import ApiIcon from '@mui/icons-material/Api';
import BarChartIcon from '@mui/icons-material/BarChart';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import GroupIcon from '@mui/icons-material/Group';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        icon: <ApiIcon />,
        link: '/dashboard/overview'
      },
      {
        name: 'Knowledge Base',
        icon: <LibraryBooksIcon />,
        link: '/dashboard/knowledgebase'
      },
      {
        name: 'Security',
        icon: <SecurityIcon />,
        link: '/dashboard/security/',
        items: [
          {
            name: 'Users',
            link: '/dashboard/security/users',
            icon: <PersonIcon />
          },
          {
            name: 'Groups',
            link: '/dashboard/security/groups',
            icon: <GroupIcon />
          }
        ]
      },
      {
        name: 'Settings',
        icon: <SettingsIcon />,
        link: '/dashboard/settings/',
        items: [
          {
            name: 'Preferences',
            link: '/dashboard/settings/preferences',
            icon: <LocalOfferIcon />
          },
          {
            name: 'Integrations',
            link: '/dashboard/settings/integrations',
            icon: <ElectricalServicesIcon />
          }
        ]
      },
      {
        name: 'Billing & Usage',
        icon: <CorporateFareIcon />,
        link: '/dashboard/billing/',
        items: [
          {
            name: 'Usage',
            link: '/dashboard/billing/usage',
            icon: <BarChartIcon />
          },
          {
            name: 'Billing',
            link: '/dashboard/billing/settings',
            icon: <CreditCardIcon />
          }
        ]
      }
    ]
  }
];

export default menuItems;
