import {
  BasicResponse,
  FrontendUser,
  HttpMethods,
  Organization
} from 'common/types';
import { FetchProps, FetchUrl } from './api-client';

export class PagesApi {
  public static async getInitialData(): Promise<
    BasicResponse<{
      user: FrontendUser;
      organization: Organization;
    }>
  > {
    const request: FetchProps = {
      apiEndpoint: `/api/pages/home`,
      method: HttpMethods.GET
    };
    const response = await FetchUrl<{
      user: FrontendUser;
      organization: Organization;
    }>(request);
    return response;
  }
}
