import { forwardRef, ReactElement, Ref, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Slide,
  styled,
  Typography,
  Zoom
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { TransitionProps } from '@mui/material/transitions';
import { OrganizationApi } from 'common/api/organization-service-api';
import { OrgPermissionGroup } from 'common/types';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface BulkActionsProps {
  selectedGroups: OrgPermissionGroup[];
}

function BulkActions(props: BulkActionsProps) {
  const { t }: { t: any } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ message?: string }>({});
  const [openEditRole, setOpenEditRole] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user, refreshOrganization: fetchOrganization } = useAuth();

  const handleDeleteGroups = async () => {
    try {
      setIsLoading(true);
      if (!user?.orgId) {
        setErrors({ message: 'Unexpected Error' });
        setIsLoading(false);
        return;
      }

      const response = await OrganizationApi.deleteGroups(
        user.orgId,
        props.selectedGroups.map(group => group.groupId)
      );
      if (response.success && response.data) {
        await fetchOrganization();
        resetFormState();
        enqueueSnackbar(t('The group(s) have been deleted'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      } else {
        setErrors({ message: response.errorMessage });
      }
    } catch (err: any) {
      console.error(err);
      setErrors({ message: err.message });
    }
    setIsLoading(false);
  };

  const resetFormState = () => {
    setOpenEditRole(false);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" color="text.secondary">
            {t('Bulk actions')}:
          </Typography>
          <ButtonError
            sx={{
              ml: 1
            }}
            size="small"
            startIcon={<DeleteIcon />}
            variant="contained"
            onClick={() => setOpenEditRole(true)}
          >
            {t('Delete')}
          </ButtonError>
        </Box>
      </Box>
      <DialogWrapper
        open={openEditRole}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={resetFormState}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 6
            }}
            variant="h3"
          >
            {t(
              'Are you sure you want to remove these groups from the organization'
            )}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={resetFormState}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteGroups}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
              startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
              disabled={isLoading}
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
}

export default BulkActions;
