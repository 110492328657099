import { Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

function PageHeader() {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        {t('Organization Preferences')}
      </Typography>
      <Typography variant="subtitle2">
        {user?.name.firstName},{' '}
        {t('this is your organization preferences panel.')}
      </Typography>
    </>
  );
}

export default PageHeader;
