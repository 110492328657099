import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PendingIcon from '@mui/icons-material/Pending';
import ReportIcon from '@mui/icons-material/Report';
import Timeline from '@mui/lab/Timeline/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent/TimelineContent';
import TimelineItem from '@mui/lab/TimelineItem/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator/TimelineSeparator';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography
} from '@mui/material';
import { Message, MessageStatus } from 'common/types';
import { formatDistance } from 'date-fns';
import { MessageState } from './AvaMessage';

interface WorklogProps {
  workLog: Message[];
  state: MessageState;
}

export const Worklog = (props: WorklogProps) => {
  return (
    <Accordion sx={{ borderRadius: '10px' }} defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          minHeight: '45px !important',
          height: '45px !important',
          padding: '0px 15px',
          margin: '0px'
        }}
      >
        <Box mr={2} mt={'3px'}>
          {props.state === MessageState.PROCESSING && (
            <CircularProgress sx={{ marginTop: '3px' }} size="1rem" />
          )}
          {props.state === MessageState.DONE && (
            <DoneIcon style={{ color: 'green' }} fontSize="small" />
          )}
          {props.state === MessageState.WAITING_FOR_USER && (
            <PendingIcon fontSize="small" />
          )}
          {props.state === MessageState.ERROR && (
            <ReportIcon style={{ color: 'red' }} fontSize="small" />
          )}
        </Box>
        <Typography p={0} fontSize={14} variant="h6" mt={'3px'}>
          Work Log
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop: '1px solid whitesmoke', padding: 0 }}>
        <Timeline sx={{ margin: 0, padding: '0px 30px' }}>
          {props.workLog.map((message, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <Box mt={'6px'}>
                  {message.status === MessageStatus.PROCESSING &&
                    message.workflowState &&
                    message.workflowState.waitingForUserApproval && (
                      <PendingIcon fontSize="small" />
                    )}
                  {message.status === MessageStatus.PROCESSING &&
                    (!message.workflowState ||
                      !message.workflowState.waitingForUserApproval) && (
                      <CircularProgress sx={{ marginTop: '3px' }} size="1rem" />
                    )}
                  {message.status === MessageStatus.COMPLETED && (
                    <DoneIcon style={{ color: 'green' }} fontSize="small" />
                  )}
                  {message.status === MessageStatus.ERROR && (
                    <ReportIcon style={{ color: 'red' }} fontSize="small" />
                  )}
                </Box>
                {index !== props.workLog.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent p={0}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Typography fontSize={14} sx={{ color: 'text.primary' }}>
                    {`${message.workflowState?.workLogText}${
                      message.workflowState &&
                      message.workflowState.logs.length > 0 &&
                      message.workflowState.logs[
                        message.workflowState.logs.length - 1
                      ].status === MessageStatus.PROCESSING
                        ? `: ${
                            message.workflowState.logs[
                              message.workflowState.logs.length - 1
                            ].log
                          }`
                        : ``
                    }`}
                  </Typography>
                  <Typography
                    variant="caption"
                    fontSize={10}
                    sx={{ color: 'text.secondary', marginLeft: '10px' }}
                  >
                    {formatDistance(new Date(message.timestamp), new Date(), {
                      addSuffix: true
                    })}
                  </Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </AccordionDetails>
    </Accordion>
  );
};
