import { Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';

function PageHeader() {
  const { user } = useAuth();

  return (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        User Settings
      </Typography>
      <Typography variant="subtitle2">
        {user?.name.firstName}, this is your user settings panel.
      </Typography>
    </>
  );
}

export default PageHeader;
