import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import {
  UserApprovalType,
  WorkflowInputType,
  WorkflowParameter
} from 'common/types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface UserApprovalProps {
  type: UserApprovalType;
  value: any;
  onApprove: (values: { [key: string]: string }) => void;
}

export const UserApproval = (props: UserApprovalProps) => {
  let content = <Box></Box>;
  const { t }: { t: any } = useTranslation();

  if (props.type === UserApprovalType.FIELDS) {
    const inputFields = props.value as WorkflowParameter[];
    content = (
      <Box>
        <Formik
          initialValues={{
            ...inputFields.reduce((acc, field) => {
              acc[field.name] = field.value;
              return acc;
            }, {}),
            submit: null
          }}
          validationSchema={Yup.object().shape(
            inputFields
              .filter(f => f.required)
              .reduce((acc, field) => {
                acc[field.name] = Yup.string().required(
                  t(`This field is required`)
                );
                return acc;
              }, {})
          )}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              setSubmitting(true);

              await props.onApprove(_values as any);

              setStatus({ success: true });
              setSubmitting(false);
            } catch (err: any) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: 'Unexpected Error' });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              {Boolean(touched.submit && errors.submit) && (
                <Box textAlign="center">
                  <Alert severity="error">{errors.submit}</Alert>
                </Box>
              )}
              <Box
                sx={{
                  p: 1
                }}
              >
                <Grid container spacing={1}>
                  {inputFields.map((field, index) => (
                    <Grid item xs={12} key={index} p={0}>
                      <InputLabel
                        variant="standard"
                        required={field.required}
                        sx={{ display: 'flex' }}
                      >
                        <Typography variant="h6">
                          {`${field.description}`}
                        </Typography>
                      </InputLabel>
                      {field.inputType === WorkflowInputType.TEXT && (
                        <TextField
                          sx={{
                            padding: 0
                          }}
                          error={Boolean(
                            touched[field.name] && errors[field.name]
                          )}
                          fullWidth
                          helperText={touched[field.name] && errors[field.name]}
                          name={field.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values[field.name]}
                          variant="outlined"
                        />
                      )}

                      {field.inputType === WorkflowInputType.SELECT && (
                        <Select
                          error={Boolean(
                            touched[field.name] && errors[field.name]
                          )}
                          fullWidth
                          name={field.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values[field.name]}
                          variant="outlined"
                        >
                          {field.valuesAllowed?.map(r => (
                            <MenuItem key={r.value} value={r.value}>
                              {r.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {t('Submit')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    );
  }

  return content;
};
