import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  Link,
  Slide,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Formik } from 'formik';
import { ReactElement, Ref, forwardRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import useRefMounted from '../../../../hooks/useRefMounted';

import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import Logo from 'app/components/Logo';
import { UserApi } from 'common/api';
import { FrontendRoutes } from 'common/types';
import { useTranslation } from 'react-i18next';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      top: -${theme.spacing(6)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

function RequestPassword() {
  const { t }: { t: any } = useTranslation();
  const isMountedRef = useRefMounted();

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Container maxWidth="sm">
        <Logo size="l" />
        <Card
          sx={{
            mt: 3,
            p: 4
          }}
        >
          <Box>
            <Typography
              variant="h2"
              sx={{
                mb: 1
              }}
            >
              {t('Recover Password')}
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{
                mb: 3
              }}
            >
              {t(
                'Enter the email used for registration to reset your password.'
              )}
            </Typography>
          </Box>

          <Formik
            initialValues={{
              email: '',
              submit: null
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(t('The email provided should be a valid email address'))
                .max(255)
                .required(t('The email field is required'))
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                if (isMountedRef.current) {
                  let response = await UserApi.doesEmailExist(values.email);
                  if (response.success && response.data === false) {
                    setStatus({ success: false });
                    setErrors({
                      email: 'No found user with that email'
                    });
                    setSubmitting(false);
                    return;
                  }
                  const resetResponse = await UserApi.sendPasswordResetEmail(
                    values.email
                  );
                  if (resetResponse.success) {
                    handleOpenDialog();
                    setStatus({ success: true });
                    setSubmitting(false);
                  } else {
                    setStatus({ success: false });
                    setErrors({ submit: resetResponse.errorMessage });
                    setSubmitting(false);
                  }
                }
              } catch (err: any) {
                console.error(err);
                if (isMountedRef.current) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
              errors,
              isSubmitting,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {Boolean(touched.submit && errors.submit) && (
                  <Box textAlign="center">
                    <Alert severity="error">{errors.submit}</Alert>
                  </Box>
                )}
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('Email address')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />

                <Button
                  sx={{
                    mt: 3
                  }}
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  color="primary"
                  disabled={
                    isSubmitting || Boolean(touched.email && errors.email)
                  }
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  {t('Reset Password')}
                </Button>
              </form>
            )}
          </Formik>
        </Card>
        <Box mt={3} textAlign="right">
          <Typography
            component="span"
            variant="subtitle2"
            color="text.primary"
            fontWeight="bold"
          >
            {t('Want to try to sign in again?')}
          </Typography>{' '}
          <Link component={RouterLink} to={FrontendRoutes.LOGIN}>
            <b>Click here</b>
          </Link>
        </Box>
      </Container>
      <DialogWrapper
        open={openDialog}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
      >
        <Box
          sx={{
            px: 4,
            pb: 4,
            pt: 10
          }}
        >
          <AvatarSuccess>
            <CheckTwoToneIcon />
          </AvatarSuccess>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 10
            }}
            variant="h3"
          >
            {t(
              'Check your email for further instructions. The password reset instructions have been sent to your email'
            )}
          </Typography>

          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={handleCloseDialog}
            component={RouterLink}
            to={FrontendRoutes.LOGIN}
          >
            {t('Continue to login')}
          </Button>
        </Box>
      </DialogWrapper>
    </>
  );
}

export default RequestPassword;
