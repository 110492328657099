import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { UsageSnapshot, UsageStats } from 'common/types';
import useAuth from 'hooks/useAuth';

const DotLegend = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.5)};
    height: ${theme.spacing(1.5)};
    display: inline-block;
    margin-right: ${theme.spacing(0.5)};
`
);

interface ChatStatsProps {
  usageStats: UsageStats;
  monthlyStats: UsageSnapshot[];
}
function ChatStats(props: ChatStatsProps) {
  const theme = useTheme();
  const { organization } = useAuth();

  const monthStartTotalMessages =
    props.monthlyStats.length > 0
      ? props.monthlyStats[0].totalMessages ?? 0
      : 0;

  const messagesUsed =
    props.usageStats.totalAvaMessages - monthStartTotalMessages;
  const messageLimit = organization?.limits.monthlyMessageLimit ?? messagesUsed;

  const seriesData = getSeriesDataForMonth(
    props.usageStats,
    props.monthlyStats
  );

  return (
    <Card>
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography gutterBottom variant="h4">
            Conversations
          </Typography>
          <Typography variant="subtitle2">
            View conversation analytics for the current month
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4">
            {!organization?.limits.monthlyMessageLimit ? (
              <>{messagesUsed}</>
            ) : (
              <>
                {messagesUsed}/{messageLimit}
              </>
            )}
          </Typography>
          <Typography variant="subtitle2">
            {!organization?.limits.monthlyMessageLimit ? `Used` : `Used/Limit`}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Grid py={1} container spacing={3}>
        <Grid
          md={12}
          item
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={350}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={seriesData}
              margin={{
                top: 10,
                right: 40,
                left: 0,
                bottom: 10
              }}
            >
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis dataKey="name" />
              <YAxis
                label={{
                  value: 'Messages',
                  angle: -90
                }}
              />
              <Tooltip />

              <Bar
                dataKey="Messages"
                stackId="a"
                fill={theme.colors.primary.main}
                label={{ position: 'top' }}
              >
                {seriesData.map((entry, index) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={theme.colors.primary.main}
                    />
                  );
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Card>
  );
}

const getSeriesDataForMonth = (
  usageData: UsageStats,
  usageSnapshots: UsageSnapshot[]
) => {
  // go through each day of the month up until the current day and get the number user, ava, and function messages. If it is the current day use the usageStats data, otherwise use the snapshot data
  const seriesData: {
    name: string;
    Messages: number;
  }[] = [];

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();

  let prevAvaMessages = 0;
  for (let i = 1; i <= daysInMonth; i++) {
    if (i === today.getDate()) {
      const avaMessages = usageData.totalAvaMessages - prevAvaMessages;

      seriesData.push({
        name: today.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        }),
        Messages: avaMessages
      });

      break;
    }

    const usageSnapshot = usageSnapshots.find(
      snapshot => new Date(snapshot.createdAt).getDate() === i
    );

    const avaMessages = usageSnapshot?.totalAvaMessages
      ? usageSnapshot?.totalAvaMessages - prevAvaMessages
      : 0;

    prevAvaMessages = usageSnapshot?.totalAvaMessages ?? 0;

    seriesData.push({
      name: new Date(currentYear, currentMonth, i).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      }),
      Messages: avaMessages
    });
  }

  return seriesData;
};

export default ChatStats;
