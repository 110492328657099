import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import {
  Box,
  Card,
  CardActionArea,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

const CardBorderBottom = styled(Card)(
  () => `
    border-bottom: transparent 5px solid;
  `
);

const CardActionAreaWrapper = styled(CardActionArea)(
  ({ theme }) => `
    padding: ${theme.spacing(3, 2, 3, 3)};
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiTouchRipple-root {
        opacity: .3;
    }

    &:hover {
        .MuiCardActionArea-focusHighlight {
            opacity: .05;
        }
    }
  `
);

interface StatCardProps {
  icon: ReactNode;
  value: string;
  label: string;
  link: string;
  shadow?: string;
  color?: string;
}
function StatCard(props: StatCardProps) {
  const theme = useTheme();

  return (
    <CardBorderBottom
      sx={{
        boxShadow: props.shadow ?? undefined,
        borderBottomColor: props.color ?? undefined
      }}
    >
      <NavLink to={props.link} style={{ textDecoration: 'none' }}>
        <CardActionAreaWrapper>
          <Box>
            <Box
              component="span"
              sx={{ color: props.color ?? theme.colors.primary.main }}
            >
              {props.icon}
            </Box>
            <Box mt={0.5}>
              <Typography
                component="span"
                variant="h4"
                sx={{ color: props.color ?? theme.colors.primary.main }}
              >
                {props.value}
              </Typography>{' '}
              <Typography component="span" variant="subtitle2">
                {props.label}
              </Typography>
            </Box>
          </Box>
          <Typography
            component="span"
            color="text.secondary"
            sx={{
              opacity: 0.5,
              display: 'flex'
            }}
          >
            <ChevronRightTwoToneIcon />
          </Typography>
        </CardActionAreaWrapper>
      </NavLink>
    </CardBorderBottom>
  );
}

export default StatCard;
