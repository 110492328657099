import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { ConversationApi } from 'common/api';
import { Conversation, WorkflowInputType } from 'common/types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ToolUI } from './ToolDrawer';

interface ToolDialogProps {
  tool: ToolUI;
  selectedConversation?: Conversation;
  open: boolean;
  onClose: () => void;
  onSuccess: (conversation: Conversation) => void;
}
export const ToolDialog = (props: ToolDialogProps) => {
  const { t }: { t: any } = useTranslation();

  return (
    <Dialog fullWidth maxWidth="md" open={props.open} onClose={props.onClose}>
      <DialogTitle>
        <Typography variant="h4" gutterBottom>
          {props.tool.title}
        </Typography>
      </DialogTitle>
      <Box sx={{ padding: 2 }}>
        <Formik
          initialValues={{
            ...props.tool.parameters.reduce((acc, field) => {
              acc[field.key] = field.value;
              return acc;
            }, {}),
            submit: undefined
          }}
          validationSchema={Yup.object().shape(
            props.tool.parameters
              .filter(f => f.required)
              .reduce((acc, field) => {
                acc[field.key] = Yup.string().required(
                  t(`This field is required`)
                );
                return acc;
              }, {})
          )}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              setSubmitting(true);

              let vals: any = _values;
              delete vals.submit;

              const response = await ConversationApi.startWorkflow({
                conversationId: props.selectedConversation?.conversationId,
                workflowId: props.tool.workflowKey,
                values: vals
              });

              if (response.success && response.data) {
                await props.onSuccess(response.data);
                setStatus({ success: true });
                setSubmitting(false);
              } else {
                setStatus({ success: false });
                setErrors({ submit: 'Unexpected Error' });
                setSubmitting(false);
              }
            } catch (err: any) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: 'Unexpected Error' });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              {Boolean(touched.submit && errors.submit) && (
                <Box textAlign="center">
                  <Alert severity="error">{errors.submit}</Alert>
                </Box>
              )}
              <Box
                sx={{
                  p: 2
                }}
              >
                <Grid container spacing={1}>
                  {props.tool.parameters.map((field, index) => (
                    <Grid item xs={12} key={index} p={0}>
                      <InputLabel
                        variant="standard"
                        required={field.required}
                        sx={{ display: 'flex' }}
                      >
                        <Typography variant="h6">
                          {`${field.description}`}
                        </Typography>
                      </InputLabel>
                      {field.type === WorkflowInputType.TEXT && (
                        <TextField
                          sx={{
                            padding: 0
                          }}
                          error={Boolean(
                            touched[field.key] && errors[field.key]
                          )}
                          fullWidth
                          helperText={touched[field.key] && errors[field.key]}
                          name={field.key}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values[field.key]}
                          variant="outlined"
                        />
                      )}

                      {field.type === WorkflowInputType.SELECT && (
                        <Select
                          error={Boolean(
                            touched[field.key] && errors[field.key]
                          )}
                          fullWidth
                          name={field.key}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values[field.key]}
                          variant="outlined"
                        >
                          {field.valuesAllowed?.map(r => (
                            <MenuItem key={r.value} value={r.value}>
                              {r.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {t('Submit')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};
