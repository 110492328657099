import RefreshIcon from '@mui/icons-material/Refresh';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import {
  Box,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import LogoLoading from 'app/components/LogoLoading';
import { DocumentApi, WEBSOCKET_URL } from 'common/api';
import {
  Document,
  OrgPermissionGroup,
  Undefinable,
  WebsocketEventPayload,
  WebsocketEventTypes,
  acceptedFileExtensions
} from 'common/types';
import firebase from 'firebase/compat/app';
import useAuth from 'hooks/useAuth';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import BulkActions from './BulkActions';
import { DeleteFileDialog } from './Dialogs/DeleteFile';
import EditFileDialog from './Dialogs/EditFile';
import FilePreviewDialog from './Dialogs/FilePreview';
import FileFilter, { FileFilters } from './FileFilter';
import { FileRow } from './FileRow';

const applyFilters = (
  documents: Document[],
  query: string,
  fitlers: FileFilters
) => {
  let filtered = documents.filter(d =>
    d.fileName.toLowerCase().includes(query.toLowerCase())
  );

  if (fitlers.fileTypes.length > 0) {
    filtered = filtered.filter(d => fitlers.fileTypes.includes(d.fileType));
  }

  if (fitlers.groups.length > 0) {
    filtered = filtered.filter(d => {
      for (const group of fitlers.groups) {
        if (d.permissionGroups.map(g => g.key).includes(group.groupId)) {
          return true;
        }
      }
      return false;
    });
  }

  return filtered;
};

const applyPagination = (
  documents: Document[],
  page: number,
  limit: number
): Document[] => {
  return documents.slice(page * limit, page * limit + limit);
};

const FileExplorer = () => {
  const { t }: { t: any } = useTranslation();
  const [query, setQuery] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [filters, setFilters] = useState<FileFilters>({
    fileTypes: [] as string[],
    groups: [] as OrgPermissionGroup[]
  });

  const [documents, setDocuments] = useState<Document[]>([]);
  const filteredDocuments = applyFilters(documents, query, filters);
  const paginatedDocuments = applyPagination(filteredDocuments, page, limit);
  const { organization } = useAuth();
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const [previewDocument, setPreviewDocument] =
    useState<Undefinable<Document>>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [itemBeingDeleted, setItemBeingDeleted] =
    useState<Undefinable<Document>>(undefined);
  const [itemBeingEdited, setItemBeingEdited] =
    useState<Undefinable<Document>>(undefined);
  const [token, setToken] = useState<string | undefined>();

  useEffect(() => {
    const getToken = async () => {
      const user = firebase.auth().currentUser;
      const token = user
        ? await user.getIdToken(/* forceRefresh */ true)
        : undefined;
      setToken(token);
    };
    getToken();
  }, []);

  useWebSocket(WEBSOCKET_URL, {
    shouldReconnect: closeEvent => true,
    onOpen: () => console.log('Connected'),
    onClose: () => console.log('Disconnected'),
    share: true,
    onMessage: event => {
      console.log(event);
      const newMessage = JSON.parse(event.data) as WebsocketEventPayload<any>;
      console.log('websocket event', newMessage);
      if (newMessage.type === WebsocketEventTypes.NEW_FILE) {
        setDocuments([...documents, newMessage.payload]);
      } else if (newMessage.type === WebsocketEventTypes.UPDATE_FILE) {
        updateDocuments('update', [newMessage.payload]);
      }
    },
    queryParams: {
      token: token ?? ''
    }
  });

  useEffect(() => {
    fetchDocuments();
  }, []);

  const updateDocuments = (
    type: 'update' | 'delete' | 'create',
    items: Document[]
  ) => {
    const updatedDocuments = [...documents];
    if (type === 'update') {
      for (const item of items) {
        updatedDocuments[
          updatedDocuments.findIndex(d => d.documentId === item.documentId)
        ] = item;
      }
    } else if (type === 'delete') {
      for (const item of items) {
        updatedDocuments.splice(
          updatedDocuments.findIndex(d => d.documentId === item.documentId),
          1
        );
      }
    }
    setDocuments(updatedDocuments);
  };

  const fetchDocuments = async () => {
    setIsLoading(true);
    if (!organization) return;
    const response = await DocumentApi.getByOrg(organization.orgId);
    if (response.success && response.data) {
      setDocuments(response.data.items);
    }
    setIsLoading(false);
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPage(0);
    event.persist();
    setQuery(event.target.value);
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedDocuments(event.target.checked ? filteredDocuments : []);
  };

  const handleSelectOne = (document: Document): void => {
    if (!selectedDocuments.find(d => d.documentId === document.documentId)) {
      setSelectedDocuments(prevSelected => [...prevSelected, document]);
    } else {
      setSelectedDocuments(prevSelected =>
        prevSelected.filter(prev => prev.documentId !== document.documentId)
      );
    }
  };
  const selectedBulkActions = selectedDocuments.length > 0;
  const selectedSomeDocuments =
    selectedDocuments.length > 0 &&
    selectedDocuments.length < filteredDocuments.length;
  const selectedAllVisibleDocuments =
    selectedDocuments.length === filteredDocuments.length &&
    filteredDocuments.length !== 0;

  return (
    <>
      <Card>
        <Box
          sx={{ pr: '15px', pt: '6px' }}
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <Box sx={{ minWidth: '50%' }} p={1.5}>
            {!selectedBulkActions && (
              <Box display="flex" justifyContent="space-between" width="100%">
                <TextField
                  sx={{
                    m: 0
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder={t('Search by name...')}
                  value={query}
                  size="small"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Box>
            )}
            {selectedBulkActions && (
              <BulkActions
                selectedDocuments={selectedDocuments}
                updateDocuments={updateDocuments}
              />
            )}
          </Box>
          <Box p={2}>
            <IconButton
              sx={{ marginRight: 2 }}
              disabled={isLoading}
              onClick={fetchDocuments}
            >
              <RefreshIcon />
            </IconButton>
            <FileFilter
              fileTypes={acceptedFileExtensions.map(ext => ({
                label: ext,
                value: ext
              }))}
              groups={organization?.permissionGroups ?? []}
              onFilterChange={filter => {
                setPage(0);
                setFilters(filter);
              }}
            />
          </Box>
        </Box>

        <Divider />

        {paginatedDocuments.length === 0 ? (
          <>
            <Typography
              sx={{
                py: 10
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {isLoading ? (
                <Box display="flex" justifyContent="center">
                  <LogoLoading size="l" />
                </Box>
              ) : (
                t("We couldn't find any files")
              )}
            </Typography>
          </>
        ) : (
          <>
            <TableContainer
              sx={{
                minHeight: `390px`,
                maxHeight: `390px`,
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAllVisibleDocuments}
                        indeterminate={selectedSomeDocuments}
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>File Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Source</TableCell>
                    <TableCell>Groups</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell>Uploaded On</TableCell>
                    <TableCell align="center">{t('Actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedDocuments.map(item => {
                    return (
                      <FileRow
                        document={item}
                        organization={organization}
                        isSelected={
                          selectedDocuments.find(
                            d => d.documentId === item.documentId
                          )
                            ? true
                            : false
                        }
                        handleSelect={(document: Document) => {
                          handleSelectOne(document);
                        }}
                        onViewClick={(document: Document) => {
                          setPreviewDocument(document);
                        }}
                        onEditClick={(document: Document) => {
                          setItemBeingEdited(document);
                        }}
                        onDeleteClick={(document: Document) => {
                          setItemBeingDeleted(document);
                        }}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              p={'0px 20px'}
              sx={{ borderTop: '1px solid', borderColor: '#afafaf' }}
            >
              <TablePagination
                component="div"
                count={filteredDocuments.length}
                onPageChange={(_event: any, newPage: number): void => {
                  setPage(newPage);
                }}
                onRowsPerPageChange={(
                  event: ChangeEvent<HTMLInputElement>
                ): void => {
                  setLimit(parseInt(event.target.value));
                }}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[10, 25, 50]}
              />
            </Box>
          </>
        )}
      </Card>
      <FilePreviewDialog
        document={previewDocument}
        onClose={() => setPreviewDocument(undefined)}
      />
      {itemBeingDeleted && (
        <DeleteFileDialog
          document={itemBeingDeleted}
          open={itemBeingDeleted !== undefined}
          onClose={() => {
            setItemBeingDeleted(undefined);
          }}
          onSuccess={item => {
            updateDocuments('delete', [item]);
            setItemBeingDeleted(undefined);
          }}
        />
      )}
      {itemBeingEdited && (
        <EditFileDialog
          document={itemBeingEdited}
          open={itemBeingEdited !== undefined}
          onClose={() => {
            setItemBeingEdited(undefined);
          }}
          onSuccess={item => {
            updateDocuments('update', [item]);
            setItemBeingEdited(undefined);
          }}
        />
      )}
    </>
  );
};

export default FileExplorer;
